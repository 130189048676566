import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApiEndPoints } from '../../../../models/api-endpoint';
import Toaster from '../../../../services/toaster.services';
import { toggleLoader } from '../../../../redux/actions/loader.action';
import RestApiService from '../../../../services/http-services';
import DynamicList from '../../../../shared/components/dynamic-list/dynamic-list';
import SubHeaderComponent from '../../../../shared/components/sub-header/sub-header';
import { useHistory } from 'react-router-dom';
import * as XLSX from "xlsx";
import { Dialog, DialogContent, DialogTitle, Typography, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ClusterCompareMultiSelect from '../ClusterCompareMultiSelect/ClusterCompareMultiSelect';
import FileDownloadService from '../../../../services/file-download-services';

import "./ClusterCompare.scss"
import AppConstants from '../../../../core/constants/appConstants';


const ClusterComparison = (props: ClusterComparisonProps) => {

    const [pageSize] = useState(10);
    const [currentPageNo] = useState(1);
    const [totalPage] = useState(1);
    const [totalRecord] = useState(0);
    const [comparisonData, setComparisonData] = useState<Array<any>>([]);
    const [openPopUp, setOpenPopUp] = useState<boolean>(false);
    const [nodesCompareList, setNodesCompareList] = useState<Array<any> | undefined>(props.location?.state?.clusterCompareList);

    let history = useHistory();
    let fileDownloadService = new FileDownloadService();
    const [clusterETId, clusterSV, nodeETId, nodeSV] = [AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.SV,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.SV]

    useEffect(() => {
        if (nodesCompareList?.length > 0) {
            nodesCompareList.forEach((cluster: any) => {
                getNodesInfo(cluster, cluster.NodeUrl);
            })
        }
    }, [nodesCompareList]);


    const getNodesInfo = (cluster: any, url: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.BLOCKCHAIN_INFO, null, { clusterUrl: url }, null, null).then(res => {
            RestApiService.invoke(ApiEndPoints.GET_ENVELOPE_COUNT, null, { url: url }, null, {
                ETId: nodeETId,
                SV: nodeSV
            }).then(nodeResponse => {
                setComparisonData((prev) => [...prev, { ...cluster, ...res.data.data, envelopeCount: nodeResponse.data.totalRecord }])
                props.toggleLoader(false);

            }).catch(error => {
                props.toggleLoader(false);
                console.log(error, "resultEnvelopCount")
                Toaster.errorToaster(error);
            })
        }).catch(error => {
            props.toggleLoader(false);
            console.log(error, "resultDataComparision")
            Toaster.errorToaster(error);
        })
    }

    const downloadxls = (data = comparisonData) => {
        fileDownloadService.arrayOfObjectToXLSXFileDownload(data, "Node Comparison Result.xlsx");
    }

    const handleAction = (item: any, action: string) => {
        if (action === 'delete') {
            const elementDeleted = comparisonData.filter((compareObject) => compareObject.UID !== item.UID);
            setComparisonData(elementDeleted);
        }
        else if (action === 'edit') {
            setOpenPopUp(true)
        }
    }

    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='setting-page cluster-node'>
                        <div className="Innerheading cluster-Compare">
                            <SubHeaderComponent
                                headingText={'Comparison Result'}
                                searchLabel={'Nodes'}
                                btnText={"Export Result"}
                                isbackEnabled
                                onBackButtonClick={() => history.goBack()}
                                onButtonClick={downloadxls}
                                showBtn
                            />
                            <div className="Innerdetails">

                                <div className="grid">
                                    <DynamicList
                                        dataList={comparisonData}
                                        columnList={[{ name: "Nodes", db: "NodeName", sort: true }, { name: "BlockChain Type", db: "blockChainType", sort: true }, { name: "Envelope Count", db: "envelopeCount", sort: true }, { name: "Blockchain Height", db: "blockChainHeight", sort: true }
                                        ]}
                                        primaryColumn={"id"}
                                        orderIndexedFields={false}
                                        onSortingData={false}
                                        showEdit={true}
                                        showAdd={false}
                                        showDelete={true}
                                        showView={false}
                                        showActions={true}
                                        handleAction={(item: any, action: string) => handleAction(item, action)}
                                        showChangePassword={false}
                                        isOrderIconChange={true}
                                        keysToHide={['']}
                                        fromComponent={'SettingComponent'}
                                        goToPreviousPage={false}
                                        goToNextPage={false}
                                        changePageSize={false}
                                        pageSize={pageSize}
                                        currentPageNo={currentPageNo}
                                        totalPage={totalPage}
                                        totalRecord={totalRecord}
                                        isLessthanTotalElement={false}
                                        SNo={false}
                                        showPagination={false}
                                    />
                                </div>
                                <div className='comparison-result-btn'>
                                    <Tooltip title={"Add to comparison list"} placement="bottom" >
                                        <button
                                            className={`btn-primary`}
                                            onClick={() => { setOpenPopUp(true) }}
                                        >Add to comparison list</button>
                                    </Tooltip >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openPopUp} onClose={() => setOpenPopUp(false)}>
                <DialogTitle disableTypography >
                    <Typography variant="h6">Add nodes to comparison table</Typography>
                    <IconButton aria-label="close" onClick={() => setOpenPopUp(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='viewCredential'>
                    <ClusterCompareMultiSelect dialogHandler={(val: any) => setOpenPopUp(false)} compareCallBack={setNodesCompareList} fromComponent='ComparisonTable' history={props.location} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClusterComparison);


interface ClusterComparisonProps {
    toggleLoader: any;
    location: any;
}

ClusterComparison.defaultProps = {

}
