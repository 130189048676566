import React from 'react';
import { Dashboard, Web, Reorder, Business, Home, LockOpen } from '@material-ui/icons';
import { SupervisedUserCircle } from '@material-ui/icons';

const SidebarMenu = [
    {
        name: 'Home',
        icon: <Home />,
        url: '/landing',
        tooltip: 'Home',
        placement: 'left'


    },
    {
        name: 'Dashboard',
        icon: <Dashboard />,
        url: '/page/dashboard',
        tooltip: 'Dashboard',
        placement: 'left'


    },
    {
        name: 'Schema',
        icon: <Web />,
        url: '/page/schema-list',
        tooltip: 'Schema',
        placement: 'right'

    },
    {
        name: 'BPM',
        icon: <Business />,
        url: '/page/bpm',
        tooltip: 'BPM',
        placement: 'right'

    },
    // {
    //     name: 'Schema',
    //     icon: <Web />,
    //     url: '/page/schema-list',
    //     tooltip: 'Schema',
    //     placement: 'right'

    // },
    {
        name: 'List',
        icon: <Reorder />,
        url: '/page/list',
        tooltip: 'List',
        placement: 'right'

    },
    {
        name: 'Admin',
        icon: <SupervisedUserCircle />,
        url: '/page/admin/users',
        tooltip: 'Admin',
        placement: 'right'

    },
    {
        name: 'Logout',
        icon: <LockOpen />,
        url: '/landing',
        tooltip: 'Logout',
        placement: 'right'

    },
    // {
    //     name: 'Envelope',
    //     icon: <img src={require('../../assets/images/email.png')} alt="" />,
    //     url: '/page/envelope-list',
    //     tooltip: 'Envelope',
    //     placement: 'right'

    // },
    // {
    //     name: 'Secure Data',
    //     icon: <img src={require('../../assets/images/folder.png')} alt="" />,
    //     url: '/page/secure-data',
    //     tooltip: 'Secure Data',
    //     placement: 'right'

    // },   
    // {
    //     name: 'File',
    //     icon: <img src={require('../../assets/images/file.png')} alt="" />,
    //     url: '/page/file-list',
    //     tooltip: 'File',
    //     placement: 'right'

    // },
    // {
    //     name: 'Admin',
    //     icon: <img src={require('../../assets/images/man.png')} alt="" />,
    //     url: '',
    //     tooltip: 'Admin',


    // },
    // {
    //     name: 'Role',
    //     icon: <img src={require('../../assets/images/role.png')} alt="" />,
    //     url: '',
    //     tooltip: 'Role'

    // }
];

export default SidebarMenu;