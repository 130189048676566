import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    IconButton, Dialog, DialogContent, Button, TextField, InputAdornment, MenuItem, Select, Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ApiEndPoints } from '../../../models/api-endpoint';
import Toaster from '../../../services/toaster.services';
import { toggleLoader } from '../../../redux/actions/loader.action';
import RestApiService from '../../../services/http-services';

import SubHeaderComponent from '../../../shared/components/sub-header/sub-header';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from 'formik';
import * as yup from 'yup';
import NestedTable from './NestedTable/NestedTable';

import "../Setting/Setting.scss";
import "./cluster.scss"
import { values } from 'lodash';
import AppConstants from '../../../core/constants/appConstants';

const ClusterNodeComponent = (props: settingProps) => {
    const [pageSize] = useState(10);
    const [currentPageNo] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [order] = useState<any>('desc');
    const [orderBy] = useState<any>('CreatedAt');
    const filterType: any[] = ['All'];
    const [filterTypes, setfilterType] = useState([]);
    const [searchKey, setSearchKey] = useState<any>();
    const [listData, setListData] = useState([]);
    const [nodeListData, setNodeListData] = useState([]);
    const [status, setStatus] = useState('');
    const [isBindFilterTypes, setIsBindFilterTypes] = useState(true);
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopup1, setOpenPopup1] = useState(false);
    const [initialValue, setInitialValue] = useState<any>({
        ClusterFamily: '',
        ClusterName: '',
    });
    const [nodeInitialValue, setNodeInitialValue] = useState<any>({
        SelectCluster: '',
        ClusterUID: '',
        NodeName: '',
        NodeUrl: '',
        NodeType: ""
    })
    const [showPassword, setShowPassword] = useState(false);
    const [viewCredential, setViewCredntial] = useState(false)
    const [viewCredentialData, setViewCredntialData] = useState<any>([])
    const [viewCredentialPopupData, setViewCredntialPopupData] = useState([])
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [selectedData, setSelectedData] = useState<any>([])
    const [credntialEditPopup, setCredntialEditPopup] = useState(false)
    const [NodeTypeList, setNodeTypeList] = useState([
        'Master', "Slave", "Replica"
    ])
    const [clusterETId, clusterSV, nodeETId, nodeSV] = [AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.SV,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.SV]

    function handleCreateCluster(value: any) {
        props.toggleLoader(true);
        const bodyCluster = {
            "ETId": clusterETId, "SV": clusterSV,
            "Data": [value]
        }

        RestApiService.invoke(ApiEndPoints.CREATE_CLUSTER, null, bodyCluster, null, {
            ETId: AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.ETId,
            SV: AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.SV
        }).then(res => {
            props.toggleLoader(false);
            setOpenPopup1(false)
            getClusterList({})
            Toaster.successToaster("Create Node", "Cluster Created Succesfully")

        }).catch((error) => {
            props.toggleLoader(false);
            console.log(error, "error")
            setOpenPopup1(false)
            Toaster.errorToaster(error);
        })
    }

    function handleCreateClusterNode(value: any) {
        const bodyNode = {
            "ETId": nodeETId, "SV": nodeSV,
            "Data": [{ 'ClusterUID': value.ClusterUID, 'NodeName': value.NodeName, 'NodeUrl': value.NodeUrl, "NodeType": value.NodeType }]
        }
        props.toggleLoader(true);

        RestApiService.invoke(ApiEndPoints.CREATE_NODE, null, bodyNode, null, {
            ETId: nodeETId,
            SV: nodeSV
        }).then(res => {
            setOpenPopup(false)
            props.toggleLoader(false);
            getNodeList()
            Toaster.successToaster("createdClusterNode", "Node created successfully")
        }).catch((errors) => {
            console.log(errors.message, "errorClust")
            props.toggleLoader(false);
            setOpenPopup(false)
            Toaster.errorToaster(errors.message);
        })

    }

    useEffect(() => {
        setViewCredntialPopupData(nodeListData.filter((data) => data.UID === viewCredentialData[0]))
    }, [nodeListData, viewCredentialData])

    const getClusterList = (query, type?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_CLUSTER_LIST, null, null, query, {
            ETId: clusterETId,
            SV: clusterSV
        }).then(res => {
            props.toggleLoader(false);
            setListData(res.data.data);
            if (parseInt(res.data.totalRecord) % pageSize === 0) {
                setTotalPage((parseInt(res.data.totalRecord) / pageSize));
            }
            else {
                setTotalPage((Math.floor(parseInt(res.data.totalRecord) / pageSize)) + 1);
            }

        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }


    const getNodeList = (_type?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_NODE_LIST, null, null, null, {
            ETId: nodeETId,
            SV: nodeSV
        }).then(res => {
            props.toggleLoader(false);
            setNodeListData(res.data.data);
        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }

    useEffect(() => {
        getNodeList()
        if (pageSize && currentPageNo && orderBy && order) {
            getClusterList({});
        }
    }, [pageSize, currentPageNo, order, orderBy]);

    const onFilterUserStatus = (status: any) => {
        setStatus(status);
        getClusterList({})
    }

    const onSearchData = (searchData: any) => {
        setSearchKey(searchData);
        getClusterList({ searchKey: searchData })
    }

    const onCancelSearch = (value: any) => {
        setSearchKey('');
        if (value) {
            getClusterList({});
        }
    }

    function compareHandler(compareList, selectedData) {
        props.history.push({ pathname: selectedData.length === 0 ? "/page/admin/clusters/compare" : `/page/admin/clusters/compare/comparison`, state: { clusterCompareList: selectedData } });
    }

    const handleSelectChange = (data: any) => {
        setNodeInitialValue({
            SelectCluster: data.name,
            ClusterUID: data.value,
            NodeName: nodeInitialValue.NodeName,
            NodeUrl: nodeInitialValue.NodeUrl,
            NodeType: nodeInitialValue.NodeType
        })
    }
    const handleSelectChangeNode = (data: any) => {
        setNodeInitialValue({
            SelectCluster: nodeInitialValue.SelectCluster,
            ClusterUID: nodeInitialValue.ClusterUID,
            NodeName: nodeInitialValue.NodeName,
            NodeUrl: nodeInitialValue.NodeUrl,
            NodeType: data.value
        })
    }
    const CreateClusterNodeRenderForm = ({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }: any) => {
        if (initialValue) return (

            <form className="CreateClusterNode-form" onSubmit={handleSubmit} >
                <div className="ClusterNodemainDiv" >
                    <div className="ClusterNodeHeadingDiv" >
                        Create Cluster Node
                    </div >
                    <div className="formContentDiv" >
                        <div className="nodeIdIv" >
                            <div className='nodeIdHeading' >
                                Select Cluster
                            </div >
                            <div className="nodeIdinput select" >

                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    label="Age"
                                    value={values.ClusterUID}
                                    name="SelectCluster"
                                    onChange={(e) => { handleSelectChange(e.target) }}
                                >
                                    {listData.map((data) => <MenuItem value={data.UID} key={data.UID} >{data.ClusterName}</MenuItem>)}
                                    {/* <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </div>
                        </div >
                        <div className="nodeIdIv" >
                            <div className='nodeIdHeading' >
                                Cluster UID
                            </div >

                            <div className="nodeIdinput" >
                                <TextField id="outlined-basic" variant="outlined"
                                    placeholder='6enajfna-9Adbka1dssd-addsds76'
                                    size="small"
                                    value={values.ClusterUID}
                                    name="ClusterUID"
                                    onChange={handleChange}
                                    disabled={true}
                                />
                                {errors.ClusterUID && touched.ClusterUID && <div className="alert alert-danger my-3">{errors.ClusterUID}</div>}
                            </div >
                        </div >
                        <div className="formContentDiv" >
                            <div className="nodeIdIv" >
                                <div className='nodeIdHeading' >
                                    Node Type
                                </div >
                                <div className="nodeIdinput select" >

                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        label="Age"
                                        value={values.NodeType}
                                        name="SelectCluster"
                                        onChange={(e) => { handleSelectChangeNode(e.target) }}
                                    >

                                        {NodeTypeList.map((data) => <MenuItem value={data}>{data}</MenuItem>)}
                                        {/* {listData.map((data) => <MenuItem value={data.UID} key={data.UID} >{data.ClusterName}</MenuItem>)} */}
                                    </Select>
                                </div>
                            </div >

                            <div className="nodeIdIv" >
                                <div className='nodeIdHeading' >
                                    Node Name
                                </div >

                                <div className="nodeIdinput" >
                                    <TextField id="outlined-basic" variant="outlined"
                                        placeholder='Node Name'
                                        size="small"
                                        value={values.NodeName}
                                        name="NodeName"
                                        onChange={handleChange}
                                    />
                                    {errors.NodeName && touched.NodeName && <div className="alert alert-danger my-3">{errors.NodeName}</div>}
                                </div>
                            </div >
                            <div className="nodeIdIv" >
                                <div className='nodeIdHeading' >
                                    Node Url
                                </div >

                                <div className="nodeIdinput" >
                                    <TextField id="outlined-basic" variant="outlined"
                                        size="small"
                                        placeholder='https://dev-platform.epcone.com/'
                                        value={values.NodeUrl}
                                        name="NodeUrl"
                                        onChange={handleChange}
                                    />
                                    {errors.NodeUrl && touched.NodeUrl && <div className="alert alert-danger my-3">{errors.NodeUrl}</div>}
                                </div>
                            </div >
                            <div className="buttonDiv" >
                                <div className="CloseBtn" >
                                    <Button variant="contained" size='medium' className='saveBtn' onClick={handleSubmit} > Submit</Button >
                                </div >
                                <div className="CloseBtn" >
                                    <Button
                                        variant="contained"
                                        size='medium'
                                        className={"btn-primary"}
                                        onClick={
                                            () => {
                                                setOpenPopup(false)
                                            }
                                        }
                                    >
                                        Close
                                    </Button >
                                </div >
                            </div >
                        </div >
                    </div>
                </div >
            </form >
        )
    }
    const CreateClusterRenderForm = ({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }: any) => {
        if (initialValue) return (
            <form className="CreateClusterNode-form" onSubmit={handleSubmit} >
                <div className="ClusterNodemainDiv" >
                    <div className="ClusterNodeHeadingDiv" >
                        Create Cluster
                    </div >
                    <div className="formContentDiv" >
                        <div className="nodeIdIv" >
                            <div className='nodeIdHeading' >
                                Cluster Family
                            </div >

                            <div className="nodeIdinput" >
                                <TextField id="outlined-basic" variant="outlined"
                                    size="small"
                                    placeholder='slave cluster 101'
                                    value={values.ClusterFamily}
                                    name="ClusterFamily"
                                    onChange={handleChange} />
                                {errors.ClusterFamily && touched.ClusterFamily && <div className="alert alert-danger my-3">{errors.ClusterFamily}</div>}
                            </div >
                        </div >
                        <div className="nodeIdIv" >
                            <div className='nodeIdHeading' >
                                Cluster Name
                            </div >

                            <div className="nodeIdinput" >
                                <TextField id="outlined-basic" variant="outlined"
                                    size="small"
                                    placeholder='Culter 101'
                                    value={values.ClusterName}
                                    name="ClusterName"
                                    onChange={handleChange}
                                />
                                {errors.ClusterName && touched.ClusterName && <div className="alert alert-danger my-3">{errors.ClusterName}</div>}
                            </div >
                        </div >
                        <div className='textDiv'>
                            <span className='textPrimary'>*Cluster name cannot be edited after save / submission</span>
                        </div>
                        <div className="buttonDiv" >
                            <div className="CloseBtn" >
                                <Button variant="contained" size='medium' className='saveBtn' onClick={handleSubmit}> Submit</Button >
                            </div>
                            <div className="CloseBtn" >
                                <Button
                                    variant="contained"
                                    size='medium'
                                    className={"btn-primary"}
                                    onClick={
                                        () => {
                                            setOpenPopup1(false)
                                        }
                                    }
                                >
                                    Close
                                </Button >
                            </div >
                        </div >
                    </div >
                </div >
            </form >
        )
    }
    const memoizedFunction = useCallback((values: any) => setViewCredntialData(values), []);
    const viewCredentialMemoized = useCallback((value) => setViewCredntial(value), []);
    const gridSelectData = useCallback((values) => {
        listData.forEach((clusterObject) => {
            values.forEach((nodeObject) => {
                if (clusterObject.UID === nodeObject.ClusterUID) {
                    nodeObject["NodeName"] = nodeObject.NodeName + " (" + clusterObject.ClusterName + ")"
                }
            }
            )
        });
        setSelectedData(values)
    }, [listData]);

    function statusColumnFormatter(value: any) {
        if (value.data.ES > 70) {
            return (
                <div className='status-column'>
                    <div className="status-column-circle green">
                    </div>
                    <div className='status-column-btn'>
                        <span className='status-column-text'>Done</span>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='status-column'>
                    <div className="status-column-circle yellow">
                    </div>
                    <span className='status-column-text'>In Progress</span>
                </div>
            )
        }
    }


    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='setting-page'>
                        <div className="Innerheading clusterCompare">
                            <SubHeaderComponent
                                headingText={'Cluster Management'}
                                searchLabel={'Cluster or Node'}
                                showBtn={true}
                                showSearch={true}
                                searchKey={searchKey}
                                btnText={"Create Cluster"}
                                filterArray={filterTypes}
                                filterUserStatus={(status: any) => onFilterUserStatus(status)}
                                onSearchData={(searchData: any) => onSearchData(searchData)}
                                onCancelSearch={(value: any) => onCancelSearch(value)}
                                onButtonClick={() => setOpenPopup1(true)}
                                btnText2={"Create Cluster Node"}
                                showBtn2={true}
                                onButtonClick2={(popUp) => { setOpenPopup(popUp) }}
                                showBtn3={true}
                                btnText3={"Compare"}
                                onButtonClick3={(e) => compareHandler(e, selectedData)}
                                blurBtn={selectedData.length === 0 ? false : true}
                            />
                            <div className="Innerdetails">
                                <div className="grid">
                                    <NestedTable
                                        showActions={true}
                                        actionBtnIcon={"share"}
                                        viewCredential={viewCredentialMemoized}
                                        viewCredentialData={memoizedFunction}
                                        gridSelectData={gridSelectData}
                                        dataSource={listData}
                                        nestedDataSource={nodeListData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openPopup1} >
                <DialogContent className='CreateForm'>
                    <Formik
                        initialValues={initialValue}
                        render={CreateClusterRenderForm}
                        enableReinitialize
                        validationSchema={
                            yup.object().shape({
                                ClusterFamily: yup.string().required('Cluster Family Field is Required'),
                                ClusterName: yup.string().required('Cluster Name Field is Required'),
                            })}
                        onSubmit={value => handleCreateCluster(value)}>
                    </Formik>
                </DialogContent>
            </Dialog>
            <Dialog open={openPopup}>
                <DialogContent className='CreateForm'>
                    <Formik
                        initialValues={nodeInitialValue}
                        render={CreateClusterNodeRenderForm}
                        enableReinitialize
                        validationSchema={
                            yup.object().shape({
                                ClusterUID: yup.string().required('Cluster UID Field is Required'),
                                NodeName: yup.string().required('NodeName Field is Required'),
                                NodeUrl: yup.string().required('Node URL Field is Required'),
                            })}
                        onSubmit={value => handleCreateClusterNode(value)}>
                    </Formik>
                </DialogContent>
            </Dialog>
            <Dialog open={viewCredential}>
                {console.log(viewCredentialData, "viewCredentialData")}
                <DialogContent className='viewCredential'>
                    <div className="CreateClusterNode-form"  >
                        <div className="ClusterNodemainDiv" >
                            <div className="ClusterNodeHeadingDiv" >
                                View Node Credentials
                            </div >
                            <div className="formContentDiv" >
                                <div className="nodeIdIv" >
                                    <div className='nodeIdHeading' >
                                        Node Type
                                    </div >

                                    <div className="nodeIdinput" >
                                        {viewCredentialData?.NodeType}
                                    </div >
                                </div >
                                <div className="nodeIdIv" >
                                    <div className='nodeIdHeading' >
                                        Cluster
                                    </div >

                                    <div className="nodeIdinput" >
                                        {viewCredentialData?.NodeName}
                                    </div>
                                </div >
                                <div className="nodeIdIv" >
                                    <div className='nodeIdHeading' >
                                        Status
                                    </div >

                                    <div className="nodeIdinput" >
                                        Done
                                    </div >
                                </div >
                                <Divider variant="fullWidth" />
                                <div className="nodeIdIv" >
                                    <div className='nodeIdHeading' >
                                        Node Id
                                    </div >

                                    <div className="nodeIdinput" >
                                        {viewCredentialData?._id}
                                    </div >
                                </div >
                                <div className="nodeIdIv" >
                                    <div className='nodeIdHeading' >
                                        Node UID
                                    </div >

                                    <div className="nodeIdinput" >
                                        {viewCredentialData?.UID}
                                    </div >
                                </div >
                                <div className="nodeIdIv" >
                                    <div className='nodeIdHeading' >
                                        Node URL
                                    </div >

                                    <div className="nodeIdinput" >
                                        {viewCredentialData?.NodeUrl}
                                    </div >
                                </div >
                                <Divider variant="fullWidth" />
                                <div className="buttonDiv" >
                                    <div className="CloseBtn" >
                                        <Button
                                            variant="contained"
                                            size='medium'
                                            className={"btn-primary"}
                                            onClick={
                                                () => {
                                                    setViewCredntial(false)
                                                }
                                            }
                                        >
                                            Close
                                        </Button >
                                    </div >
                                    <div className="EditBtn" >
                                        <Button
                                            variant="contained"
                                            size='medium'
                                            className={"btn-primary"}
                                            onClick={() => { setCredntialEditPopup(true) }}
                                        >
                                            Edit
                                        </Button >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </DialogContent>
            </Dialog>

            <Dialog open={credntialEditPopup}>
                <DialogContent className='credentialEdit'>
                    <div className="credentialEditMain">
                        <div className="closeBtnDiv">
                            <div className="heading">
                                Edit Credential
                            </div>
                            <div className="btnDiv">
                                <IconButton aria-label="close" onClick={() => setCredntialEditPopup(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className="credentialContentDiv">
                            <div className="nodeIdIv" >
                                <div className='nodeIdHeading' >
                                    Cluster Name
                                </div >

                                <div className="nodeIdinput" >
                                    <TextField id="outlined-basic" variant="outlined"
                                        placeholder={viewCredentialData.NodeName}
                                        size="small"
                                        name="ClusterUID"
                                        disabled={false}
                                    />
                                </div >
                            </div >
                            <div className="nodeIdIv" >
                                <div className='nodeIdHeading' >
                                    Node Name
                                </div >

                                <div className="nodeIdinput" >
                                    <TextField id="outlined-basic" variant="outlined"
                                        placeholder={viewCredentialData.NodeName}
                                        size="small"
                                        // value={values.ClusterUID}
                                        name="ClusterUID"
                                        // onChange={handleChange}
                                        disabled={false}
                                    />
                                    {/* {errors.ClusterUID && touched.ClusterUID && <div className="alert alert-danger my-3">{errors.ClusterUID}</div>} */}
                                </div >
                            </div >
                        </div>
                        <div className="BtnDiv">
                            <div className="SubmitBtn">
                                <Button variant="contained"
                                    size='small'
                                    className={"btn-primary"}>Submit</Button >
                            </div >
                        </div>

                    </div>
                </DialogContent>
            </Dialog>

        </div>
    )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClusterNodeComponent);


interface settingProps {
    toggleLoader: any;
    history: any;
}