import { Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import DashboardComponent from './dashboard/dashboard';
import FileListComponent from './file/fileList';
import SchemaListComponent from './schema/schemaList';
import NewSchemaListComponent from './schema/newSchemaList';
import BPMComponent from './BPM/BPM';
import PageListComponent from './pagelist/List';
import UserComponent from '../pages/Admin/User/User';
import RoleListComponent from '../pages/Admin/Role/RoleList';
import SettingComponent from '../pages/Admin/Setting/Setting';
import ChangePasswordComponent from '../pages/security/ChangePassword';
import { JwtServices } from '../services/jwt.services';
import ClusterComponent from './Admin/Cluster/Cluster';
import ClusterCompareMultiSelect from './Admin/Cluster/ClusterCompareMultiSelect/ClusterCompareMultiSelect';
import ClusterComparison from './Admin/Cluster/ClusterCompare/ClusterCompare';


const jwtServices = new JwtServices();
const PrivateRoute = ({ component: Component, ...rest }: any) => (

    <Route {...rest} render={(props) => (
        jwtServices.isLogin() === true
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />

)

{/* <Route {...rest} render={(props) => (
    <Component {...props} />        
)} />

) */}



const PageRoute = (props: any) => {
    return (
        <Switch>
            <PrivateRoute exact path={`${props.match.path}/dashboard`} component={DashboardComponent} />
            <PrivateRoute exact path={`${props.match.path}/list`} component={PageListComponent} />
            {/* <PrivateRoute exact path={`${props.match.path}/schema-list`} component={SchemaListComponent} />  */}
            <PrivateRoute exact path={`${props.match.path}/schema-list`} component={NewSchemaListComponent} />
            <PrivateRoute exact path={`${props.match.path}/bpm`} component={BPMComponent} />
            <PrivateRoute exact path={`${props.match.path}/admin`} component={UserComponent} />
            <PrivateRoute exact path={`${props.match.path}/admin/users`} component={UserComponent} />
            <PrivateRoute exact path={`${props.match.path}/admin/roles`} component={RoleListComponent} />
            <PrivateRoute exact path={`${props.match.path}/admin/settings`} component={SettingComponent} />
            <PrivateRoute exact path={`${props.match.path}/change-password`} component={ChangePasswordComponent} />
            <PrivateRoute exact path={`${props.match.path}/admin/clusters`} component={ClusterComponent} />
            <PrivateRoute exact path={`${props.match.path}/admin/clusters/compare`} component={ClusterCompareMultiSelect} />
            <PrivateRoute exact path={`${props.match.path}/admin/clusters/compare/comparison`} component={ClusterComparison} />
        </Switch>

    )
}

export default PageRoute;