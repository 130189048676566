import { Route, Redirect, Switch } from 'react-router-dom';
import React from 'react';
import PagesComponent from './app/pages/pages';
import LoginComponent from './app/pages/security/Login';
import SignUpComponent from './app/pages/security/SignUp';
import LandingPageComponent from './app/pages/LandingPage';


const AppRoute = (
    <Route>
        <Switch>
            <Route path="/page" component={PagesComponent} />
            <Route path="/logincomponent" component={LoginComponent} />
            <Route path="/signup" component={SignUpComponent} />
            <Route path="/landing" component={LandingPageComponent} />
            <Redirect from='*' to='/landing' />
        </Switch>
    </Route>
);

export default AppRoute;