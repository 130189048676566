import React, { Component, useEffect } from 'react'
import { Pagination } from 'react-bootstrap';
import './paging.component.scss';
import { TextField, MenuItem } from '@material-ui/core';

const PagingComponent = (props: ItemListProps) => {

    const pageSizeList: any[] = [{ "name": 10 }, { "name": 25 }, { "name": 50 }];
    const [prePage, setPrePage] = React.useState();
    const [nextPage, setNextPage] = React.useState();
    const [pageSize, setPageSize] = React.useState();

    const goToPreviousPage = (page: any) => {
        setPrePage(page);
        props.goToPreviousPage(page);
    }

    const goToNextPage = (page: any) => {
        setNextPage(page);
        props.goToNextPage(page);
    }

    useEffect(() => {
        if (pageSize) {
            props.changePageSize(pageSize);
        }
    }, [pageSize]);

    const changePageSize = (value: any) => {
        setPageSize(value);

    }
    return (
        <div className={`pagination-cover`}>

            <div className="total-result">
                <div className="total-size">
                    <p>Showing {props.currentPageNo} of {props.totalPage} pages ({props.totalRecord} records)</p>
                    {props.showPageSize && <div className="filter-box pagination-pageSize">
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            label="Page Size"
                            className="select-field page-size-select-field"
                            margin="normal"
                            variant="outlined"
                            value={props.pageSize}
                            onChange={(event: any) => { changePageSize(event.target.value) }}
                        >
                            {pageSizeList.map((item, index) => (
                                <MenuItem key={`pageSize_${index}`} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>}
                </div>
                <Pagination>
                    <Pagination.Item
                        onClick={() => {
                            if (props.currentPageNo > 1)
                                goToPreviousPage(1)
                        }}
                        disabled={props.currentPageNo === 1}

                    >
                        First Page
                   </Pagination.Item>

                    <Pagination.Prev
                        onClick={() => {
                            if (props.currentPageNo != 1) {
                                let page = props.currentPageNo - 1;
                                goToPreviousPage(page)
                            } else {
                                goToPreviousPage(props.currentPageNo)
                            }
                        }}
                        disabled={props.currentPageNo === 1}
                    />

                    <Pagination.Item disabled>{props.currentPageNo}</Pagination.Item>

                    <Pagination.Next
                        onClick={() => {
                            if (!props.isLessthanTotalElement && (props.currentPageNo < props.totalPage)) {
                                let page = props.currentPageNo + 1;
                                goToNextPage(page)
                            }
                            else {
                                goToNextPage(props.currentPageNo);
                            }
                        }}
                        disabled={(props.currentPageNo === props.totalPage)}
                    />
                    <Pagination.Item
                        onClick={() => {
                            if (props.currentPageNo < props.totalPage)
                                goToNextPage(props.totalPage)
                        }}
                        disabled={(props.currentPageNo === props.totalPage)}
                    >
                        Last Page
                   </Pagination.Item>
                </Pagination>

            </div>

        </div>
    )
}


export default PagingComponent;

interface ItemListProps {
    goToPreviousPage?: any;
    goToNextPage?: any;
    changePageSize?: any;
    isLessthanTotalElement?: boolean;
    pageSize?: number;
    currentPageNo?: number;
    totalPage?: number;
    totalRecord?: number;
    showPageSize?: boolean;
}


PagingComponent.defaultProps = {
    showPageSize: true
}


