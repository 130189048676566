import React, { useEffect, useState } from 'react'
import SubHeaderComponent from '../../../../shared/components/sub-header/sub-header'
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';
import RestApiService from '../../../../services/http-services';
import { Button } from 'devextreme-react'
import { useHistory } from 'react-router-dom';
import { ApiEndPoints } from '../../../../models/api-endpoint';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Toaster from '../../../../services/toaster.services';
import { toggleLoader } from '../../../../redux/actions/loader.action';
import "../ClusterCompareMultiSelect/ClusterCompareMultiSelect.scss"
import 'devextreme/dist/css/dx.light.css'
import AppConstants from '../../../../core/constants/appConstants';

type Props = {
    toggleLoader: any;
    history: any;
    fromComponent?: string;
    compareCallBack?: (nodeSetter: any) => void;
    dialogHandler?: (dialogState: boolean) => void;
}

const ClusterCompareMultiSelect = (props: Props) => {
    const [clusterList, setClusterList] = useState([]);
    const [nodeDropdownValue, setNodeDropdownValue] = useState([])
    const [clusterDropdownValue, setClusterDropdownValue] = useState([])
    const [nodeList, setNodeList] = useState([]);
    const [selectedList, setSelectedList] = useState([])
    const [nodeRenderList, setNodeRenderList] = useState([...nodeList, ...clusterList])

    let history = useHistory();
    const [clusterETId, clusterSV, nodeETId, nodeSV] = [AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.clusters.SV,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.ETId,
    AppConstants.SYSTEM_GENERATED_ETIDS.clustering.nodes.SV]

    useEffect(() => {
        getClusterList();
        getNodeList();
    }, [])

    function getClusterList() {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_CLUSTER_LIST, null, null, null, {
            ETId: clusterETId,
            SV: clusterSV
        }).then(res => {
            setClusterList(res.data.data);
            props.toggleLoader(false);
        }).catch((error) => {
            props.toggleLoader(false);
            console.log(error, "clusterCompare.tsx=>error")
            Toaster.errorToaster(error);
        })
    }

    function getNodeList() {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_NODE_LIST, null, null, null, {
            ETId: nodeETId,
            SV: nodeSV
        }).then(res => {
            console.log(res.data, "data");
            const tempData = res.data.data;
            tempData.forEach((clusterData) => {
                delete Object.assign(clusterData, { ["ClusterName"]: clusterData["NodeName"] })["NodeName"];
            });
            setNodeList(tempData);
            props.toggleLoader(false);
        }).catch((error) => {
            props.toggleLoader(false);
            console.log(error, "error")
            Toaster.errorToaster(error);
        })
    }

    function treeViewItemSelectionChanged(e: any) {

        if (e.component.getSelectedNodeKeys() !== undefined) {
            setNodeDropdownValue(e.component.getSelectedNodeKeys())
        }
        let result = nodeList.filter(object => {
            return e.component.getSelectedNodeKeys().includes(object.ClusterUID);
        }).map(object => {
            return { ...object, NodeName: object.UserName };
        });
        clusterList.forEach((clusterObject) => {
            result.forEach((nodeObject) => {
                if (clusterObject.UID === nodeObject.ClusterUID) {
                    nodeObject["NodeName"] = `${nodeObject.NodeName} (${clusterObject.ClusterName})`
                }
            }
            )
        });
        setSelectedList(result)

    }

    const NodeViewRender = () => {
        return (
            <TreeView
                dataSource={[...nodeList, ...nodeRenderList]}
                dataStructure="plain"
                keyExpr="UID"
                parentIdExpr="ClusterUID"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={true}
                displayExpr="ClusterName"
                selectByClick={true}
                onItemSelectionChanged={treeViewItemSelectionChanged}
            />
        )
    }

    const clusterViewRender = () => {
        return (
            <TreeView
                dataSource={clusterList}
                keyExpr="UID"
                parentIdExpr="_id"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={false}
                displayExpr="ClusterName"
                selectByClick={true}
                onSelectionChanged={onClusterSelection}
            />
        )
    }
    const onClusterSelection = (e) => {
        let result = [...clusterList].filter(object => {
            return e.component.getSelectedNodeKeys().includes(object.UID);
        })
        setNodeRenderList(result.map((data) => {
            data.selected = false;
            return data
        }))
        if (e.component.getSelectedNodeKeys() !== undefined) {
            setClusterDropdownValue(e.component.getSelectedNodeKeys())
        }
    }
    function compareBtnHandler() {
        console.log(props.fromComponent);
        if (props.fromComponent === 'ComparisonTable') {
            props.compareCallBack(selectedList);
            props.dialogHandler(false);
        }
        else {
            props.history.push({ pathname: '/page/admin/clusters/compare/comparison', state: { clusterCompareList: selectedList } })
        }
    }

    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='setting-page'>
                        <div className="Innerheading  cluster-Compare">
                            {props.fromComponent === 'ComparisonTable' ? null : <SubHeaderComponent
                                headingText={'Cluster Comparison'}
                                isbackEnabled
                                onBackButtonClick={() => history.goBack()}
                            />}
                            <div className="Innerdetails">
                                <div className="grid clusterCompare">
                                    <div className="DropdownDiv">
                                        <div className="clusterDropdownDiv">
                                            <p className="dropdownHeading">
                                                Cluster
                                            </p>
                                            <div className="DropDown">
                                                <DropDownBox
                                                    value={clusterDropdownValue}
                                                    valueExpr="UID"
                                                    displayExpr="ClusterName"
                                                    placeholder="Select a value..."

                                                    dataSource={clusterList}
                                                    contentRender={clusterViewRender}
                                                />
                                            </div>
                                        </div>
                                        <div className="nodeDropdownDiv">
                                            <p className="dropdownHeading">
                                                Node
                                            </p>
                                            <div className="DropDown">
                                                <DropDownBox
                                                    value={nodeDropdownValue}
                                                    valueExpr="UID"
                                                    displayExpr="ClusterName"
                                                    placeholder="Select a value..."
                                                    dataSource={nodeList}
                                                    contentRender={NodeViewRender}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="compareBtn">
                                        <Button
                                            onClick={compareBtnHandler}
                                            text="Compare"
                                            type="default"
                                            stylingMode="contained"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClusterCompareMultiSelect);


