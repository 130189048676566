import React, { useEffect, useState, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { List, ListItem, Paper, Checkbox, Button, Tooltip, Grid, Card, CardHeader, Avatar, IconButton, CardMedia, Menu, MenuItem, ListItemIcon, ListItemText, CardContent, Typography, Dialog, DialogTitle, DialogContent, TextField, InputAdornment, DialogActions } from '@material-ui/core';
import "./RoleList.scss";
import { Pagination } from 'react-bootstrap';
import { ApiEndPoints } from '../../../models/api-endpoint';
import Toaster from '../../../services/toaster.services';
import { toggleLoader } from '../../../redux/actions/loader.action';
import { MoreVert, Visibility, Create, Close } from '@material-ui/icons';
import RestApiService from '../../../services/http-services';
import MultipleSelectComponent from '../../../shared/components/elements/MultipleDropDown/MultipleSelectDropDown';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SubHeaderComponent from '../../../shared/components/sub-header/sub-header';
import DynamicList from '../../../shared/components/dynamic-list/dynamic-list';
import SearchBar from 'material-ui-search-bar';
const RoleListComponent: React.FC<RoleProps> = (props: RoleProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = React.useState<any>({});
    const [mode, setMode] = React.useState<any>('add');
    const [scope, setScope] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [roleId, setRoleId] = useState('');
    const pageSizeList: any[] = [{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }];
    const [open, setOpen] = React.useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [totalPage, setTotalPage] = useState(20);
    const [totalRecord, setTotalRecord] = useState(200);
    const scopeList = ['AdminAccess', 'ReadWrite', 'ReadOnly'];
    const [users, setUsers] = React.useState([]);
    const [userRoles, setUserRoles] = React.useState([]);
    const [order, setOrder] = React.useState<any>('desc');
    const [orderBy, setOrderBy] = React.useState<any>('CreatedAt');
    const [checked, setChecked] = React.useState<any[]>([]);
    const [existsUser, setExistsUser] = React.useState<number[]>();
    const [notExistsUser, setNotExistsUser] = React.useState<number[]>();
    const existsUserChecked = intersection(checked, existsUser);
    const notExistsUserChecked = intersection(checked, notExistsUser);
    const [onCancel, setOnCancel] = React.useState(false);
    const [roleUserArray, setRoleUserArray] = React.useState<any[]>([]);
    const [orderIndexedFields, setOrderIndexedFields] = React.useState([{ 'orderBy': 'createdAt', 'order': 'desc', 'isActive': false }, { 'orderBy': 'updatedAt', 'order': 'desc', 'isActive': false }]);

    function not(a: number[], b: number[]) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a: number[], b: number[]) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }
    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const handleCheckedRight = () => {
        setNotExistsUser(notExistsUser.concat(existsUserChecked));
        setExistsUser(not(existsUser, existsUserChecked));
        setChecked(not(checked, existsUserChecked));
        checked.map((item: any) => {
            if (item.roleType === 'ExistingRole') {
                const userData = {
                    UID: item.UID,
                    IsDeleted: true
                }
                roleUserArray.push(userData);
            }
            else {
                const userData = {
                    UserUID: item.id,
                    RoleID: roleId
                }
                const currentIndex = roleUserArray.indexOf(userData);
                roleUserArray.splice(currentIndex, 1);
            }
        })

    };

    const handleCheckedLeft = () => {
        setExistsUser(existsUser.concat(notExistsUserChecked));
        setNotExistsUser(not(notExistsUser, notExistsUserChecked));
        setChecked(not(checked, notExistsUserChecked));
        checked.map((itme: any) => {
            const userData = {
                UserUID: itme.id,
                RoleID: roleId
            }
            roleUserArray.push(userData);
        })
    };
    const handleChangeSearch = (text: any) => {
        if (text !== undefined && text !== '') {
            searchUsers(text, roleId);
        }
    };
    const OnCancelSearch = () => {
        searchUsers(null, roleId);
    }
    const searchUsers = (user?: string, roleID?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.SEARCH_USERS, null, null, { searchKey: user, roleId: roleID }, {
            ETId: 10
        }).then(res => {
            props.toggleLoader(false);
            setExistsUser(res.data.data.ExistingRole);
            setNotExistsUser(res.data.data.NewRole);
        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }
    const getRoles = (from?: string) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_ROLES, null, null, { orderBy: orderBy, order: order }, {
            ETId: 15
        }).then(res => {
            props.toggleLoader(false);
            setRoles(res.data.data);
        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }

    const handleClose = () => {
        setOpen(false);
        getRoles();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const deleteRole = () => {
        const roleData = {
            ETId: 15,
            Data: [{
                UID: roleId,
                IsDeleted: true
            }]
        }
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.DELETE_ROLE, null, roleData, null, {
            ETId: 15
        }).then(res => {
            Toaster.successToaster('Delete Role', 'Role deleted successfully');
            handleClose();
            getRoles();
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response.data.errors);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    const openModal = (action: string, item?: any) => {
        setOpen(true)
        handleMenuClose();
        if (action === 'delete') {
            setRoleName(item.roleName);
            setRoleId(item.id);
            setMode('delete')
        }
        if (action === 'add' && item === undefined) {
            setMode('add')
        }
        if (action === 'add' && item != undefined) {
            setRole(item);
            setRoleName(item.roleName);
            setRoleId(item.id);
            setMode('addUser');
            searchUsers(null, item.id);
            //getUsers();
        }
        if (action === 'view') {
            setMode('view')

        }
        if (action === 'edit') {
            setRole(item);
            setRoleName(item.roleName);
            setMode('edit')
        }
    };

    // const onView = (item) => {
    //     setOpen(true);
    //     setRole(item);
    //     setMode('view');
    //     setRoleName(item.roleName);
    // }

    const addRole = (roleData) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.CREATE_ROLE, null, roleData, null, {
            ETId: 15
        }).then(res => {
            Toaster.successToaster('Create Role', 'Role added successfully');
            handleClose();
            setTimeout(() => {
                getRoles()
            }, 2000);
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response.data.errors);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    const editRole = (roleData) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.UPDATE_ROLE, null, roleData, null, {
            ETId: 15
        }).then(res => {
            Toaster.successToaster('Update Role', 'Role updated successfully ');
            handleClose();
            setTimeout(() => {
                getRoles();
            }, 2000);
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    const saveRole = () => {
        if (mode === 'add') {
            const roleData = {
                ETId: 15,
                // SV: 1,
                Data: [{
                    RoleName: roleName,
                    Scopes: scope
                }]
            }
            addRole(roleData);

        }
        else {
            const roleData = {
                ETId: 15,
                // SV: 1,
                Data: [{
                    UID: role.id,
                    RoleName: roleName,
                    Scopes: scope
                }]
            }
            editRole(roleData);
        }
    }
    const getUsers = () => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_USER_WITHOUT_ROLE, null, null, null, {
            ETId: 10
        }).then(res => {
            props.toggleLoader(false);
            setUsers(res.data.data);

        }).catch(error => {
            props.toggleLoader(false);
            Toaster.errorToaster(error);
        })
    }
    const onPageChange = (page: any) => {
        setCurrentPageNo(page);

    }

    const goToPreviousPage = (page: any) => {
        setCurrentPageNo(page);

    }

    const goToNextPage = (page: any) => {
        if (currentPageNo < totalPage) {
            setCurrentPageNo(page);

        }
    }

    const isLessthanTotalElement = () => {
        if ((currentPageNo * pageSize) === (totalPage - 1))
            return true;
        else
            return false;
    }

    const changePageSize = (value: any) => {
        setPageSize(value);
        setCurrentPageNo(1);

    }


    useEffect(() => {
        if (order && orderBy) {
            getRoles();
        }

    }, [order, orderBy]);

    const addUserRole = () => {
        props.toggleLoader(true);
        const Data = [];
        const userRole = {
            ETId: 16,
            // SV: 1,
            Data: roleUserArray
        }
        RestApiService.invoke(ApiEndPoints.CREATE_USER_ROLE, null, userRole, null, {
            ETId: 16
        }).then(res => {
            props.toggleLoader(false);
            Toaster.successToaster('Add UserRole', 'User role updated successfully');
            handleClose();
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
        });
    }
    const onSortingData = (order, orderBy, data: any) => {
        if (orderBy && order) {
            setOrderBy(orderBy.charAt(0).toUpperCase() + orderBy.slice(1));
            setOrder(order);
            setOrderIndexedFields(data);
        }

    }
    const customList = (items: number[]) => (
        <Paper className="MuiPaper">
            <List dense component="div" role="list">
                {items.map((value: any) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.userName}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );
    return (
        <div className="mainview">
            <div className="maincontent">
                <div className="dashboardcover">
                    <div className='role-page'>
                        <div className="Innerheading">
                            <SubHeaderComponent
                                headingText={'Role Management'}
                                btnText={'Add Role'}
                                showBtn={true}
                                onButtonClick={(value: any) => openModal('add')}></SubHeaderComponent>
                            <div className="Innerdetails">
                                <div className="grid">
                                    <DynamicList
                                        dataList={roles}
                                        columnList={[{ name: "Role Name", db: "roleName" }, { name: "Scopes", db: "scopes" }, { name: "Created At", db: "createdAt", sort: true }, { name: "Updated At", db: "updatedAt", sort: true }]}
                                        orderIndexedFields={orderIndexedFields}
                                        onSortingData={(order: string, orderBy: any, data: any) => onSortingData(order, orderBy, data)}
                                        primaryColumn={"id"}
                                        handleAction={(item: any, action: string) => openModal(action, item)}
                                        showEdit={false}
                                        showUserEdit={true}
                                        showAddMultiUser={true}
                                        showDelete={true}
                                        showView={false}
                                        showActions={true}
                                        isOrderIconChange={true}
                                        keysToHide={['']}
                                        fromComponent={'RoleComponent'}
                                        goToPreviousPage={(page: any) => goToPreviousPage(page)}
                                        goToNextPage={(page: any) => goToNextPage(page)}
                                        changePageSize={(page: any) => changePageSize(page)}
                                        pageSize={pageSize}
                                        currentPageNo={currentPageNo}
                                        totalPage={totalPage}
                                        totalRecord={totalRecord}
                                        isLessthanTotalElement={isLessthanTotalElement()}
                                        showPagination={false}
                                        SNo={false}
                                    />
                                </div>

                                <Dialog open={open} className={`${(mode === 'delete') ? 'roledeletepopup' : 'roleaddpopup'} `} >
                                    <DialogTitle disableTypography>
                                        <div className="roletile">
                                            <VpnKeyIcon />
                                            {mode === 'add' && <Typography component={'span'} variant="h6">Add Role</Typography>}
                                            {mode === 'edit' && <Typography component={'span'} variant="h6">Role Info</Typography>}
                                            {mode === 'addUser' && <Typography component={'span'} variant="h6">Maintain Users in Role</Typography>}
                                            {mode === 'delete' && <Typography component={'span'} variant="h6">Delete Role Name {roleName}</Typography>}
                                        </div>
                                        <IconButton aria-label="close" onClick={handleClose}>
                                            <Close />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent dividers>
                                        <Typography component={'span'} gutterBottom>
                                            {mode === 'delete' && <Grid container spacing={6} className="add-lable">
                                                <Grid item xs={12}>
                                                    <span>Are you sure that you want to delete this Role? </span>
                                                </Grid>
                                            </Grid>}
                                            {mode !== 'delete' && <Grid container spacing={6} className="add-lable">
                                                <Grid item xs={12} md={mode === 'addUser' ? 3 : 2}>
                                                    <span>Role</span>
                                                </Grid>
                                                <Grid item xs={12} md={mode === 'addUser' ? 9 : 10}>
                                                    <div className="form-group">
                                                        <TextField
                                                            id="standard-uncontrolled"
                                                            defaultValue={mode === 'add' ? '' : role.roleName}
                                                            margin="normal"
                                                            disabled={mode === 'view' || mode === 'addUser'}
                                                            onChange={(text) => setRoleName(text.target.value)}
                                                        />
                                                    </div>

                                                </Grid>
                                            </Grid>}
                                            {mode != 'addUser' && mode !== 'delete' && <Grid container spacing={6} className="add-lable">
                                                <Grid item xs={12} md={2}>
                                                    <span>Scopes</span>
                                                </Grid>
                                                <Grid item xs={12} md={10}>
                                                    <div className="multi">
                                                        {
                                                            mode !== 'add' && role && role.scopes &&
                                                            <MultipleSelectComponent
                                                                labelname={"Scopes"}
                                                                data={scopeList}
                                                                mode={mode}
                                                                selectedValues={role.scopes}
                                                                from={"ROLE"}
                                                                handleScopes={(items) => setScope(items)}
                                                            />
                                                        }
                                                        {
                                                            mode === 'add' &&
                                                            <MultipleSelectComponent
                                                                labelname={"Scopes"}
                                                                data={scopeList}
                                                                mode={mode}
                                                                from={"ROLE"}
                                                                handleScopes={(items) => setScope(items)}
                                                            />
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>}
                                            {mode === 'addUser' && mode !== 'delete' && <Grid container spacing={6} className="add-lable">
                                                <Grid item xs={12} md={3}>
                                                    <span>Search Users</span>
                                                </Grid>
                                                {/* <Grid item xs={12} md={10}>
                                                    <div className="multi">
                                                        <MultipleSelectComponent
                                                            labelname={"Roles"}
                                                            data={users}
                                                            mode={"add"}
                                                            from={"RoleList"}
                                                            handleRoleList={(data) => setUserRoles(data)}
                                                        />
                                                    </div>
                                                </Grid> */}
                                                <Grid item xs={12} md={9}>
                                                    <div className="multi"> <SearchBar
                                                        className={'search-bar'}
                                                        placeholder={`Search`}
                                                        value=""
                                                        onChange={handleChangeSearch}
                                                        onCancelSearch={OnCancelSearch}
                                                    /></div> </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <span>Existing Users</span>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <span>Add  Users</span>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    {existsUser ? customList(existsUser) : ''}
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <Grid container direction="column" alignItems="center">
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            className="btnclass"
                                                            onClick={handleCheckedLeft}
                                                            disabled={notExistsUserChecked.length === 0}
                                                            aria-label="move selected left"
                                                        >
                                                            &lt;&lt; Add User
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            className="btnclass"
                                                            onClick={handleCheckedRight}
                                                            disabled={existsUserChecked.length === 0}
                                                            aria-label="move selected right"
                                                        >
                                                            Remove User  &gt;&gt;
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={5}>{notExistsUser ? customList(notExistsUser) : ''}</Grid>
                                            </Grid>}
                                        </Typography>
                                    </DialogContent>
                                    {mode !== 'view' && <DialogActions>
                                        {mode === 'delete' && <Button className={"btn-primary"} variant="contained" onClick={deleteRole} color="primary"> OK</Button>}
                                        {mode === 'delete' && <Button className={"btn-primary"} variant="contained" onClick={handleClose} color="primary"> Cancel</Button>}
                                        {mode !== 'addUser' && mode !== 'delete' && <Button className={"btn-primary"} variant="contained" disabled={scope.length === 0 || roleName === ''} onClick={saveRole} color="primary">
                                            Save
          </Button>}
                                        {mode === 'addUser' && mode !== 'delete' && <Button className={"btn-primary"} variant="contained" disabled={roleUserArray.length === 0} onClick={addUserRole} color="primary">
                                            Save
          </Button>}
                                    </DialogActions>}
                                </Dialog>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RoleListComponent);


interface RoleProps {
    toggleLoader: any;
}