import React from 'react';
import { ArrowBack, People, VpnKey, Settings, SupervisedUserCircle } from '@material-ui/icons';
import { ReactComponent as Logo } from "../../assets/images/clusterManagementIcon.svg"
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
const SidebarAdminMenu = [
    // {
    //     name: '',
    //     icon: <ChevronLeftIcon />,
    //     url: '#',
    //     tooltip: 'Close',
    //     placement: 'right'

    // },

    {
        name: 'Users',
        url: '/page/admin/users',
        icon: <People />,
        tooltip: 'Users',
        placement: 'right'

    },
    {
        name: 'Roles',
        icon: <VpnKey />,
        url: '/page/admin/roles',
        tooltip: 'Roles',
        placement: 'right'

    },
    {
        name: 'Configuration Settings',
        icon: <Settings />,
        url: '/page/admin/settings',
        tooltip: 'Configuration Settings',
        placement: 'right'

    }, {
        name: 'Cluster Management',
        icon: <Logo />,
        url: '/page/admin/clusters',
        tooltip: 'Cluster manager',
        placement: 'right'

    }

];

export default SidebarAdminMenu;