import React, { useEffect, useState, Fragment, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button, Tooltip, Grid, Card, CardHeader, Avatar, IconButton, CardMedia, Menu, MenuItem, ListItemIcon, ListItemText, CardContent, Typography, Dialog, DialogTitle, DialogContent, TextField, InputAdornment, DialogActions, Checkbox,
  FormControlLabel, InputLabel, Chip, Paper, FormControl, Select, FormLabel, RadioGroup, Radio
} from '@material-ui/core';
import "./User.scss";
import { Pagination } from 'react-bootstrap';
import { ApiEndPoints } from '../../../models/api-endpoint';
import Toaster from '../../../services/toaster.services';
import { toggleLoader } from '../../../redux/actions/loader.action';
import { MoreVert, Visibility, Create, Close, AccountCircle, DeviceHubRounded, People, VpnKey, VisibilityOff, Info, Face } from '@material-ui/icons';
import RestApiService from '../../../services/http-services';
import MultipleSelectComponent from '../../../shared/components/elements/MultipleDropDown/MultipleSelectDropDown';
import DynamicList from '../../../shared/components/dynamic-list/dynamic-list';
import SubHeaderComponent from '../../../shared/components/sub-header/sub-header';
import { CookieStorage } from 'cookie-storage';
const UserComponent = (props: UserProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState<any>({});
  const [userObj, setUserObj] = React.useState<any>({});
  const [mode, setMode] = React.useState<any>('add');
  const [roleMode, setRoleMode] = React.useState<any>('add');
  const [hasBlockChainAddress, setHasBlockChainAddress] = React.useState(false);
  const [roleList, setRoleList] = React.useState([]);
  const [userRoleData, setUserRoleData] = React.useState<any>({});
  const [userRoleArray, setUserRoleArray] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState([]);
  const [userId, setUserId] = React.useState('');
  const [UserName, setUserName] = React.useState('');
  const [FirstName, setFirstName] = React.useState('');
  const [LastName, setLastName] = React.useState('');
  const [Password, setPassword] = React.useState('');
  const [retypePassword, setRetypePassword] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openRole, setOpenRole] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [hiddenNewPassword, setHiddenNewPassword] = React.useState(true);
  const [hiddenRetypePassword, setHiddenRetypePassword] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(true);
  const pageSizeList: any[] = [{ "name": 10 }, { "name": 25 }, { "name": 50 }, { "name": 100 }];
  const sortingList: any[] = ['CreatedAt', 'UserName', 'FirstName', 'LastName'];
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [order, setOrder] = React.useState<any>('desc');
  const [orderBy, setOrderBy] = React.useState<any>('CreatedAt');
  const [searchKey, setSearchKey] = React.useState<any>();
  const [userStatus, setUserStatus] = React.useState('');
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const filterUserArray: any[] = ['All', 'Enable', 'Disable'];
  const [orderIndexedFields, setOrderIndexedFields] = React.useState([{ 'orderBy': 'userName', 'order': 'desc', 'isActive': false }, { 'orderBy': 'firstName', 'order': 'desc', 'isActive': false }, { 'orderBy': 'lastName', 'order': 'desc', 'isActive': false }, { 'orderBy': 'createdAt', 'order': 'desc', 'isActive': false }]);
  // const [queryParam, setQueryParam] = useState<any>({ pageNo: 1, pageSize: 1, orderBy: 'CreatedAt', order: 'desc' });
  const myRef = useRef(null)
  const cookies = new CookieStorage();


  // const onScroll = () => {
  //   if (hasMore) {
  //     setCurrentPageNo(currentPageNo + 1);
  //   }
  // }


  const getUsers = (query, type?: string) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_USERS, null, null, query, {
      ETId: 10
    }).then(res => {
      props.toggleLoader(false);
      setUsers(res.data.data);
      // if (type === 'refresh') {
      //   setUsers(res.data.data);
      // } else {
      //   setUsers([...users, ...res.data.data]);
      // }
      // setTotalPage(res.data.totalPage);
      setTotalRecord(res.data.totalRecord);
      if (parseInt(res.data.totalRecord) % pageSize === 0) {
        setTotalPage((parseInt(res.data.totalRecord) / pageSize));
      }
      else {
        setTotalPage((Math.floor(parseInt(res.data.totalRecord) / pageSize)) + 1);
      }
      // if (currentPageNo <= totalPage) {
      //   setHasMore(false);
      // }
    }).catch(error => {
      props.toggleLoader(false);
      Toaster.errorToaster(error);
    })
  }

  const getRoles = () => {
    RestApiService.invoke(ApiEndPoints.GET_ROLES, null, null, null, {
      ETId: 15
    }).then(res => {
      setRoleList(res.data.data);
    }).catch(error => {
      Toaster.errorToaster(error);
    })
  }

  // const handleMenu = (event: React.MouseEvent<HTMLElement>, item) => {
  //   event.stopPropagation();
  //   setUser(item);
  //   item.userAddress !== null ? setIsDisabled(true) : setIsDisabled(false);
  //   getRoles();
  //   setAnchorEl(event.currentTarget);
  //   setUserName(item.userName);
  //   setFirstName(item.firstName);
  //   setLastName(item.lastName);
  //   setUserObj({ UID: item.id })
  // };

  const handleClose = (text: string) => {
    if (text === 'user' || text === 'delete') {
      setOpen(false);
      setOpenChangePassword(false);
      getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
    } else {
      setOpenRole(false);
      getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
    }
  };

  const handleSortOrder = (event) => {
    setUsers([]);
    setCurrentPageNo(1);
    setOrder(event.target.value);
  }

  const handleSort = (event) => {
    setUsers([]);
    setCurrentPageNo(1);
    setOrderBy(event.target.value);
  }



  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const onSortingData = (order, orderBy, data: any) => {

    if (orderBy && order) {
      setOrderBy(orderBy.charAt(0).toUpperCase() + orderBy.slice(1));
      setOrder(order);
      setOrderIndexedFields(data);
    }

  }
  const onUserAccount = (isDeleted: boolean, item: any) => {
    const userData = {
      ETId: 10,
      Data: [{
        UID: item.id,
        IsDeleted: isDeleted
      }]
    }
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.DELETE_USER, null, userData, null, {
      ETId: 10
    }).then(res => {
      if (!isDeleted) {
        Toaster.successToaster('Update User', item.userName + ' user is enabled successfully');
      }
      else {
        Toaster.successToaster('Update User', item.userName + ' user is disabled successfully');
      }

      setOpen(false);
      getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response.data.errors);
      error.response.data.errors.forEach((response) => {
        Toaster.errorToaster(response.message);
      })
    })
  }

  const openModal = (action: string, item?: any) => {
    if (action === 'changePassword') {
      setOpenChangePassword(true)
      setMode('changePassword')
      handleMenuClose();
      setUser(item);
      setUserName(item.userName);
      setUserObj({ UID: item.id })
    }
    if (action === 'delete') {
      setOpen(true)
      setMode('delete')
      setUserId(item.id);
      setUserName(item.userName);
    }
    if (action === 'addUser') {
      setOpen(true)
      handleMenuClose();
      setMode('add')
    }
    if (action === 'add') {
      if (item.roles.length > 0) {
        openUserRoleModal('edit', item.roles)
        setUser(item);
        setUserObj({ UID: item.id })
        getRoles();
      }
      else {
        openUserRoleModal('add')
        setUser(item);
        setUserObj({ UID: item.id })
        getRoles();
      }

    }
    if (action === 'view') {
      setMode('view')

    }
    if (action === 'edit') {
      setOpen(true)
      handleMenuClose();
      setMode('edit')
      setUser(item);
      item.userAddress !== null ? setIsDisabled(true) : setIsDisabled(false);
      setUserName(item.userName);
      setFirstName(item.firstName);
      setLastName(item.lastName);
      setUserObj({ UID: item.id })
      getRoles();

    }
  };


  const openUserRoleModal = (action: string, userRole?: any) => {
    setOpenRole(true);
    handleMenuClose();
    if (action === 'add') {
      setRoleMode('add')
    }
    if (action === 'edit') {
      handleClose('user');
      setRoleMode('edit')
      setUserRoleData(userRole);
      // const array = []
      // array.push(userRole);
      setUserRoleArray(userRole);
    }
  };



  const onView = (item) => {
    setOpen(true);
    setUser(item);
    setMode('view');
  }

  const addUser = (userData) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.CREATE_USER, null, userData, null, {
      ETId: 10
    }).then(res => {
      if (hasBlockChainAddress) {
        const address = {
          ETId: 11,
          SV: 1,
          Data: [{
            UserUID: res.data.data.UID[0]
          }
          ]
        }
        assignAddress(address);
      }
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const editUser = (userData) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.UPDATE_USER, null, userData, null, {
      ETId: 10
    }).then(res => {
      Toaster.successToaster('Update Role', 'User updated successfully.Changes will reflect in sometime');
      setOpen(false);
      setTimeout(() => {
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }, 2000);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const saveUser = () => {
    if (mode === 'add') {
      const userData = {
        ETId: 10,
        SV: 1,
        Data: [{
          UserName,
          FirstName,
          LastName,
          Password
        }
        ]
      }
      addUser(userData);
    }
    else {
      const userData = {
        ETId: 10,
        SV: 1,
        Data: [userObj]
      }
      if (Object.keys(userObj).length > 1) {
        editUser(userData);
      }
      else if ((isDisabled || !hasBlockChainAddress) && (Object.keys(userObj).length === 1)) {
        Toaster.errorToaster('Please make some changes to be saved ');
      }
    }

  }

  const addUserRole = (userRole) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.CREATE_USER_ROLE, null, userRole, null, {
      ETId: 16
    }).then(res => {
      Toaster.successToaster('Add UserRole', 'User role created successfully');
      handleClose('role');
      setTimeout(() => {
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }, 2000);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }


  const editUserRole = (userRole) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.UPDATE_USER_ROLE, null, userRole, null, {
      ETId: 16
    }).then(res => {
      Toaster.successToaster('Update UserRole', 'User role updated successfully');
      handleClose('role');
      // handleClose('user');
      setTimeout(() => {
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }, 2000);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const assignRoles = () => {
    if (roleMode === 'add') {
      const Data = [];
      userRoles.map((role: any) => {
        const userData = {
          RoleID: role.id,
          UserUID: user.id
        }
        Data.push(userData);
      })
      const userRole = {
        ETId: 16,
        SV: 1,
        Data: Data
      }
      addUserRole(userRole);
    }
    else {
      const Data = [];
      userRoles.map((role: any) => {
        const userData = {
          RoleID: role.id,
          UserUID: user.id
        }
        Data.push(userData);
      })
      user.roles.map((existUsers: any) => {
        let eData = userRoles.filter(element => element.id === existUsers.id);
        if (eData.length < 1) {
          const existUser = {
            UID: existUsers.userRoleId,
            IsDeleted: true
          }
          Data.push(existUser);
        }
      })

      const userRole = {
        ETId: 16,
        //  SV: 2,
        Data: Data
      }
      editUserRole(userRole);
    }
  }


  const assignAddress = (address) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.ADD_USER_BLOCKCHAIN_ADDRESS, null, address, null, {
      ETId: 11,
      SV: 1
    }).then(res => {
      Toaster.successToaster('Create User', 'User added successfully');
      setOpen(false);
      setTimeout(() => {
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }, 2000);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });

  }


  const onPageChange = (page: any) => {
    setCurrentPageNo(page);

  }

  const goToPreviousPage = (page: any) => {
    setCurrentPageNo(page);

  }

  const goToNextPage = (page: any) => {
    if (currentPageNo < totalPage) {
      setCurrentPageNo(page);

    }
  }

  const onSearchData = (searchData: any) => {
    if (searchData !== '' && searchData !== undefined) {
      setSearchKey(searchData);
      currentPageNo === 1 ? getUsers({
        pageNo: currentPageNo, pageSize: pageSize,
        orderBy: orderBy, order: order, searchKey: searchData, totalReq: true, userStatus: userStatus === 'Enable' ? false : userStatus === 'Disabled' ? true : ''
      }) : setCurrentPageNo(1);
    }
  }

  const onCancelSearch = (value: any) => {
    if (value) {
      setSearchKey('');
      setCurrentPageNo(1);
      setPageSize(10);
      getUsers({
        pageNo: currentPageNo, pageSize: pageSize,
        orderBy: orderBy, order: order, totalReq: true, userStatus: userStatus === 'Enable' ? false : userStatus === 'Disabled' ? true : ''
      })
    }
  }

  const isLessthanTotalElement = () => {
    if ((currentPageNo * pageSize) === (totalPage - 1))
      return true;
    else
      return false;
  }

  const changePageSize = (value: any) => {
    setPageSize(value);
    //setCurrentPageNo(1);

  }
  // useEffect(() => {
  //   if (mode === 'edit' && UserName) {
  //     setUserObj({ ...userObj, UserName })
  //   }
  // }, [UserName]);

  useEffect(() => {
    if (mode === 'edit' && FirstName !== user.firstName) {
      setUserObj({ ...userObj, FirstName })
    }
    else {
      delete userObj["FirstName"]
    }
  }, [FirstName]);

  useEffect(() => {
    if (mode === 'edit' && LastName !== user.lastName) {
      setUserObj({ ...userObj, LastName })
    }
    else {
      delete userObj["LastName"]
    }
  }, [LastName]);
  useEffect(() => {
    if (mode === 'changePassword' && Password !== user.password) {
      setUserObj({ ...userObj, Password })
    }
    else {
      delete userObj["Password"]
    }
  }, [Password]);

  useEffect(() => {
    user && user.userAddress === null ? setHasBlockChainAddress(false) : setHasBlockChainAddress(true);

  }, [user]);

  useEffect(() => {
    if (pageSize && currentPageNo && orderBy && order) {
      getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true });
      // myRef.current.addEventListener("scroll", () => {
      //   if (
      //     myRef.current.scrollTop + myRef.current.clientHeight >=
      //     myRef.current.scrollHeight
      //   ) {
      //     onScroll();
      //   }

      // });
    }
  }, [pageSize, currentPageNo, order, orderBy]);
  const handleAction = (item: any, action: any) => {
    if (action == 'view') {
      setOpen(true);
      // setSelectedRule(item);
    }
  }
  const deleteUser = () => {
    const userData = {
      ETId: 10,
      Data: [{
        UID: userId,
        IsDeleted: true
      }]
    }
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.DELETE_USER, null, userData, null, {
      ETId: 10
    }).then(res => {
      Toaster.successToaster('Delete User', 'User deleted successfully');
      setOpen(false);
      getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response.data.errors);
      error.response.data.errors.forEach((response) => {
        Toaster.errorToaster(response.message);
      })
    })
  }
  const onFilterUserStatus = (status: any) => {
    setUserStatus(status);
    getUsers({
      pageNo: currentPageNo, pageSize: pageSize,
      orderBy: orderBy, order: order, totalReq: true, searchKey: searchKey, userStatus: status === 'Enable' ? false : status === 'Disable' ? true : ''
    })

  }
  const changePassword = () => {
    if (Password === retypePassword) {
      const userData = {
        ETId: 10,
        Data: [userObj]
      }
      props.toggleLoader(true);
      RestApiService.invoke(ApiEndPoints.CHANGE_PASSWORD, null, userData, null, {
        ETId: 10
      }).then(res => {
        Toaster.successToaster('Chenge Password', 'Password changed successfully');
        setOpenChangePassword(false);
        getUsers({ pageNo: currentPageNo, pageSize: pageSize, orderBy: orderBy, order: order, totalReq: true }, 'refresh');
      }).catch(error => {
        props.toggleLoader(false);
        console.log('Get error', error.response.data.errors);
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        })
      })
    }
    else {
      Toaster.errorToaster('Password does not match.');
    }
  }
  return (
    <div className="mainview">
      <div className="maincontent">
        <div className="dashboardcover">
          <div className='user-page'>
            <div className="Innerheading">
              <SubHeaderComponent
                headingText={'User Management'}
                btnText={'Add User'}
                searchLabel={'User'}
                showBtn={true}
                showSearch={true}
                showFilter={true}
                searchKey={searchKey}
                filterHeading={'Filter By Account Locked:'}
                filterArray={filterUserArray}
                filterUserStatus={(status: any) => onFilterUserStatus(status)}
                onButtonClick={(value: any) => openModal('addUser')}
                onSearchData={(searchData: any) => onSearchData(searchData)}
                onCancelSearch={(value: any) => onCancelSearch(value)} />
              <div className="Innerdetails">
                {/* <div className="user-filter-block">
                  <FormControl variant="outlined" className="formControl-select">
                    <InputLabel id="demo-simple-select-outlined-label">SortBy</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={orderBy}
                      label="SortBy"
                      onChange={handleSort}
                    >
                      {sortingList.map((item: any, index: any) => <MenuItem key={index} value={item}>{item}</MenuItem>)}

                    </Select>
                  </FormControl>
                  <FormControl component="fieldset" className="formControl-radioButton">
                    <RadioGroup row aria-label="position" name="position" defaultValue={order} onChange={handleSortOrder}>
                      <FormControlLabel
                        value="asc"
                        control={<Radio color="primary" />}
                        label="Ascending"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="desc"
                        control={<Radio color="primary" />}
                        label="Descending"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                </div> */}
                <div className="grid">
                  <DynamicList
                    dataList={users}
                    columnList={[{ name: "User Name", db: "userName", sort: true }, { name: "First Name", db: "firstName", sort: true }, { name: "Last Name", db: "lastName", sort: true }, { name: "Created At", db: "createdAt", sort: true }, { name: "Address Assigned", db: "userAddress" }, { name: "Role", db: "roles" }, { name: "Last Login", db: "lastLogin" }, { name: "Account Locked", db: "isDeleted" }]}
                    primaryColumn={"id"}
                    orderIndexedFields={orderIndexedFields}
                    onSortingData={(order: string, orderBy: any, data: any) => onSortingData(order, orderBy, data)}
                    handleAction={(item: any, action: string) => openModal(action, item)}
                    userAccount={(isDeleted: boolean, item: any,) => onUserAccount(isDeleted, item)}
                    showEdit={false}
                    showUserEdit={true}
                    showAdd={true}
                    showDelete={false}
                    showView={false}
                    showActions={true}
                    showChangePassword={true}
                    isOrderIconChange={true}
                    keysToHide={['']}
                    fromComponent={'UserComponent'}
                    goToPreviousPage={(page: any) => goToPreviousPage(page)}
                    goToNextPage={(page: any) => goToNextPage(page)}
                    changePageSize={(page: any) => changePageSize(page)}
                    pageSize={pageSize}
                    currentPageNo={currentPageNo}
                    totalPage={totalPage}
                    totalRecord={totalRecord}
                    isLessthanTotalElement={isLessthanTotalElement()}
                    SNo={false}
                  />
                </div>
                {/* <div ref={myRef} className="infinite-scroll" >
                  <Grid container spacing={3}>
                    {users && users.length > 0 ?
                      users.map((item: any, index: any) =>
                        <Grid item xs={12} md={3} key={`user_${item.id}`}>
                          <Card >
                            <CardHeader
                              avatar={item && item.userName &&
                                <Avatar aria-label="recipe">
                                  {(item.userName).charAt(0)}
                                </Avatar>
                              }
                              action={
                                <IconButton
                                  aria-label="settings"
                                  aria-controls="menu-appbar"
                                  aria-haspopup="true"
                                  color="inherit"
                                  onClick={(event) => handleMenu(event, item)}>
                                  <MoreVert />
                                </IconButton>
                              }
                              title={item.userName}
                              subheader={
                                <Fragment>
                                  <strong>
                                    Roles Assigned: {item && item.roles.length > 0 ? item.roles.map((role: any, index: any) =>
                                    <span key={`role_${role.id}`}>{role.roleName}</span>
                                  ) : <span>None</span>}
                                  </strong>
                                  <br />
                                  <strong> UserAddress Assigned:  <span>{item.userAddress === null ? 'No' : 'Yes'}
                                  </span>
                                  </strong>
                                </Fragment>
                              }
                              onClick={() => onView(item)}

                            />
                            {anchorEl && <div className="role-menu">
                              <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                className={"profile-menu"}
                              >
                                <MenuItem onClick={() => openUserRoleModal('add')}>
                                  <ListItemIcon>
                                    <VpnKey />
                                  </ListItemIcon>
                                  <ListItemText primary="Add User Roles" />
                                </MenuItem>
                                <MenuItem onClick={() => openModal('view')} >
                                  <ListItemIcon>
                                    <Visibility />
                                  </ListItemIcon>
                                  <ListItemText primary="View" />
                                </MenuItem>
                                <MenuItem onClick={() => openModal('edit')}>
                                  <ListItemIcon>
                                    <Create />
                                  </ListItemIcon>
                                  <ListItemText primary="Edit" />
                                </MenuItem>

                              </Menu>
                            </div>}
                          </Card>
                        </Grid>) : <Grid item xs={12} md={3}>
                        <Card>
                          <CardContent>
                            <Typography component={'span'} >No data found</Typography>
                          </CardContent>
                        </Card>
                      </Grid>}
                  </Grid>
                </div> */}
                <Dialog open={open} className={`${(mode === 'delete') ? 'userdeletepopup' : 'useraddpopup'} `}>
                  <DialogTitle disableTypography>
                    <div className="usertile">
                      <People />
                      {mode === 'add' && <Typography component={'span'} variant="h6">Add User Details</Typography>}
                      {mode == 'view' && <Typography component={'span'} variant="h6">View User Details</Typography>}
                      {mode == 'edit' && <Typography component={'span'} variant="h6">Edit User Details</Typography>}
                      {mode === 'delete' && <Typography component={'span'} variant="h6">Delete User Name {UserName}</Typography>}
                    </div>
                    <IconButton aria-label="close" onClick={() => handleClose('user')}>
                      <Close />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography component={'span'} gutterBottom>
                      {mode === 'delete' && <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12}>
                          <span>Are you sure that you want to delete this user? </span>
                        </Grid>
                      </Grid>}
                      {mode !== 'delete' && <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12} md={3}>
                          <span>User Name</span>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <div className="form-group">
                            <TextField
                              id="standard-uncontrolled"
                              defaultValue={mode === 'add' ? '' : user.userName}
                              margin="normal"
                              disabled={mode === 'view' || mode === 'edit'}
                              onChange={(text) => {
                                text.target.value = text.target.value.replace(' ', '_')
                                if ((/^[A-Za-z]\w*$/).test(text.target.value)) {
                                  setUserName(text.target.value);
                                } else {
                                  text.target.value = '';
                                  setUserName('');
                                }
                              }}
                            />
                            {(mode !== 'view' && mode !== 'edit') && <Tooltip title="Username should begin with an alphabet and spaces are not allowed">
                              <IconButton>
                                <Info />
                              </IconButton>
                            </Tooltip>}
                          </div>

                        </Grid>
                      </Grid>}

                      {mode !== 'delete' && <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12} md={3}>
                          <span>First Name</span>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <div className="form-group">
                            <TextField
                              id="standard-uncontrolled"
                              defaultValue={mode === 'add' ? '' : user.firstName}
                              margin="normal"
                              disabled={mode === 'view'}
                              onChange={(text) => {
                                text.target.value = text.target.value.replace(' ', '_')
                                if ((/^[A-Za-z]\w*$/).test(text.target.value)) {
                                  setFirstName(text.target.value);
                                } else {
                                  text.target.value = '';
                                  setFirstName('');
                                }
                              }}
                            />
                            {mode !== 'view' && <Tooltip title="FirstName should begin with an alphabet and spaces are not allowed">
                              <IconButton>
                                <Info />
                              </IconButton>
                            </Tooltip>}
                          </div>

                        </Grid>
                      </Grid>}

                      {mode !== 'delete' && <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12} md={3}>
                          <span>Last Name</span>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <div className="form-group">
                            <TextField
                              id="standard-uncontrolled"
                              defaultValue={mode === 'add' ? '' : user.lastName}
                              margin="normal"
                              disabled={mode === 'view'}
                              onChange={(text) =>
                                setLastName(text.target.value)
                              }
                            />
                          </div>

                        </Grid>
                      </Grid>}

                      {mode === 'add' && <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12} md={3}>
                          <span>Password</span>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <div className="form-group">
                            <TextField
                              id="standard-uncontrolled"
                              type={hiddenNewPassword ? "password" : "text"}
                              margin="normal"
                              onChange={(text) => setPassword(text.target.value)}
                            />
                            <IconButton onClick={() => setHiddenNewPassword(!hiddenNewPassword)}>
                              {!hiddenNewPassword && <Visibility />}
                              {hiddenNewPassword && <VisibilityOff />}
                            </IconButton>
                          </div>
                        </Grid>
                      </Grid>}

                      {mode !== 'add' && mode !== 'delete' && <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12} md={3}>
                          <span>Roles Assigned</span>
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <div className="userroles">
                            {user && user.roles.length > 0 ? user.roles.map((userrole: any, index: any) =>
                              <div key={`userrole_${userrole.id}`} className="form-group">
                                <Tooltip title={userrole.roleName}>
                                  <Chip label={userrole.roleName}
                                    icon={mode === 'edit' && <Create />}
                                    disabled={mode === 'view'}
                                    color="primary"
                                    onClick={() => openUserRoleModal('edit', [userrole])} />
                                </Tooltip>
                              </div>) :
                              <div className="form-group">
                                <Tooltip title="None">
                                  <Chip label="None" disabled={mode === 'view'} color="primary" />
                                </Tooltip>
                              </div>
                            }
                          </div>
                        </Grid>
                      </Grid>}

                      {mode === 'edit' && <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12} md={4}>
                          <span>Assign Blockchain Address</span>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <div className="form-group">
                            <FormControlLabel className="checkbox-control"
                              control={
                                <Checkbox
                                  disabled={hasBlockChainAddress}
                                  checked={hasBlockChainAddress}
                                  value={hasBlockChainAddress}
                                  color="primary"
                                  onChange={(event) => { event.target.checked ? setHasBlockChainAddress(true) : setHasBlockChainAddress(false) }}
                                />
                              }
                              label=""
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <span>Blockchain Address</span>
                        </Grid>

                        <Grid item xs={12} md={9}>
                          <div className="form-group">
                            <span>{user.userAddress === null ? "Address not assigned" : user.userAddress?.BlockChainAddress ? user.userAddress.BlockChainAddress : "Address not assigned"}</span>
                          </div>
                        </Grid>

                      </Grid>}
                      {mode === 'view' && <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12} md={4}>
                          <span>Address Assigned</span>
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <div className="form-group">

                            <TextField
                              id="standard-uncontrolled"
                              defaultValue={user.userAddress === null ? 'No' : 'Yes'}
                              margin="normal"
                              disabled={mode === 'view'}
                              className="addresses"
                            />


                          </div>

                        </Grid>
                      </Grid>}
                    </Typography>
                  </DialogContent>
                  {mode === 'add' && <DialogActions>
                    <Button className={"btn-primary"} variant="contained" color="primary" disabled={UserName === '' || FirstName === '' || Password == ''} onClick={saveUser}>
                      Save
                    </Button>
                  </DialogActions>}
                  {mode === 'edit' && <DialogActions>
                    <Button className={"btn-primary"} variant="contained" color="primary" disabled={FirstName === ''} onClick={saveUser}>
                      Save
                    </Button>
                  </DialogActions>}
                  {mode === 'delete' && <DialogActions>
                    <Button className={"btn-primary"} variant="contained" onClick={deleteUser} color="primary"> OK</Button>
                    <Button className={"btn-primary"} variant="contained" onClick={() => handleClose('delete')} color="primary"> Cancel</Button>
                  </DialogActions>}
                </Dialog>
                <Dialog open={openRole} className="useraddpopup" >
                  <DialogTitle disableTypography>
                    <div className="usertile">
                      < People />
                      {roleMode === 'add' && <Typography component={'span'} variant="h6">User Role</Typography>}
                      {roleMode === 'edit' && <Typography component={'span'} variant="h6">Edit User Role</Typography>}
                    </div>
                    <IconButton aria-label="close" onClick={() => handleClose('role')}>
                      <Close />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography component={'span'} gutterBottom>

                      <Grid container spacing={6} className="add-lable">
                        <Grid item xs={12} md={2}>
                          <span>Roles</span>
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <div className="multi">
                            <MultipleSelectComponent
                              labelname={"Roles"}
                              data={roleList}
                              mode={roleMode}
                              from={"USER"}
                              selectedValues={roleMode === 'edit' ? userRoleArray : []}
                              handleRoles={(data) => setUserRoles(data)}
                            />

                          </div>

                        </Grid>
                      </Grid>

                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button className={"btn-primary"} variant="contained" disabled={userRoles.length === 0} color="primary" onClick={assignRoles}>
                      Save
          </Button>
                  </DialogActions>
                </Dialog>
                <div className="change-password-modal">
                  <Dialog aria-labelledby="customized-dialog-title" open={openChangePassword} >
                    <DialogTitle disableTypography>
                      <div className="usertile">
                        <Typography variant="h6">< People className="userIcon" />  Change Password for {UserName}</Typography>
                      </div>
                      <IconButton aria-label="close" onClick={() => handleClose('user')}>
                        <Close />
                      </IconButton>
                    </DialogTitle>
                    <div className="content-boxs">
                      <div className="change-password">
                        <form action="post" className="form">

                          {/* <div className="form-group">
                            <TextField
                              id="standard-uncontrolled1"
                              label="User Name"
                              margin="normal"
                              type="text"
                              defaultValue={mode === 'add' ? '' : user.userName}
                              disabled={true}
                            />
                          </div> */}
                          <div className="form-group">
                            <TextField
                              id="standard-uncontrolled"
                              label="New Password"
                              margin="normal"
                              type={hiddenNewPassword ? "password" : "text"}
                              onChange={(text) => setPassword(text.target.value)}
                              required

                            />
                          </div>
                          <div className="form-group">
                            <TextField
                              id="standard-uncontrolled"
                              label="Confirm New Password"
                              type={hiddenRetypePassword ? "password" : "text"}
                              margin="normal"
                              onChange={(text) => setRetypePassword(text.target.value)}
                              required

                            />
                          </div>
                          <div className="login-button">
                            <Button color="primary" variant="contained" disabled={Password == '' || retypePassword === ''} onClick={changePassword}>Save
                              </Button>

                          </div>

                        </form>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);


interface UserProps {
  toggleLoader: any;
}