import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SidebarMenu from '../../../models/SidebarMenu.list';
import { Drawer, List, ListItemIcon, ListItemText, Tooltip, ListItem, Collapse, AppBar, Toolbar, IconButton, Typography, Divider, Theme, createStyles, makeStyles, useTheme, ExpansionPanelDetails, ExpansionPanelActions, ExpansionPanel, ExpansionPanelSummary, Chip, Button, InputBase, Badge } from '@material-ui/core';
import './adminSideBar.scss';
import { ExpandMore, ExpandLess, AccountCircle, ArrowRight, ChevronLeft, ChevronRight, Mail, Inbox, More, Notifications } from '@material-ui/icons';
import { SupervisedUserCircle } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import SidebarAdminMenu from '../../../models/SidebarAdminMenu';
import { Menu, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { AuthService } from '../../../core/interceptors/auth.interceptor';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { logoutUser, changePassword } from '../../../redux/actions/authActions';
import ChangePassword from '../../../pages/security/ChangePassword';
import { ApiEndPoints } from '../../../models/api-endpoint';
import RestApiService from "../../../services/http-services";
import { toggleLoader } from '../../../redux/actions/loader.action';
import Toaster from '../../../services/toaster.services';
import { LockOpen, VpnKey } from '@material-ui/icons';
import { CookieStorage } from 'cookie-storage';
import { toggleSideBar } from '../../../redux/actions/sidebarAction';
import { bindActionCreators } from 'redux';
import { useCookies } from "react-cookie";
import appConstants from "../../../core/constants/appConstants";

const cookies = new CookieStorage();
const AdminSideBarComponent = (props: any) => {
    const [cookie, setCookie, removeCookie] = useCookies(['theme']);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [isThemeSet, setIsThemeSet] = React.useState(true);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const authService = new AuthService();
    const [errors, setErrors] = React.useState({});
    const [showResetPassword, setShowResetPassword] = React.useState<any>(false);
    const [user, setUser] = React.useState<any>();
    const [openMenu, setOpenMenu] = React.useState(true);
    useEffect(() => {
        setUser(props.auth.user)
    }, [props.auth]);
    const handleMenu2 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const MenuButton = () => {
        return (
            <IconButton onClick={isOpenMenu}>
                <MenuIcon />
            </IconButton>
        );
    };

    const isOpenMenu = () => {
        if (openMenu) {
            setOpenMenu(false);
        }
        else {
            setOpenMenu(true);
        }

    }
    useEffect(() => {
        if (isThemeSet) {
            dynamicThemeApply();
            setIsThemeSet(false);
        }
    }, [isThemeSet]);
    const dynamicThemeApply = () => {
        var theme = cookie.theme;
        if (theme === appConstants.THEME.DARK_BLUE) {
            document.documentElement.style.setProperty('--color', '#fff');
            document.documentElement.style.setProperty('--font-color', '#fff');
            document.documentElement.style.setProperty('--tab_background_color', '#23395d');
            document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
            document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
            document.documentElement.style.setProperty('--border_color', '#006eaf');
            document.documentElement.style.setProperty('--border_color1', 'transparent');
            document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
            document.documentElement.style.setProperty('--selected_background_color', '#7488aa');
            document.documentElement.style.setProperty('--background-color', '#23395d');
            document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
            document.documentElement.style.setProperty('--Bad_color', '#c9817d');
            document.documentElement.style.setProperty('--Good_color', '#9bc798');
            document.documentElement.style.setProperty('--hover_background_color', '#305695');
        }
        else if (theme === appConstants.THEME.ALICE_BLUE) {
            document.documentElement.style.setProperty('--color', '#fff');
            document.documentElement.style.setProperty('--font-color', '#fff');
            document.documentElement.style.setProperty('--tab_background_color', '#3E849E');
            document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
            document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
            document.documentElement.style.setProperty('--border_color', '#06b5f4');
            document.documentElement.style.setProperty('--border_color1', 'transparent');
            document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
            document.documentElement.style.setProperty('--selected_background_color', '#2e7691');
            document.documentElement.style.setProperty('--background-color', '#3E849E');
            document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
            document.documentElement.style.setProperty('--Bad_color', '#c9817d');
            document.documentElement.style.setProperty('--Good_color', '#9bc798');
            document.documentElement.style.setProperty('--hover_background_color', '#2e7691');
        }

        else {
            document.documentElement.style.setProperty('--color', '#0078d4');
            document.documentElement.style.setProperty('--font-color', '#000000');
            document.documentElement.style.setProperty('--tab_background_color', '#ffffff');
            document.documentElement.style.setProperty('--table_header_color', '#c2c0bd');
            document.documentElement.style.setProperty('--table_header_background', '#c2c0bd');
            document.documentElement.style.setProperty('--border_color', '#ccc');
            document.documentElement.style.setProperty('--border_color1', '#c2c0bd');
            document.documentElement.style.setProperty('--btn_background_color', '#0078d4');
            document.documentElement.style.setProperty('--selected_background_color', '#f1f6ff');
            document.documentElement.style.setProperty('--Pending_color', '#fdf0c8');
            document.documentElement.style.setProperty('--Bad_color', '#f8cecc');
            document.documentElement.style.setProperty('--Good_color', '#d5e8d4');
            document.documentElement.style.setProperty('--background-color', '#fff');
        }
    }
    return (
        <div className="mainside">
            <Drawer
                variant="permanent"
                className={`${openMenu ? "closestrip" : ""}`}
            >
                <MenuButton />
                <List>
                    <li>
                        <div className="rightmenu">
                            {SidebarAdminMenu.map((item: any, index: any) => (item.type && item.type === 'DROPDOWN') ? item.menu(props) :
                                <div className="right" key={"s_r_nl" + index}>
                                    <li className={`menu-link `} key={"s_li_nl" + index}>
                                        <NavLink activeClassName="active" className="link" to={item.url} key={"s_nl" + index}>
                                            <Tooltip title={item.name} placement={item.placement}>
                                                <ListItemIcon key={"s_nli" + index}>{item.icon}</ListItemIcon></Tooltip>
                                            <Tooltip title={item.name} placement={item.placement}>
                                                <ListItemText primary={item.name} key={"s_nllt" + index}>{item.name}</ListItemText>
                                            </Tooltip>
                                        </NavLink>
                                    </li>
                                </div>
                            )}
                        </div>
                    </li>
                </List>
            </Drawer>
        </div >
    );
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    isShowSideBar: state.appSidebarReducer
});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleSideBar: toggleSideBar, logoutUser, changePassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminSideBarComponent));