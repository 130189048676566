
export class ApiEndPoints {
    public static SIGN_IN: APIDef = { 'method': 'POST', api: () => `/ui/api/auth/login` };
    public static SIGN_UP: APIDef = { 'method': 'POST', api: () => `/ui/api/auth/register` };
    public static RESET_PASSWORD: APIDef = { 'method': 'POST', api: () => `/ui/api/users/resetpassword` };
    public static GET_ENVELOPES: APIDef = { 'method': 'GET', api: () => `/ui/api/envelopes` };
    public static GET_ETIDs: APIDef = { 'method': 'POST', api: () => `/ui/api/getETIds` };
    public static GET_ENVELOPE_TYPE_VERSIONS: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/envelopeTypes/${apiInput.ETId}/versions` };
    public static GET_VERSIONS: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/versions${apiInput.queryString}` };

    public static GET_HASHES: APIDef = { 'method': 'GET', api: () => `/ui/api/envelopes/hashes` };
    public static VIEW_DATAHASH: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelopes/hashes/Model?DH=${apiInput.DH}&EId=${apiInput.EId}` };
    public static DECRYPT_DATAHASH: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelopes/hashes/decrypt?DH=${apiInput.DH}&EId=${apiInput.EId}` };
    public static VIEW_FILEHASH: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelopes/filehashes/Model?FH=${apiInput.FH}` };
    public static DECRYPT_FILEHASH: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelopes/filehashes/decrypt?FH=${apiInput.FH}` };
    public static GET_FILES: APIDef = { 'method': 'GET', api: () => `/ui/api/uploads` };
    public static POST_FILES: APIDef = { 'method': 'POST', api: () => `/ui/api/uploads` };
    public static GET_FILE_BY_NAME: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/uploads/${apiInput.type}/${apiInput.fileName}` };
    public static DELETE_FILE_BY_NAME: APIDef = { 'method': 'DELETE', api: (apiInput: APIInput) => `/ui/api/uploads/${apiInput.fileName}` };

    public static GET_PROFILE: APIDef = { 'method': 'GET', api: () => '/ui/api/profile' };
    public static GET_PROFILES: APIDef = { 'method': 'GET', api: () => '/ui/api/profile/all' };
    public static POST_PROFILE: APIDef = { 'method': 'POST', api: () => '/ui/api/profile' };
    public static DELETE_PROFILE: APIDef = { 'method': 'DELETE', api: () => '/ui/api/profile' };
    public static GET_PROFILE_BY_HANDLE: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/profile/handle/${apiInput.handle}` };

    public static GET_SCHEMAS_TREE: APIDef = { 'method': 'GET', api: () => '/ui/api/schema/treeview' }; //'/ui/api/schemas' };
    public static GET_SCHEMAS: APIDef = { 'method': 'GET', api: () => '/ui/api/schemas' };
    public static GET_SYSTEM_FIELDS: APIDef = { 'method': 'GET', api: () => '/ui/api/systemFields' };
    public static POST_SCHEMAS: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas' };
    public static GET_SCHEMAS_ID: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/${apiInput.id}` }; //
    public static GET_SCHEMA_DATATYPE: APIDef = { 'method': 'GET', api: () => '/ui/api/schemas/dataTypes' };
    public static GET_SCHEMA_WITH_VERSION: APIDef = { 'method': 'GET', api: () => '/ui/api/schemas/withVersion' };
    public static GET_ETID_DATA: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/getETIdData' };
    public static GET_RECORD_TIMELINE: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/recordTimeline' };
    public static GET_RECORD_COMPARISON: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/recordComparison' };
    public static VALIDATE_RECORDS: APIDef = { 'method': 'POST', api: () => '/ui/api/schemas/validateRecords' };
    public static GET_SCHEMAS_LIST_FOR_CHART: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/schemaList?page=1&pageSize=25&startDate=${apiInput.startDate}&endDate=${apiInput.endDate}` };
    public static GET_SCHEMAS_LIST_FOR_PIE_CHART: APIDef = { 'method': 'POST', api: (apiInput: APIInput) => `/ui/api/schemas/getSchemaPieData` };
    public static GET_SCHEMAS_LIST_FOR_ENVELOPE_CHART: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/envelope/graphData?startDate=${apiInput.startDate}&endDate=${apiInput.endDate}` };
    public static GET_SCHEMAS_DETAILS_FOR_TABLE: APIDef = { 'method': 'GET', api: (apiInput: APIInput) => `/ui/api/schemas/getSchemaDetails` };
    public static GET_ORGANIZATIONS: APIDef = { 'method': 'GET', api: () => '/ui/api/organizations' };
    public static GET_ENVELOPE_HEALTH_DETAILS: APIDef = { 'method': 'GET', api: () => `/ui/api/envelope/healthDetails` };
    public static GET_DATA_FOR_ENVELOPE_CHART: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/graphData` };


    public static GET_BPM_RULES: APIDef = { 'method': 'GET', api: () => `/ui/api/bpm/bpmRules` };
    public static SAVE_BPM_RULE: APIDef = { 'method': 'POST', api: () => `/ui/api/bpm/create` };
    public static GET_FIELDS: APIDef = { 'method': 'GET', api: () => `/ui/api/bpm/fields` };
    public static STORE_FILE: APIDef = { 'method': 'POST', api: () => `/ui/api/bpm/uploadFile` };
    public static GET_FILE_HASH: APIDef = { 'method': 'POST', api: () => `/ui/api/bpm/getFileHash` };


    public static GET_ROLES: APIDef = { 'method': 'GET', api: () => `/ui/api/roles` };
    public static CREATE_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static UPDATE_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };

    public static GET_USERS: APIDef = { 'method': 'POST', api: () => `/ui/api/users` };
    public static CREATE_USER: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static UPDATE_USER: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static CREATE_USER_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static UPDATE_USER_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static ADD_USER_BLOCKCHAIN_ADDRESS: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static GET_USER_WITHOUT_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/role/getUserWithoutRole` };
    public static DELETE_ROLE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static DELETE_USER: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static CHANGE_PASSWORD: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` };
    public static GET_USER_ROLES: APIDef = { 'method': 'POST', api: () => `/ui/api/user/getUserAssignRoles` };
    public static SEARCH_USERS: APIDef = { 'method': 'GET', api: () => `/ui/api/users/searchUsers` };

    public static SETTINGS_USERS: APIDef = { 'method': 'POST', api: () => `/ui/api/setting/settingDetails` };
    public static GET_ASSISTED_AUDIT_PIE_CHART: APIDef = { 'method': 'GET', api: () => `/ui/api/schemas/getAssistedAuditGraph` };
    public static GET_FILES_LIST: APIDef = { 'method': 'GET', api: () => `/ui/api/getFilesList` };
    public static UPLOAD_FILES: APIDef = { 'method': 'POST', api: () => `/ui/api/uploadFile` };
    public static BLOCKCHAIN_INFO: APIDef = { 'method': 'POST', api: () => `/ui/api/cluster/blockChainInfo` };
    public static CREATE_CLUSTER: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` }
    public static CREATE_NODE: APIDef = { 'method': 'POST', api: () => `/ui/api/envelopes/submit` }
    public static GET_CLUSTER_LIST: APIDef = { 'method': 'POST', api: () => `/ui/api/cluster/getClusterList` };
    public static GET_NODE_LIST: APIDef = { 'method': 'POST', api: () => `/ui/api/cluster/getNodeList` };
    public static GET_ENVELOPE_COUNT: APIDef = { 'method': 'POST', api: () => `/ui/api/totalenvelopes` };


}

export interface APIDef {
    method: string;

    api: any;
}

export interface APIInput {
    id?: string;
    ETId?: any;
    pageSize?: number;
    pageNo?: number;
    type?: string;
    fileName?: any;
    handle?: any;
    startDate?: any;
    endDate?: any;
    DH?: any;
    FH?: any;
    EId?: any;
    queryString?: any;
}




