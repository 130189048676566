/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppConstants from '../../core/constants/appConstants';
import "./newSchemaList.scss";
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { toggleLoader } from '../../redux/actions/loader.action';
import { Grid, IconButton, Table, TableHead, TableRow, TableCell, TableBody, AppBar, Typography, Tabs, Tab, Box, TableContainer, Menu, MenuItem, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import RestApiService from '../../services/http-services';
import { ApiEndPoints } from '../../models/api-endpoint';
import Toaster from '../../services/toaster.services';
import { changeEpochToDate } from '../../utils/utilityFunctions';
import { MoreVert, Create, Close, VerifiedUser, Delete, Visibility, Check, Clear, Refresh, AddCircle } from '@material-ui/icons';
import SearchBar from "material-ui-search-bar";
import { Formik, FieldArray } from 'formik';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'underscore';

import PagingComponent from '../../shared/components/paging/paging.component';
import moment from 'moment';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SubHeaderComponent from '../../shared/components/sub-header/sub-header';
import { JwtServices } from '../../services/jwt.services';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function tabIndexProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


class NewSchemaListComponent extends Component<any, any> {

    public state = {
        dataTypeList: [],
        listData: [],
        data: [],
        showETIdError: false,
        isSchemaDetail: false,
        schemaDataAvailable: true,
        systemFields: [],
        systemFieldKeys: [],
        fieldKeys: [],
        schemaData: [],
        fieldData: [],
        indexData: [],
        etidDataList: [],
        etidColumnList: [],
        etidColumnListTest: [],
        initialState: {
            TableName: '',
            ETId: '',
            DoSummary: 'true',
            ORGId: "",
            Description: '',
            Family: '',
            Fields: [],
            Indexes: []
        },
        filter: {
            page: 1,
            pageSize: 100000,
            detail: true
        },
        isEditMenu: false,
        isEditOpenPopup: false,
        isAddOpenPopup: false,
        value: 0,
        versionData: [],
        mode: 'ADD',
        isFamilyMode: '',
        fieldMode: 'ADD',
        indexMode: 'ADD',
        currentFieldIndex: null,
        currentIndex: null,
        anchorEl: null,
        isDefaultRow: true,
        versionList: [],
        organizationList: [],
        pageSize: 10,
        currentPageNo: 1,
        orderBy: 'CreatedAt',
        order: 'desc',
        skipPage: 0,
        totalPage: 1,
        totalRecord: 0,
        selectedNode: '',
        selectedNodeChild: '',
        keysToHide: ['_id'],
        openAuditModal: false,
        timelinePageSize: 10,
        timelineCurrentPageNo: 1,
        timelineTotalPage: 1,
        timelineTotalRecord: 0,
        recordTimelineData: [],
        recordTimelineColumnList: [],
        recordComparisonData: [],
        recordComparisonColumnList: [],
        selectedAuditUID: '',
        chainHistoryStatus: '',
        isAuditBad: true,
        indexedFields: [],
        indexFieldValue: '',
        schemaIndexData: [],
        orderIndexedFields: [],
        isSystemField: false,
        isSystemFieldData: false,
        allAvailableFields: [],
        etidlist: [],
        isNodeSelected: "",

    }

    constructor(props: any) {
        super(props);
        this._renderForm = this._renderForm.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);
        this.goToPreviousPage = this.goToPreviousPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.onSortingETIdData = this.onSortingETIdData.bind(this);
        this.convertValue = this.convertValue.bind(this);
        this.handleAuditAction = this.handleAuditAction.bind(this);
        this.onFilterSystemFields = this.onFilterSystemFields.bind(this);
        this.onChangeSvSystemFields = this.onChangeSvSystemFields.bind(this)
        this.handleModalClose = this.handleModalClose.bind(this);
        this.validateRecord = this.validateRecord.bind(this);
        this.refreshRecordComparisonData = this.refreshRecordComparisonData.bind(this);
        this.search = this.search.bind(this);
        this.CancelSearch = this.CancelSearch.bind(this);
        this.setFileteval = this.setFileteval.bind(this)
    }

    getDataTypeList() {
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_SCHEMA_DATATYPE, null, null, null, null).then(res => {
            this.props.toggleLoader(false);
            this.setState({ dataTypeList: res.data.data });
        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error in schema component', error);
            Toaster.errorToaster(error);
        })
    }
    getOrganizationList() {
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_ORGANIZATIONS, null, null, null, null).then(res => {
            this.props.toggleLoader(false);
            this.setState({ organizationList: res.data.data });
        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error in schema component', error);
            Toaster.errorToaster(error);
        })
    }

    scrollBottom(id: any) {
        setTimeout(() => {
            const elmnt = document.getElementById(id);
            elmnt.scrollIntoView();
            window.scroll(0, 0);
        }, 700);

    }

    getEtidsList() {
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_ETIDs, null, null, null, {
            ETId: 50,
            SV: 1
        }).then((res: any) => {
            this.props.toggleLoader(false);
            res.data.data.map((item: any, index: any) => {
                this.state.etidlist.push((item.ETId).toString())
            });

        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
        });
    }

    getSchemaList(query: object = {}) {
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_SCHEMAS_TREE, null, null, query, null).then(res => {
            this.props.toggleLoader(false);
            this.setState({ listData: res.data.data });
            if (res.data.data && res.data.data[0] && res.data.data[0].ETId)
                this.openSchemaView(res.data.data[0].ETId, res.data.data[0].nodeName, true);
        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error in schema component', error);
            // Toaster.errorToaster(error);
        })
    }

    componentDidMount() {
        this.getDataTypeList();
        this.getSchemaList(this.state.filter);
        this.setState({ isDefaultRow: true });
        this.getOrganizationList();
        this.getSystemFields();
        this.getEtidsList();
    }

    closeSchemaView() {
        this.setState({ isSchemaDetail: false, schemaData: [], indexData: [], fieldData: [] });
    }

    onFilterSystemFields(event) {
        if (event.target.checked) {
            this.setState({ isSystemField: true, schemaData: { ...this.state.schemaData, Fields: this.state.allAvailableFields } });
        }
        else {
            const fieldArray = this.state.schemaData["Fields"].filter((field: any, index: number) => field.SystemGenerated !== true)
            this.setState({ isSystemField: false, schemaData: { ...this.state.schemaData, Fields: fieldArray } });
        }
    }
    onChangeSvSystemFields() {
        if (this.state.isSystemField === true) {
            this.setState({ schemaData: { ...this.state.schemaData, Fields: this.state.allAvailableFields } });
        }
        else {
            const fieldArray = this.state.schemaData["Fields"].filter((field: any, index: number) => field.SystemGenerated !== true)
            this.setState({ schemaData: { ...this.state.schemaData, Fields: fieldArray } });
        }
    }

    openSchemaView(ETID: any, NodeId?: any, isTree?: boolean) {
        if (isTree === true) {

            this.setState({ value: 0, currentPageNo: 1, pageSize: 10, selectedNode: NodeId, selectedNodeChild: null, isNodeSelected: ETID });
        }
        else {
            this.setState({ value: 0, currentPageNo: 1, pageSize: 10, selectedNode: null, selectedNodeChild: NodeId, isNodeSelected: ETID });
        }
        this.props.toggleLoader(true);
        RestApiService.invoke<any>(ApiEndPoints.GET_ENVELOPE_TYPE_VERSIONS, { ETId: ETID }, null, null, null).then(res => {
            let reverseData = _.sortBy(res.data.data).reverse();
            this.setState({ versionList: reverseData });
            RestApiService.invoke(ApiEndPoints.GET_SCHEMAS, null, null, { ETId: ETID, SV: reverseData[0], detail: true }, null).then(res => {
                this.props.toggleLoader(false);
                if (res.data.data.length > 0) {
                    this.setState({ isSystemField: false, schemaData: res.data.data[0], isSchemaDetail: true, schemaDataAvailable: true, isAddOpenPopup: false, isEditOpenPopup: false }, () => {
                        this.setState({ allAvailableFields: this.state.schemaData['Fields'] })
                        const fieldArray = this.state.schemaData["Fields"].filter((field: any, index: number) => field.SystemGenerated !== true)
                        this.setState({ schemaData: { ...this.state.schemaData, Fields: fieldArray } });
                        let array2 = [];
                        let orderArray = [];
                        this.state.schemaData['Indexes'].forEach((element) => {
                            // element.Fields.forEach((item: any) => {
                            //     if (!array2.includes(item)) {
                            //         array2.push(item);
                            //         orderArray.push({ 'column': item, 'order': 'desc' })
                            //     }
                            // });
                            array2.push('UID', 'CreatedAt', 'UpdatedAt', 'size', 'name');
                            orderArray.push({ 'column': 'UID', 'order': 'desc' }, { 'column': 'CreatedAt', 'order': 'desc' }, { 'column': 'UpdatedAt', 'order': 'desc' },
                                { 'column': 'size', 'order': 'desc' }, { 'column': 'name', 'order': 'desc' })
                        });
                        this.setState({ schemaIndexData: this.state.schemaData["Indexes"] });
                        this.setState({ indexedFields: array2 })
                        this.setState({ orderIndexedFields: orderArray });
                        const arrayKey = [];
                        this.state.schemaData["Fields"].forEach((item: any) => {
                            arrayKey.push(item.FieldName);
                        });
                        arrayKey.sort(function (a: any, b: any) {
                            return a.localeCompare(b);
                        });
                        this.setState({ fieldKeys: arrayKey });
                    });
                }
                else {
                    this.setState({ schemaData: [], isSchemaDetail: false, schemaDataAvailable: false, isAddOpenPopup: false, isEditOpenPopup: false });
                }
            }).catch(error => {
                this.props.toggleLoader(false);
                console.log('Get error in schema component', error);
                // Toaster.errorToaster(error);
            })
        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error in schema component', error);
        })

    }

    getSystemFields() {
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_SYSTEM_FIELDS, null, null, null, null).then(res => {
            this.setState({ systemFields: res.data.data });
            const array = [];
            res.data.data.forEach((item: any) => {
                array.push(item.FieldName);
            });
            array.sort(function (a: any, b: any) {
                return a.localeCompare(b);
            });
            this.setState({ systemFieldKeys: array });
        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error', error.response);
            error.response?.data?.errors?.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }
    changeVersionData(etid: any, versionId: any) {
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_SCHEMAS, null, null, { ETId: etid, SV: versionId, detail: true }, null).then(res => {
            this.props.toggleLoader(false);
            this.setState({ schemaData: res.data.data[0], isSchemaDetail: true });


            this.onChangeSvSystemFields()
        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error in schema component', error);
        })
    }

    openEditView() {
        this.setState({ isAddOpenPopup: false, isEditOpenPopup: true, isEditMenu: false, isSchemaDetail: false, isFamilyMode: '', mode: 'EDIT', anchorEl: null });
    }

    getColumnNames(data) {
        this.setState({
            etidColumnList: [],
            etidColumnListTest: []
        })
        data.forEach((item: any, index1: any) => {
            let newObj;
            Object.keys(item).forEach((key: any, index2: any) => {
                if (item[key] && typeof (item[key]) === 'object') {
                    let item2 = item[key];
                    newObj = Object.assign(item, item2);
                    Object.keys(item[key]).forEach((key3) => {
                        if (Object.keys(newObj).includes(key3)) {
                            delete newObj.Config;
                        }
                    })

                }
                else {
                    newObj = item;
                }
            })
            this.setState({
                etidColumnList: Object.entries(newObj).map(obj => obj[0]),
                etidColumnListTest: Object.entries(newObj).map(obj => obj[0])
            });
        });
    }
    getRecordTimelineColumnNames(data) {
        this.setState({ recordTimelineColumnList: [] })
        let array1 = [];
        data.forEach((item: any) => {
            Object.keys(item).forEach((key: any) => {
                if (!array1.includes(key)) {
                    array1.push(key)
                }
            });
        });
        this.setState({ recordTimelineColumnList: array1 })
    }

    getRecordComparisonColumnNames(data) {
        this.setState({ recordComparisonColumnList: [] })
        let array1 = [];
        data.forEach((item: any) => {
            Object.keys(item).forEach((key: any) => {
                if (!array1.includes(key)) {
                    array1.push(key)
                }
            });
        });
        this.setState({ recordComparisonColumnList: array1 })
    }

    handleChangeValue(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ value: newValue, currentFieldIndex: null, currentIndex: null });
        if (newValue === 2) {
            this.getETIdDetails();
        }
    }

    getETIdDetails() {
        this.props.toggleLoader(true);
        let query;
        if (this.state.schemaData["DoSummary"] === false) {
            query = { fromSummary: false }
        }
        else {
            query = null;
        }
        const bodyData = {};
        this.state.fieldKeys.forEach((item: any) => {
            if (!this.state.systemFieldKeys.find((field) => field.includes(item))) {
                if (item !== 'SV') {
                    bodyData[item] = "$" + item;
                }
                else {
                    bodyData[item] = "$DV";
                }
            }
        });
        this.state.systemFieldKeys.forEach((item: any) => {
            if (item !== 'SV') {
                bodyData[item] = "$" + item;
            }
            else {
                bodyData[item] = "$DV";
            }

        })
        const body = { sort: { [this.state.orderBy]: this.state.order === 'asc' ? 1 : -1 }, skip: this.state.skipPage, limit: this.state.pageSize, projectData: bodyData };
        RestApiService.invoke(ApiEndPoints.GET_ETID_DATA, null, body, query, { ETId: this.state.schemaData['ETId'], SV: 1 }).then(res => {
            this.props.toggleLoader(false);
            res.data.data.map((item: any, index: any) => {
                if (item.hasOwnProperty('HashSign')) {
                    delete item.HashSign
                }
            })
            this.setState({ etidDataList: res.data.data })
            this.getColumnNames(res.data.data);
            this.setState({ totalRecord: res.data.total })
            if (parseInt(res.data.total) % this.state.pageSize === 0) {
                this.setState({ totalPage: (parseInt(res.data.total) / this.state.pageSize) });
            }
            else {
                this.setState({ totalPage: ((Math.floor(parseInt(res.data.total) / this.state.pageSize)) + 1) });
            }
            this.changeEtidRow()
        }).catch(error => {
            this.props.toggleLoader(false);
            this.setState({ etidDataList: [] })
            console.log('Get error', error);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    getRecordTimeLine() {
        this.props.toggleLoader(true);
        let query;
        query = { pageNo: this.state.timelineCurrentPageNo, pageSize: this.state.timelinePageSize };
        RestApiService.invoke(ApiEndPoints.GET_RECORD_TIMELINE, null, { UID: this.state.selectedAuditUID }, query, { ETId: this.state.schemaData['ETId'], SV: this.state.schemaData['SV'] }).then(res => {
            this.props.toggleLoader(false);
            res.data.data.map((item: any, index: any) => {
                if (item.hasOwnProperty('HashSign')) {
                    delete item.HashSign
                }
            })
            this.setState({ recordTimelineData: res.data.data })
            this.getRecordTimelineColumnNames(res.data.data);
            this.setState({ timelineTotalRecord: res.data.total })
            if (parseInt(res.data.total) % this.state.timelinePageSize === 0) {
                this.setState({ timelineTotalPage: (parseInt(res.data.total) / this.state.timelinePageSize) });
            }
            else {
                this.setState({ timelineTotalPage: ((Math.floor(parseInt(res.data.total) / this.state.timelinePageSize)) + 1) });
            }
        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error', error.response);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    getRecordComparison() {
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_RECORD_COMPARISON, null, { UID: this.state.selectedAuditUID }, null, { ETId: this.state.schemaData['ETId'], SV: this.state.schemaData['SV'] }).then(res => {
            const deltaObj = res.data.data[2];
            const valueArray = []
            for (const [key, value] of Object.entries(deltaObj)) {
                if (key !== '_id' && key !== "") {
                    valueArray.push(value);
                }
            }
            (valueArray.includes('No')) || (valueArray.length === 0) ? this.setState({ isAuditBad: true }) : this.setState({ isAuditBad: false });
            this.props.toggleLoader(false);
            res.data.data.map((item: any, index: any) => {
                if (item.hasOwnProperty('HashSign')) {
                    delete item.HashSign
                }
            })
            this.setState({ recordComparisonData: res.data.data, chainHistoryStatus: res.data.chainHistoryStatus })
            this.getRecordComparisonColumnNames(res.data.data);
        }).catch(error => {
            this.props.toggleLoader(false);
            this.setState({ recordComparisonData: [], chainHistoryStatus: '', recordComparisonColumnList: [] })
            console.log('Get error', error.response);
            error.response.data.errors.forEach((response) => {
                Toaster.errorToaster(response.message);
            })
        })
    }

    editField(field: any, index: any) {
        this.setState({ currentFieldIndex: index, fieldMode: 'EDIT' });
    }

    editIndex(data: any, index: any) {
        this.setState({ currentIndex: index, indexMode: 'EDIT' });
    }

    saveSchema(values: any) {
        const Schema = values;
        Schema.DoSummary = (Schema.DoSummary === 'true');
        Schema.Fields = (Schema && Schema.Fields).map((obj: any) => ({ ...obj, Required: (obj.Required === 'true') }));
        Schema.Indexes = (Schema && Schema.Indexes).map((obj: any) => ({
            ...obj, Delete: (obj.Delete === 'true'), ForceUpdate: (obj.ForceUpdate === 'true'),
            Summary: (obj.Summary === 'true'), History: (obj.History === 'true')
        }));
        if (this.state.etidlist.includes((Schema.ETId).toString()) && (this.state.mode === 'ADD')) {
            Toaster.errorToaster('ETId already exists');
        }
        else {
            this.setState({ showETIdError: false })
            const newSchema = {
                ETId: AppConstants.SCHEMA.ETId,
                SV: AppConstants.SCHEMA.SV,
                Schema,
            }
            this.props.toggleLoader(true);
            RestApiService.invoke(ApiEndPoints.POST_SCHEMAS, null, newSchema, null, null).then(res => {
                this.props.toggleLoader(false);
                Toaster.successToaster('Schema', 'Schema submitted successfully ');
                this.setState({
                    isEditOpenPopup: false,
                    isAddOpenPopup: false,
                    initialState: {
                        TableName: '',
                        ETId: '',
                        DoSummary: 'true',
                        ORGId: "",
                        Description: '',
                        Family: '',
                        Fields: [],
                        Indexes: []
                    }
                })
                setTimeout(() => {
                    this.getSchemaList(this.state.filter);
                }, 2000);

            }).catch(error => {
                this.props.toggleLoader(false);
                console.log('Get error', error.response);
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                })
            })
        }
    }



    goToPreviousPage(page: any, text: string) {
        if (text === 'etiddata') {
            this.setState({
                currentPageNo: page, pageSize: this.state.pageSize
                , skipPage: (this.state.pageSize * (page - 1))
            }, () => {
                this.getETIdDetails();
            });
        }
        else {
            this.setState({ timelineCurrentPageNo: page, timelinePageSize: this.state.timelinePageSize }, () => {
                this.getRecordTimeLine();
            });
        }

    }

    goToNextPage(page: any, text: string) {
        if (text === 'etiddata') {
            this.setState({
                currentPageNo: page, pageSize: this.state.pageSize,
                skipPage: (this.state.pageSize * (page - 1))
            }, () => {
                this.getETIdDetails();
            })
        }
        else {
            this.setState({ timelineCurrentPageNo: page, timelinePageSize: this.state.timelinePageSize }, () => {
                this.getRecordTimeLine();
            });
        }
    }

    isLessthanTotalElement(text: string) {
        if (text === 'etiddata') {
            if ((this.state.currentPageNo * this.state.pageSize) === (this.state.totalPage - 1))
                return true;
            else
                return false;
        }
        else {
            if ((this.state.timelineCurrentPageNo * this.state.timelinePageSize) === (this.state.timelineTotalPage - 1))
                return true;
            else
                return false;
        }

    }

    changePageSize = (value: any, text: string) => {
        if (text === 'etiddata') {
            this.setState({ pageSize: value, currentPageNo: 1, skipPage: 0 }, () => {
                this.getETIdDetails();
            })
        }
        else {
            this.setState({ timelinePageSize: value, timelineCurrentPageNo: 1 }, () => {
                this.getRecordTimeLine();
            });
        }
    }


    onSortingETIdData(orderBy: any, order: any) {
        var data = this.state.orderIndexedFields.map(item => ({ ...item, order: 'desc' }));
        //  var data = [...this.state.orderIndexedFields];
        var index = data.findIndex(obj => obj.column === orderBy);
        data[index].order = order === 'desc' ? 'desc' : 'asc';
        this.setState({ orderIndexedFields: data });
        this.setState({
            currentPageNo: 1, pageSize: this.state.pageSize,
            skipPage: 0, order: order, orderBy: orderBy
        }, () => {
            this.getETIdDetails();
        })
    }

    convertValue = (value: any, columnname: any) => {
        if (typeof value === 'boolean') {
            return value.toString();
        }
        else if (value && (columnname === 'StartTime' || columnname === 'QueueTime' || columnname === 'EndTime' ||
            columnname === 'LastCheck' || columnname === 'PostDate' || columnname === 'CreatedAt' ||
            columnname === 'UpdatedAt' || columnname === 'LastLogin' || columnname === 'TS' || columnname === 'CreateDate') && (value !== "Yes" && value !== "No")) {
            const date = changeEpochToDate(value);
            const stillUtc = moment.utc(date).toDate();
            // return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
            return moment.utc(stillUtc).format('YYYY-MM-DD HH:mm:ss');
        }
        else if (value === "Yes" || value === "No") {
            return value === "Yes" ? <Check className="Check-icon" /> : <Clear className="Cross-icon" />
        }
        else {
            return value ? value : 'null';
        }
    }


    handleAuditAction = (item: any, text: string) => {
        if (text === 'view') {
            this.props.toggleLoader(true);
            this.setState({ selectedAuditUID: item.UID }, () => {
                this.setState({ openAuditModal: true })
                this.getRecordTimeLine();
                this.getRecordComparison();
            });
        }
        else {
            this.props.toggleLoader(true);
            this.setState({ selectedAuditUID: item.UID }, () => {
                this.validateRecord('dataTable');
            });
        }
    }

    handleModalClose() {
        this.setState({
            openAuditModal: false,
            recordTimelineData: [],
            recordTimelineColumnList: [],
            recordComparisonData: [],
            recordComparisonColumnList: [],
            selectedAuditUID: '',
            chainHistoryStatus: ''
        });
    }

    validateRecord(text: any) {
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.VALIDATE_RECORDS, null, { UID: this.state.selectedAuditUID }, null, { ETId: this.state.schemaData['ETId'], SV: this.state.schemaData['SV'] }).then(res => {
            this.props.toggleLoader(false);
            if (res.data.data.Status === 'complete' && text === 'dataTable') {
                this.setState({ openAuditModal: true }, () => {
                    this.getRecordTimeLine();
                    this.getRecordComparison();
                });
            }
            else if (res.data.data.Status === 'complete' && text === 'modal') {
                Toaster.infoToaster('Validation is Complete');
            }
            else if (res.data.data.Status === 'pending') {
                Toaster.infoToaster('Validation is Pending');
            }

        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error', error.response);
            error.response.data.errors.forEach((response) => {
                Toaster.infoToaster(`Validate Request in-progress for UID : ${this.state.selectedAuditUID} `);
            })
        })

    }


    refreshRecordComparisonData() {
        this.getRecordComparison();
    }

    _renderForm({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }: any) {
        if (this.state.initialState) return (
            <form className="schema-form" onSubmit={handleSubmit}>
                <div className={`schemaedit ${this.state.isEditOpenPopup === true && (this.state.mode === 'EDIT' || this.state.mode === 'ADD') ? "" : "hide"}`}>
                    <div className="schemadetails">
                        <div className="schema-heading">
                            <span className="mode-heading">{this.state.mode === 'ADD' ? 'Add' : 'Edit'} Schema </span>
                            <strong onClick={() => this.setState({
                                isEditOpenPopup: false, initialState: {
                                    TableName: '',
                                    ETId: '',
                                    DoSummary: 'true',
                                    ORGId: "",
                                    Description: '',
                                    Family: '',
                                    Fields: [],
                                    Indexes: []
                                }
                            })}><Close /></strong>
                        </div>
                        <div className="schematablecontent">
                            <div className="schematablecontenttype">
                                <div className="schemacontent">
                                    <strong>ETId*</strong>
                                    <p>
                                        <input
                                            type="number"
                                            disabled={this.state.mode === 'EDIT'}
                                            value={values.ETId}
                                            name="ETId"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.ETId && touched.ETId && <div className="alert alert-danger my-3">{errors.ETId}</div>}
                                        {values.ETId && this.state.etidlist.includes((values.ETId).toString()) && (this.state.mode === 'ADD') && <div className="alert alert-danger my-3">ETId already exists</div>}
                                    </p>

                                </div>
                                <div className="schemacontent">
                                    <strong>Organization*</strong>
                                    <p>
                                        <select className="orgddl"
                                            name="ORGId"
                                            disabled={this.state.mode === 'EDIT' || this.state.isFamilyMode === 'EDIT'}
                                            onBlur={handleBlur}
                                            value={values.ORGId}
                                            onChange={handleChange}>
                                            <option value="" hidden label="Select Organization"></option>
                                            {this.state.organizationList && this.state.organizationList.map((elem) =>
                                                <option key={elem.ORGName} value={elem.ORGId}>{elem.ORGName}</option>
                                            )
                                            }
                                        </select>
                                        {/* <input
                                        type="text"
                                        name="ORGId"
                                        disabled={this.state.mode === 'EDIT'}
                                        onBlur={handleBlur}
                                        value={values.ORGId}
                                        onChange={handleChange}
                                    /> */}
                                        {errors.ORGId && touched.ORGId && <div className="alert alert-danger my-3">{errors.ORGId}</div>}
                                    </p>

                                </div>
                                <div className="schemacontent">
                                    <strong>Family</strong>
                                    <p>
                                        <input
                                            type="text"
                                            value={values.Family}
                                            name="Family"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={this.state.isFamilyMode === 'EDIT'}
                                        />
                                        {errors.Family && touched.Family && <div className="alert alert-danger my-3">{errors.Family}</div>}
                                    </p>

                                </div>
                            </div>
                            <div className="schematablecontenttype">

                                <div className="schemacontent">
                                    <strong>Do Summary*</strong>
                                    <p><span> <input
                                        type="radio"
                                        name="DoSummary"
                                        value={'true'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        checked={values.DoSummary === 'true'}

                                    /> Yes
                                    </span>
                                        <span><input
                                            type="radio"
                                            name="DoSummary"
                                            value={'false'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            checked={values.DoSummary === 'false'}

                                        /> No
                                        </span>
                                        {errors.DoSummary && touched.DoSummary && <div className="alert alert-danger my-3">{errors.DoSummary}</div>}
                                    </p>

                                </div>

                                <div className="schemacontent">
                                    <strong>Table Name*</strong>
                                    <p><input
                                        type="text"
                                        name="TableName"
                                        value={values.TableName}
                                        onBlur={handleBlur}
                                        onChange={(event: any) => {
                                            if ((/^[a-zA-Z_0-9 ]+$/).test(event.target.value)) {
                                                handleChange(event);
                                                setFieldValue('TableName', event.target.value.toString().toLowerCase().replace(' ', '_'));
                                            } else {
                                                setFieldValue('TableName', '');
                                            }
                                        }}
                                    />
                                        {errors.TableName && touched.TableName && <div className="alert alert-danger my-3">{errors.TableName}</div>}
                                    </p>
                                </div>
                                {values && <div className="top-description"> <div className="schemacontent">
                                    <strong>Description</strong>
                                    <p><textarea
                                        name="Description"
                                        value={values.Description}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        cols={5}
                                        rows={3}
                                    /></p>
                                    {errors.Description && touched.Description && <div className="alert alert-danger my-3">{errors.Description}</div>}
                                </div>
                                </div>}
                            </div>

                        </div>


                        <div className="schematablelist viewtables">
                            <AppBar position="static">
                                <Tabs value={this.state.value} onChange={this.handleChangeValue.bind(this)} aria-label="Schema">
                                    <Tab className="tabName" label="Fields" {...tabIndexProps(0)} />
                                    <Tab className="tabName" label="Index" {...tabIndexProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <FieldArray name="Fields">
                                    {
                                        (fieldArray) => {
                                            return ((
                                                <div className="tab-content">
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => {
                                                            fieldArray.insert(values.Fields && values.Fields.length || 0, {
                                                                FieldName: '',
                                                                DataType: '',
                                                                MaxLength: '0',
                                                                Decimals: '',
                                                                Required: 'true',
                                                                Description: '',
                                                                Choices: []
                                                            });

                                                            this.setState({ currentFieldIndex: values.Fields && values.Fields.length || 0, fieldMode: 'ADD' });
                                                            this.scrollBottom('submit');
                                                        }}>Add Field</button>
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Actions</TableCell>
                                                                    <TableCell>Field Name</TableCell>
                                                                    <TableCell>DataType</TableCell>
                                                                    <TableCell>Max Length</TableCell>
                                                                    <TableCell>Required</TableCell>
                                                                    <TableCell>Description</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    this.state.initialState.Fields && this.state.initialState.Fields.map((field: any, index: number) =>
                                                                        <TableRow key={"btndeletekey_row" + index}>
                                                                            <TableCell key={"btndeletekey" + index}>
                                                                                <Delete titleAccess={"Delete"} className={`${field['SystemGenerated'] ? 'sysFields' : ''}`} onClick={() => confirmAlert({
                                                                                    title: 'Delete Confirmation',
                                                                                    message: 'Are you sure you want to delete this?',
                                                                                    buttons: [
                                                                                        {
                                                                                            label: 'Yes',
                                                                                            onClick: () => {
                                                                                                fieldArray.remove(index);
                                                                                                const currentState = this.state.initialState;
                                                                                                currentState.Fields.splice(index, 1);
                                                                                                this.setState({ initialState: currentState });
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            label: 'No',
                                                                                            onClick: () => { }
                                                                                        }
                                                                                    ]
                                                                                })}
                                                                                />
                                                                                {<Create className={`${field['SystemGenerated'] ? 'sysFields' : ''}`} onClick={() => this.editField(field, index)} />}
                                                                            </TableCell>
                                                                            <TableCell key={"field" + index}>{field['FieldName']}</TableCell>
                                                                            <TableCell key={"datatype" + index}>{field['DataType']}</TableCell>
                                                                            <TableCell key={"typedes" + index}>{(field['DataType'] === "BOOLEAN" || field['DataType'] === "DATETIME(EPOCH)") ? "" : field['MaxLength']}</TableCell>
                                                                            <TableCell key={"required" + index}>{field['Required'] === 'true' ? "Yes" : "No"}</TableCell>
                                                                            <TableCell key={"descirptf" + index}><span title={field['Description']}>{field['Description']}</span></TableCell>
                                                                        </TableRow>)
                                                                }
                                                                {this.state.initialState.Fields.length === 0 && <TableRow>
                                                                    <TableCell colSpan={5} align={"center"}>
                                                                        No Data Found
                                                                    </TableCell>
                                                                </TableRow>

                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    {
                                                        values.Fields && values.Fields[this.state.currentFieldIndex] && (
                                                            <div className={`schema-detail-field`}>
                                                                <div className="heading">
                                                                    <h4> Fields</h4>
                                                                </div>
                                                                <div className="schema-edit-field">
                                                                    <div className="schema-edit">
                                                                        <strong>Field Name*</strong>
                                                                        <span><input
                                                                            type="text"
                                                                            name={`Fields.${this.state.currentFieldIndex}.FieldName`}
                                                                            onBlur={handleBlur}
                                                                            value={values.Fields[this.state.currentFieldIndex].FieldName}
                                                                            onChange={(event: any) => {
                                                                                if ((/^[a-zA-Z0-9_ ]+$/).test(event.target.value) || event.target.value === '') {
                                                                                    handleChange(event);
                                                                                    setFieldValue(`Fields.${this.state.currentFieldIndex}.FieldName`, event.target.value.replace(' ', '_'))
                                                                                } else {
                                                                                    setFieldValue(`Fields.${this.state.currentFieldIndex}.FieldName`, '');
                                                                                }
                                                                            }}
                                                                        />
                                                                            {touched.Fields && errors.Fields && errors.Fields.length > 0 && touched.Fields[this.state.currentFieldIndex] &&
                                                                                touched.Fields[this.state.currentFieldIndex].FieldName && errors.Fields[this.state.currentFieldIndex] && errors.Fields[this.state.currentFieldIndex].FieldName &&
                                                                                <div className="alert alert-danger my-3">{errors.Fields[this.state.currentFieldIndex].FieldName}</div>
                                                                            }
                                                                            {
                                                                                values.Fields[this.state.currentFieldIndex].FieldName && values.Fields.filter((field: any) => field.FieldName === values.Fields[this.state.currentFieldIndex].FieldName).length !== 1 &&
                                                                                <div className="alert alert-danger my-3">This FieldName already exists</div>
                                                                            }
                                                                            {/* {
                                                                                (this.state.mode === 'ADD') && this.state.systemGeneratedFieldNames.includes(values.Fields[this.state.currentFieldIndex].FieldName) &&
                                                                                <div className="alert alert-danger my-3">This is a System Generated Field. It cannot be used as Field Name.</div>
                                                                            } */}
                                                                        </span>

                                                                    </div>

                                                                    <div className="schema-edit">
                                                                        <strong>Data Type*</strong>
                                                                        <span>
                                                                            <select
                                                                                className="form-control"
                                                                                name={`Fields.${this.state.currentFieldIndex}.DataType`}
                                                                                value={values.Fields[this.state.currentFieldIndex].DataType}
                                                                                onBlur={handleBlur}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    const maxValue = this.state.dataTypeList.find((type: any) => type.Name === event.target.value).MaxValue;
                                                                                    setFieldValue(`Fields.${this.state.currentFieldIndex}.MaxLength`, maxValue === undefined ? 100 : maxValue > 0 ? maxValue : 100);

                                                                                }}

                                                                            >
                                                                                <option value="" hidden label="Select DataType"></option>
                                                                                {
                                                                                    this.state.dataTypeList && this.state.dataTypeList.length > 0 && this.state.dataTypeList.map((item: any) => (
                                                                                        <option key={item.Name} value={item.Name} label={item.Name}></option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            {
                                                                                touched.Fields && errors.Fields && errors.Fields.length > 0 && errors.Fields[this.state.currentFieldIndex] && errors.Fields[this.state.currentFieldIndex].DataType && touched.Fields[this.state.currentFieldIndex] &&
                                                                                touched.Fields[this.state.currentFieldIndex].DataType &&
                                                                                <div className="alert alert-danger my-3">{errors.Fields[this.state.currentFieldIndex].DataType}</div>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="schema-edit-field">
                                                                    {values.Fields[this.state.currentFieldIndex].DataType && (values.Fields[this.state.currentFieldIndex].DataType !== 'BOOLEAN' && values.Fields[this.state.currentFieldIndex].DataType !== 'DATETIME(EPOCH)') && <div className="schema-edit">
                                                                        <strong>Max Length*</strong>
                                                                        <span><input
                                                                            type="number"
                                                                            name={`Fields.${this.state.currentFieldIndex}.MaxLength`}
                                                                            value={values.Fields[this.state.currentFieldIndex].MaxLength}
                                                                            onBlur={handleBlur}
                                                                            onChange={event => {
                                                                                setFieldValue(`Fields.${this.state.currentFieldIndex}.MaxLength`, event.target.value)
                                                                            }}
                                                                            min={10} />
                                                                            {
                                                                                touched.Fields && errors.Fields && errors.Fields.length > 0 && errors.Fields[this.state.currentFieldIndex] && errors.Fields[this.state.currentFieldIndex].MaxLength &&
                                                                                touched.Fields[this.state.currentFieldIndex] && touched.Fields[this.state.currentFieldIndex].MaxLength &&
                                                                                <div className="alert alert-danger my-3">{errors.Fields[this.state.currentFieldIndex].MaxLength}</div>
                                                                            }
                                                                        </span>

                                                                    </div>}
                                                                    <div className="schema-edit">
                                                                        <strong>Required</strong>
                                                                        <div className="checks">
                                                                            <span> <input
                                                                                type="radio"
                                                                                name={`Fields.${this.state.currentFieldIndex}.Required`}
                                                                                value={'true'}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                checked={values.Fields[this.state.currentFieldIndex].Required === 'true'}
                                                                            /> Yes
                                                                            </span>
                                                                            <span><input
                                                                                type="radio"
                                                                                name={`Fields.${this.state.currentFieldIndex}.Required`}
                                                                                value={'false'}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                checked={values.Fields[this.state.currentFieldIndex].Required === 'false'} /> No
                                                                            </span>
                                                                            {touched.Fields && errors.Fields && errors.Fields.length > 0 && errors.Fields[this.state.currentFieldIndex] && errors.Fields[this.state.currentFieldIndex].Required && touched.Fields[this.state.currentFieldIndex] && touched.Fields[this.state.currentFieldIndex].Required && <div className="alert alert-danger my-3">{errors.Fields[this.state.currentFieldIndex].Required}</div>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="schema-edit">
                                                                        <strong>Description</strong>
                                                                        <span><input
                                                                            type="text"
                                                                            name={`Fields.${this.state.currentFieldIndex}.Description`}
                                                                            onBlur={handleBlur}
                                                                            value={values.Fields[this.state.currentFieldIndex].Description}
                                                                            onChange={(event: any) => {
                                                                                handleChange(event);
                                                                                setFieldValue(`Fields.${this.state.currentFieldIndex}.Description`, event.target.value)
                                                                            }}
                                                                        />
                                                                        </span>

                                                                    </div>

                                                                </div>
                                                                {values.Fields[this.state.currentFieldIndex].DataType && values.Fields[this.state.currentFieldIndex].DataType === 'DECIMAL' && <div className="schema-edit-field">
                                                                    <div className="schema-edit">
                                                                        <strong>Field Decimals*</strong>

                                                                        <span> <input
                                                                            type="text"
                                                                            name={`Fields.${this.state.currentFieldIndex}.Decimals`}
                                                                            value={values.Fields[this.state.currentFieldIndex].Decimals}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange} />
                                                                            {touched.Fields && errors.Fields && errors.Fields.length > 0 && errors.Fields[this.state.currentFieldIndex] && errors.Fields[this.state.currentFieldIndex].Decimals && touched.Fields[this.state.currentFieldIndex] && touched.Fields[this.state.currentFieldIndex].Decimals && <div className="alert alert-danger my-3">Decimal Field is Required</div>}
                                                                        </span>


                                                                    </div>
                                                                </div>}


                                                                <div className="schema-edit-field">

                                                                    <FieldArray name={`Fields.${this.state.currentFieldIndex}.Choices`}>
                                                                        {
                                                                            (choicesProps: any) => (
                                                                                <div className="schema-edit-fields">
                                                                                    <div className="schema-edits">

                                                                                        <div className="schema-adds">
                                                                                            <strong className="choice-item">Choices </strong>
                                                                                            {/* <button className="btn btn-primary" onClick={() => this.addBlankChoice()}>+ Add Choices </button> */}

                                                                                            <span><button type="button" className="btn btn-primary" onClick={() => choicesProps.insert(0, { Name: '', Value: '' })}>+</button></span>
                                                                                        </div>

                                                                                        {values.Fields && values.Fields[this.state.currentFieldIndex] && values.Fields[this.state.currentFieldIndex].Choices && values.Fields[this.state.currentFieldIndex].Choices.map((choice: any, choiceIndex: number) =>
                                                                                            <div className="add-schema-list" key={choiceIndex} >
                                                                                                <div className="schema-choices">
                                                                                                    <div className="schema-values">
                                                                                                        <span> <input
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            placeholder="key"
                                                                                                            value={values.Fields[this.state.currentFieldIndex].Choices[choiceIndex].Name}
                                                                                                            name={`Fields.${this.state.currentFieldIndex}.Choices.${choiceIndex}.Name`}
                                                                                                            onChange={handleChange} /> </span>
                                                                                                    </div> :
                                                                                                    <div className="schema-values">
                                                                                                        <span>{
                                                                                                            values.Fields[this.state.currentFieldIndex].DataType !== 'BOOLEAN' &&
                                                                                                            <input
                                                                                                                type={values.Fields[this.state.currentFieldIndex].DataType === 'TEXT' ? 'text' : 'number'}
                                                                                                                placeholder="value"
                                                                                                                className="form-control"
                                                                                                                value={values.Fields[this.state.currentFieldIndex].Choices[choiceIndex].Value}
                                                                                                                name={`Fields.${this.state.currentFieldIndex}.Choices.${choiceIndex}.Value`}
                                                                                                                maxLength={values.Fields[this.state.currentFieldIndex].MaxLength}
                                                                                                                onChange={handleChange} />
                                                                                                        }
                                                                                                            {
                                                                                                                values.Fields[this.state.currentFieldIndex].DataType === 'BOOLEAN' &&
                                                                                                                <select
                                                                                                                    className="form-control"
                                                                                                                    value={values.Fields[this.state.currentFieldIndex].Choices[choiceIndex].Value}
                                                                                                                    name={`Fields.${this.state.currentFieldIndex}.Choices.${choiceIndex}.Value`}
                                                                                                                    onChange={event => {
                                                                                                                        setFieldValue(`Fields.${this.state.currentFieldIndex}.Choices.${choiceIndex}.Value`, event.target.value === 'true' ? true : false);
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <option value="true">True</option>
                                                                                                                    <option value="false">False</option>
                                                                                                                </select>
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <button type="button" onClick={() => choicesProps.remove(choiceIndex)} className="btn-primary">X</button>
                                                                                                </div>

                                                                                            </div>
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                            )}
                                                                    </FieldArray>
                                                                </div>
                                                                <div className="schemabtn">
                                                                    {this.state.fieldMode === 'ADD' && <button type="button" className="btn btn-primary" onClick={() => {
                                                                        fieldArray.remove(this.state.currentFieldIndex);
                                                                        this.setState({ currentFieldIndex: null });
                                                                    }}>Cancel</button>}
                                                                    {this.state.fieldMode !== 'ADD' && <button type="button" className="btn btn-primary" onClick={() => {
                                                                        setFieldValue(`Fields.${this.state.currentFieldIndex}`, this.state.initialState.Fields[this.state.currentFieldIndex]);
                                                                        this.setState({ currentFieldIndex: null });
                                                                    }} >Cancel</button>}
                                                                    <button
                                                                        disabled={(values.Fields[this.state.currentFieldIndex].FieldName === "" || values.Fields[this.state.currentFieldIndex].DataType === "" || values.Fields[this.state.currentFieldIndex].MaxLength === "")}
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                initialState: {
                                                                                    ...this.state.initialState, TableName: values.TableName,
                                                                                    ETId: values.ETId,
                                                                                    DoSummary: values.DoSummary,
                                                                                    ORGId: values.ORGId,
                                                                                    Description: values.Description,
                                                                                    Family: values.Family,
                                                                                    Fields: values.Fields,

                                                                                }
                                                                            });
                                                                            this.setState({ currentFieldIndex: null });
                                                                            Toaster.successToaster("setIndex", "Please click on Submit button to save the data.")
                                                                        }} >Apply</button>


                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            ));
                                        }
                                    }
                                </FieldArray>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <FieldArray name="Indexes">
                                    {
                                        (fieldArray) => {
                                            return ((
                                                <div className="tab-content">
                                                    <button disabled={this.state.initialState.Fields.length === 0} type="button" className={`btn btn-primary`}
                                                        onClick={() => {
                                                            fieldArray.insert(values.Indexes && values.Indexes.length || 0, {
                                                                Fields: [],
                                                                IndexValue: "",
                                                                ForceUpdate: 'false',
                                                                Delete: 'false',
                                                                Summary: 'false',
                                                                History: 'false'
                                                            });
                                                            this.setState({ currentIndex: values.Indexes && values.Indexes.length || 0, indexMode: 'ADD' })
                                                        }}>Add Index</button>
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Actions</TableCell>
                                                                    <TableCell>Index Name</TableCell>
                                                                    <TableCell>Summary</TableCell>
                                                                    <TableCell>History</TableCell>
                                                                    <TableCell>Fields</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {this.state.initialState.Indexes && this.state.initialState.Indexes.map((field: any, index: number) => <TableRow>
                                                                    <TableCell>
                                                                        {/* <Create titleAccess={"Edit"} onClick={() => this.editIndex(field, index)} /> */}
                                                                        <Delete titleAccess={"Delete"} onClick={() => confirmAlert({
                                                                            title: 'Delete Confirmation',
                                                                            message: 'Are you sure you want to delete this?',
                                                                            buttons: [
                                                                                {
                                                                                    label: 'Yes',
                                                                                    onClick: () => {
                                                                                        fieldArray.remove(index);
                                                                                        const currentState = this.state.initialState;
                                                                                        currentState.Indexes.splice(index, 1);
                                                                                        this.setState({ initialState: currentState });
                                                                                    }
                                                                                },
                                                                                {
                                                                                    label: 'No',
                                                                                    onClick: () => { }
                                                                                }
                                                                            ]
                                                                        })
                                                                        }
                                                                        />
                                                                        <Create onClick={() => this.editIndex(field, index)} />
                                                                    </TableCell>
                                                                    <TableCell key={"indexvalue" + index}>{field['IndexValue']}</TableCell>
                                                                    <TableCell key={"summary" + index}>{field['Summary'] === 'true' ? "Yes" : "No"}</TableCell>
                                                                    <TableCell key={"history" + index}>{field['History'] === 'true' ? "Yes" : "No"}</TableCell>
                                                                    <TableCell key={"indexfield" + index}>
                                                                        <TableRow>
                                                                            {field["Fields"] && field["Fields"].map((field2: any, index2: number) =>
                                                                                <TableCell key={index2} className={"cellboarder"}>
                                                                                    {field2}
                                                                                </TableCell>

                                                                            )}
                                                                        </TableRow>
                                                                    </TableCell>
                                                                </TableRow>
                                                                )}
                                                                {this.state.initialState.Indexes.length === 0 && <TableRow>

                                                                    <TableCell align={"center"} colSpan={3}>
                                                                        No Data Found
                                                                    </TableCell>

                                                                </TableRow>
                                                                }
                                                            </TableBody>

                                                        </Table>
                                                    </TableContainer>
                                                    {values && values.Indexes && values.Indexes[this.state.currentIndex] && (
                                                        <div className={`schemas`}>
                                                            <div className="heading">
                                                                <h4>Index</h4>

                                                                {
                                                                    (this.state.mode !== 'ADD' && this.state.initialState && this.state.initialState.Indexes[this.state.currentIndex]) &&
                                                                    <p style={{ color: 'red', fontSize: '12px', marginBottom: '0px' }}>Existing </p>
                                                                }

                                                                {/* <button type="button" className="btn-primary" onClick={() => this.setState({ currentIndex: null })}>x</button> */}
                                                            </div>
                                                            <div className="schema-edit-index">
                                                                <div className="edit-schemas">
                                                                    <div className="schema-index">
                                                                        <strong>Index Name*</strong>
                                                                        <span>
                                                                            <input
                                                                                disabled={(this.state.mode !== 'ADD' && this.state.initialState && this.state.initialState.Indexes[this.state.currentIndex])}
                                                                                type="text"
                                                                                placeholder=""
                                                                                value={values.Indexes[this.state.currentIndex].IndexValue}
                                                                                name={`Indexes.${this.state.currentIndex}.IndexValue`}
                                                                                onChange={handleChange} /> </span>
                                                                    </div>
                                                                    {this.state.mode !== 'ADD' && this.state.initialState && this.state.initialState.TableName !== values.TableName && <div className="schema-index">
                                                                        <strong>Force Update*</strong>
                                                                        <div className="check">
                                                                            <span> <input
                                                                                type="radio"
                                                                                name={`Indexes.${this.state.currentIndex}.ForceUpdate`}
                                                                                value={'true'}
                                                                                onChange={handleChange}
                                                                                checked={values.Indexes[this.state.currentIndex].ForceUpdate === 'true'}
                                                                            /> Yes
                                                                            </span>
                                                                            <span><input
                                                                                type="radio"
                                                                                name={`Indexes.${this.state.currentIndex}.ForceUpdate`}
                                                                                value={'false'}
                                                                                onChange={handleChange}
                                                                                checked={values.Indexes[this.state.currentIndex].ForceUpdate === 'false'} /> No
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                    {this.state.mode !== 'ADD' &&
                                                                        <div className="schema-index">
                                                                            <strong>Delete Index*</strong>
                                                                            <div className="check">
                                                                                <span> <input
                                                                                    type="radio"
                                                                                    name={`Indexes.${this.state.currentIndex}.Delete`}
                                                                                    value={'true'}
                                                                                    onChange={handleChange}
                                                                                    checked={values.Indexes[this.state.currentIndex].Delete === 'true'}
                                                                                /> Yes
                                                                                </span>
                                                                                <span><input
                                                                                    type="radio"
                                                                                    name={`Indexes.${this.state.currentIndex}.Delete`}
                                                                                    value={'false'}
                                                                                    onChange={handleChange}
                                                                                    checked={values.Indexes[this.state.currentIndex].Delete === 'false'} /> No
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        <div className="schema-index">
                                                                            <strong>Summary</strong>
                                                                            <div className="check">
                                                                                <span> <input
                                                                                    type="radio"
                                                                                    name={`Indexes.${this.state.currentIndex}.Summary`}
                                                                                    value={'true'}
                                                                                    onChange={handleChange}
                                                                                    disabled={values.DoSummary === 'false'}
                                                                                    checked={values.Indexes[this.state.currentIndex].Summary === 'true'}
                                                                                /> Yes
                                                                                </span>
                                                                                <span> <input
                                                                                    type="radio"
                                                                                    name={`Indexes.${this.state.currentIndex}.Summary`}
                                                                                    value={'false'}
                                                                                    onChange={handleChange}
                                                                                    disabled={values.DoSummary === 'false'}
                                                                                    checked={values.Indexes[this.state.currentIndex].Summary === 'false'} /> No
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        <div className="schema-index">
                                                                            <strong>History</strong>
                                                                            <div className="check">
                                                                                <span> <input
                                                                                    type="radio"
                                                                                    name={`Indexes.${this.state.currentIndex}.History`}
                                                                                    value={'true'}
                                                                                    onChange={handleChange}
                                                                                    checked={values.Indexes[this.state.currentIndex].History === 'true'}
                                                                                /> Yes
                                                                                </span>
                                                                                <span><input
                                                                                    type="radio"
                                                                                    name={`Indexes.${this.state.currentIndex}.History`}
                                                                                    value={'false'}
                                                                                    onChange={handleChange}
                                                                                    checked={values.Indexes[this.state.currentIndex].History === 'false'} /> No
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <FieldArray name={`Indexes.${this.state.currentIndex}.Fields`}>
                                                                    {
                                                                        (fieldProps: any) => (
                                                                            <div className="schemafields">
                                                                                <div className="fileds-list">
                                                                                    <strong>Fields</strong>
                                                                                    <span>
                                                                                        <select className="form-control" onChange={(event: any) => {
                                                                                            if (!(values.Indexes[this.state.currentIndex].Fields.find((field: any) => field === event.target.value))) {
                                                                                                fieldProps.insert(0, event.target.value);
                                                                                            }
                                                                                        }}>
                                                                                            <option hidden value={''} label={'Select Fields'} />
                                                                                            {
                                                                                                values.Fields && values.Fields.map((item: any, index: number) => item && item.FieldName &&
                                                                                                    <option key={values.Fields[index].FieldName} value={values.Fields[index].FieldName} label={values.Fields[index].FieldName} />)
                                                                                            }
                                                                                        </select>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="schema-edits">

                                                                                    {
                                                                                        values.Indexes && values.Indexes[this.state.currentIndex] && values.Indexes[this.state.currentIndex].Fields && values.Indexes[this.state.currentIndex].Fields.map((field: any, fieldIndex: number) => {
                                                                                            return (
                                                                                                <div className="exitinf-fields" key={fieldIndex} >
                                                                                                    <span>
                                                                                                        <input
                                                                                                            disabled
                                                                                                            type="text"
                                                                                                            className={`form-control ${!(values && values.Fields.find((field: any) => (field.FieldName === values.Indexes[this.state.currentIndex].Fields[fieldIndex]))) ? 'has-error' : ''}`}
                                                                                                            placeholder=""
                                                                                                            value={values.Indexes[this.state.currentIndex].Fields[fieldIndex]}
                                                                                                            name={`Indexes.${this.state.currentIndex}.Fields.${fieldIndex}`}
                                                                                                            onChange={handleChange} />
                                                                                                    </span>
                                                                                                    {
                                                                                                        ((this.state.mode === 'ADD') || !(this.state.initialState && this.state.initialState.Indexes[this.state.currentIndex] && this.state.initialState.Indexes[this.state.currentIndex].Fields && this.state.initialState.Indexes[this.state.currentIndex].Fields[fieldIndex])) &&
                                                                                                        <button type="button" className="btn-primary" onClick={() => fieldProps.remove(fieldIndex)}>x</button>
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }
                                                                </FieldArray>
                                                            </div>
                                                            <div className="schemabtn">
                                                                {this.state.indexMode === 'ADD' && <button type="button" className="btn btn-primary"
                                                                    onClick={() => {
                                                                        fieldArray.remove(this.state.currentIndex);
                                                                        this.setState({ currentIndex: null });
                                                                    }}>Cancel</button>}
                                                                {this.state.indexMode !== 'ADD' && <button type="button" className="btn btn-primary" onClick={() => {
                                                                    setFieldValue(`Indexes.${this.state.currentIndex}`, this.state.initialState.Indexes[this.state.currentIndex]);
                                                                    this.setState({ currentIndex: null });
                                                                }}>Cancel</button>}
                                                                <button
                                                                    disabled={this.state.indexMode === 'ADD' && (values.Indexes[this.state.currentIndex].IndexValue === "" || values.Indexes[this.state.currentIndex].Fields.length === 0)}
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => {
                                                                        this.setState({ initialState: { ...this.state.initialState, Indexes: values.Indexes } });
                                                                        this.setState({ currentIndex: null });
                                                                        Toaster.successToaster("setField", "Please click on Submit button to save the data.");
                                                                    }}>Apply</button>

                                                            </div>

                                                        </div>

                                                    )
                                                    }
                                                </div>
                                            ));
                                        }
                                    }
                                </FieldArray>
                            </TabPanel>
                            <div className="schemabtn" id="submit">
                                <button className={`btn btn-primary`} disabled={this.state.initialState.Fields.length === 0 && this.state.initialState.Indexes.length === 0 ? true : false} type="submit">Submit</button>
                                {/* <button className="btn btn-primary">Cancel</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    openAddSchemaPopup(value: any, family?: any, ORGId?: any) {
        const jwtServices = new JwtServices();
        const currentUserRoles = jwtServices.getUserRoles()[0];
        const isAdmin = (currentUserRoles.scopes.includes("AdminAccess") || currentUserRoles.scopes.includes("ReadWrite"));
        if (isAdmin) {
            if (value) {
                this.closeSchemaView();
                this.setState({
                    isEditOpenPopup: true,
                    mode: 'ADD',
                    isFamilyMode: family ? 'EDIT' : '',
                    initialState: {
                        TableName: '',
                        ETId: '',
                        DoSummary: 'true',
                        Description: '',
                        Family: family ? family : '',
                        ORGId: ORGId ? ORGId : "",
                        Fields: this.state.systemFields.length > 0 ? this.state.systemFields : [],
                        Indexes: []
                    }
                })
            }
        }
        else {
            Toaster.errorToaster("Not authorized to create schema");
        }
    }

    closeDefaultTree() {
        this.setState({ isDefaultRow: false });
    }

    editTreeEvent(event: any, item2: any) {
        this.setState({
            initialState: {
                ...this.state.initialState, ETId: null, ORGId: null, TableName: '',
                DoSummary: false,
                Description: '', Family: '',
                Fields: [],
                Indexes: []
            },
            anchorEl: event.currentTarget
        });
        this.props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_SCHEMAS, null, null, { ETId: item2.ETId, SV: item2.SV, detail: true }, null).then(res => {
            this.props.toggleLoader(false);
            let item = res.data.data[0];
            this.setState({
                isEditMenu: true,
                initialState: {
                    ...this.state.initialState, ETId: item.ETId, ORGId: item.ORGId, TableName: item.TableName,
                    DoSummary: typeof (item.DoSummary) === 'boolean' ? (item.DoSummary).toString() : (item.DoSummary) = 'false',
                    Description: item.Description, Family: item.Family,
                    Fields: item.Fields.map((obj: any) => ({ ...obj, Required: typeof obj.Required === 'boolean' ? obj.Required.toString() : obj.Required = 'false' })),
                    Indexes: item.Indexes ? item.Indexes.map((obj: any) => ({
                        ...obj, Delete: typeof obj.Delete === 'boolean' ? obj.Delete.toString() : obj.Delete = 'false',
                        ForceUpdate: typeof obj.ForceUpdate === 'boolean' ? obj.ForceUpdate.toString() : obj.ForceUpdate = 'false',
                        Summary: typeof obj.Summary === 'boolean' ? obj.Summary.toString() : obj.Summary = 'false',
                        History: typeof obj.History === 'boolean' ? obj.History.toString() : obj.History = 'false'
                    })) : []
                },
                // anchorEl: event.currentTarget
            });
        }).catch(error => {
            this.props.toggleLoader(false);
            console.log('Get error in schema component', error);
        })
    }

    groupItemTemplate = (item: any) => {
        //if (item.Family) {
        return (item.Fields && item.Fields.sort((a: any, b: any) => (a.nodeName.localeCompare(b.nodeName))).map((fields: any, index2: number) => <span>
            <IconButton key={"Etnb" + fields.nodeId} title={"menu"} aria-label="more" aria-controls="long-menu" aria-haspopup="true" className={"childItemMenu"}>
                <MoreVert key={"Etb_icon" + fields.nodeId} aria-controls="long-menu" aria-haspopup="true" onClick={(event2) => { item.open = true; this.editTreeEvent(event2, fields); }} />
            </IconButton>
            <TreeItem title={`${fields.nodeName} (${fields.ETId})`} className={`treeChildClass ${(this.state.isNodeSelected === fields.ETId) ? 'selected-child' : ''}`} key={"Family_c" + fields.nodeId} nodeId={"f" + fields.nodeName} label={`${fields.nodeName.substring(0, 64)} (${fields.ETId})`} onClick={() => { this.openSchemaView(fields.ETId, fields.nodeName, false); this.closeDefaultTree() }}>
            </TreeItem>
        </span>
        ))
        // }
        // else
        //     return;
    }
    search(indexName: any) {
        if (indexName.indexFieldValue !== '') {
            indexName = indexName.indexFieldValue.trim();
            indexName = indexName.toUpperCase();
            const data = this.state.schemaData["Indexes"].filter((item: { [x: string]: string; }) => {
                if (item["IndexValue"] != null) {
                    return item["IndexValue"].toUpperCase().indexOf(indexName) !== -1;
                }
            });
            return this.setState({ schemaIndexData: data });
        }
        else {
            this.setState({ schemaIndexData: this.state.schemaData["Indexes"] });
        }
    }
    CancelSearch() {
        this.setState({ schemaIndexData: this.state.schemaData["Indexes"] });
    }
    setFileteval(event: any) {
        console.log(this.state.etidColumnList, 'hello')

        if (event.target.checked) {
            let newArr = ["CreatedAt", "UpdatedAt", "UID", "LastModifiedBy", "EId", "version", ...this.state.etidColumnList]

            let updated = newArr.filter((item,
                index) => newArr.indexOf(item) === index)
            this.setState({
                isSystemFieldData: true,

                etidColumnList: updated
            })
        }
        else {
            let newArr = [...this.state.etidColumnListTest]
            this.setState({
                isSystemFieldData: false,
                etidColumnList: newArr
            })
        }


    }
    changeEtidRow() {
        if (this.state.isSystemFieldData === true) {
            let newArr = ["CreatedAt", "UpdatedAt", "UID", "LastModifiedBy", "EId", "version", ...this.state.etidColumnList]

            let updated = newArr.filter((item,
                index) => newArr.indexOf(item) === index)
            this.setState({
                etidColumnList: updated
            })
        }
        else if (this.state.isSystemFieldData === false) {
            let newArr = [...this.state.etidColumnListTest]
            this.setState({
                etidColumnList: newArr
            })
        }
    }


    render() {
        return (
            <div className="Innerheading" >
                <SubHeaderComponent
                    headingText={'Schema'}
                    btnText={'Add Schema'}
                    showBtn={true}
                    showFontSizeDDL={true}
                    onButtonClick={(value: any) => this.openAddSchemaPopup(value)}></SubHeaderComponent>
                <div className="Innerdetails">
                    <div className="schemamain">
                        {/* <ResizablePanels
                            displayDirection="row"
                            width="100%"
                            //panelsSize={[17, 83]}
                            panelsSize={20}
                            sizeUnitMeasure="%"
                            // resizerColor="#e9e2e2ad"
                            resizerSize="4px"
                            className="check"
                            
                        > */}
                        <div className="resizablefragmentdiv">
                            <div className="schematree sidebar withMargin panel">
                                <div className="schemaitems">
                                    <TreeView
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                    >
                                        {this.state.listData && this.state.listData.sort((a, b) => (a.nodeName.localeCompare(b.nodeName))).map((item: any, index: number) =>
                                            item && <span key={"schema_span_" + index} className={` ${item.isFamily === true ? "treeParentClass" : ""} ${(this.state.selectedNode === item.nodeName) ? 'selected' : ''} `}>
                                                <div className="addschemaicon"><Tooltip title="Add New Schema Detail"><AddCircle onClick={(value: any) => this.openAddSchemaPopup(value, item.nodeName, item.ORGId)} /></Tooltip></div>
                                                <TreeItem className="treeitemwidth" key={"schema_" + index} nodeId={item.nodeName} label={item.nodeName} onClick={(e) => { this.openSchemaView(item.ETId, item.nodeName, true); this.closeDefaultTree() }}>
                                                    {this.groupItemTemplate(item)}
                                                </TreeItem>

                                                {!item.isFamily && <IconButton key={"menu-edit-btn"} title={"menu"} aria-label="more" aria-controls="long-menu" aria-haspopup="true">
                                                    <MoreVert key={"menu-edit-icon-btn"} aria-controls="long-menu" aria-haspopup="true" onClick={(event) => { item.open = true; this.editTreeEvent(event, item); }} />
                                                </IconButton>}
                                                <div>
                                                    <Menu
                                                        key={"menu-edit-icon-btn-menu"}
                                                        anchorEl={this.state.anchorEl}
                                                        keepMounted
                                                        open={Boolean(this.state.anchorEl)}
                                                        onClose={() => { this.setState({ isEditMenu: false, anchorEl: null }) }}
                                                        className={"editmenulist"}
                                                    >

                                                        <MenuItem onClick={() => this.openEditView()}>
                                                            <button className="btn btn-primary">Edit</button>
                                                        </MenuItem>

                                                    </Menu>

                                                </div>
                                            </span>

                                        )}
                                    </TreeView>
                                </div>


                            </div>
                        </div>
                        <div className="schemadetailsdiv">
                            <div className={`schema-details ${this.state.isSchemaDetail === true ? "" : "hide"}`}>
                                <div className={`schemacontent`}>
                                    <div className="schemaitems">

                                        <div className="schema-heading">
                                            <span><b>Schema Detail</b></span>
                                            <div className="schema-dropdown">
                                                <span><b>Schema Version (SV) :</b></span>
                                                <select onChange={(e) => this.changeVersionData(this.state.schemaData["ETId"], e.target.value)} className="version-dropdown">
                                                    {this.state.versionList && this.state.versionList.map((elem: any, index: number) =>
                                                        <option key={index} value={elem}>{elem}</option>
                                                    )
                                                    }
                                                </select>
                                                <strong onClick={() => this.closeSchemaView()}><Close /></strong>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                                <div className="schemaviewdefault">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel center">
                                                <div className="form-group">
                                                    <span ><strong>Table Name</strong> </span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel">
                                                <div className="form-group">
                                                    <label htmlFor="DataType">{this.state.schemaData["TableName"] ? this.state.schemaData["TableName"] : 'N.A'} </label>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel center">
                                                <div className="form-group">
                                                    <span ><strong>Do Summary</strong> </span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel">
                                                <div className="form-group">
                                                    <label htmlFor="DataType">{this.state.schemaData["DoSummary"] === true ? "Yes" : "No"} </label>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel center ">
                                                <div className="form-group">
                                                    <span ><strong>ETId </strong> </span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel">
                                                <div className="form-group">
                                                    <label htmlFor="DataType">{this.state.schemaData["ETId"]} </label>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel center">
                                                <div className="form-group">
                                                    <span ><strong>Organization</strong> </span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel">
                                                <div className="form-group">
                                                    <label htmlFor="DataType">{this.state.schemaData["ORGName"]} </label>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel center">
                                                <div className="form-group">
                                                    <span ><strong>Family</strong> </span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel">
                                                <div className="form-group">
                                                    <label htmlFor="DataType">{this.state.schemaData["Family"]} </label>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel center">
                                                <div className="form-group">
                                                    <span ><strong>Description</strong> </span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="schemadivlabel">
                                                <div className="form-group">
                                                    <label htmlFor="DataType">{this.state.schemaData["Description"]} </label>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={`schemacontent`}>
                                    <div className="schematablelist ">
                                        <AppBar position="static">
                                            <Tabs value={this.state.value} onChange={this.handleChangeValue.bind(this)} aria-label="Schema">
                                                <Tab className="tabName" label="Fields" {...tabIndexProps(0)} />
                                                <Tab className="tabName" label="Index" {...tabIndexProps(1)} />
                                                {(this.state.isSchemaDetail) && !(this.state.isEditOpenPopup) && <Tab className="tabName" label="Data" {...tabIndexProps(2)} />}
                                            </Tabs>
                                        </AppBar>
                                        <TabPanel value={this.state.value} index={0}>
                                            <div className="searchBarWidth">
                                                <div>
                                                    <Checkbox
                                                        checked={this.state.isSystemField}
                                                        onChange={(event) => this.onFilterSystemFields(event)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'System Fields' }} />
                                                    <span> System Fields</span>
                                                </div>
                                                <SearchBar placeholder="Search Field Name"
                                                    value={this.state.indexFieldValue}
                                                    onChange={(newValue) => this.search({ indexFieldValue: newValue })}
                                                    onCancelSearch={() => this.CancelSearch()}
                                                />
                                            </div>
                                            <div className="schemacont etidfiled">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Field Name</TableCell>
                                                            <TableCell>DataType</TableCell>
                                                            <TableCell>Max Length</TableCell>
                                                            <TableCell>Required</TableCell>
                                                            <TableCell>Description</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.schemaData["Fields"] && this.state.schemaData["Fields"].map((field: any, index: number) =>
                                                            <TableRow key={index}>
                                                                <TableCell component="td" scope="row" className={`${field['SystemGenerated'] ? 'system-fields' : ''}`}>
                                                                    {field.FieldName}
                                                                </TableCell>
                                                                <TableCell className={`${field['SystemGenerated'] ? 'system-fields' : ''}`}>{field.DataType}</TableCell>
                                                                <TableCell className={`${field['SystemGenerated'] ? 'system-fields' : ''}`}>{(field['DataType'] === "BOOLEAN" || field['DataType'] === "DATETIME(EPOCH)") ? "" : field['MaxLength']}</TableCell>
                                                                <TableCell className={`${field['SystemGenerated'] ? 'system-fields' : ''}`}>{field.Required === true ? " Yes" : " No"}</TableCell>
                                                                <TableCell className={`${field['SystemGenerated'] ? 'system-fields' : ''}`}>{field.Description}</TableCell>
                                                                {/* <TableCell><span title={field.Description}>{field.Description}</span></TableCell> */}
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </TabPanel>

                                        <TabPanel value={this.state.value} index={1}>
                                            <div className="searchBarWidth">
                                                <SearchBar placeholder="Search Index Name"
                                                    value={this.state.indexFieldValue}
                                                    onChange={(newValue) => this.search({ indexFieldValue: newValue })}
                                                    onCancelSearch={() => this.CancelSearch()}
                                                />
                                            </div>
                                            {this.state.schemaData["Indexes"] && <div className="schemacont etidfiled">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Index Name</TableCell>
                                                            <TableCell>Summary</TableCell>
                                                            <TableCell>History</TableCell>
                                                            <TableCell>Fields</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.schemaData["Indexes"] && this.state.schemaIndexData.map((field: any, index: number) =>
                                                            <TableRow key={index}>
                                                                <TableCell component="td" scope="row">
                                                                    {field.IndexValue}
                                                                </TableCell>
                                                                <TableCell component="td" scope="row">
                                                                    {field.Summary === true ? " Yes" : " No"}
                                                                </TableCell>
                                                                <TableCell component="td" scope="row">
                                                                    {field.History === true ? " Yes" : " No"}
                                                                </TableCell>
                                                                <TableCell component="td" scope="row">
                                                                    {field["Fields"].map((field2: any, index2: number) =>
                                                                        <TableCell key={index2} className={"cellboarder"}>
                                                                            {field2}
                                                                        </TableCell>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                        )}
                                                        {_.isEmpty(this.state.schemaData["Indexes"]) && <div>
                                                            <TableRow>
                                                                <TableCell align={"center"} colSpan={2}>
                                                                    <span className="not-found"> No Data Found</span>
                                                                </TableCell>
                                                            </TableRow>
                                                        </div>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            }
                                            {/* {_.isEmpty(this.state.schemaData["Indexes"]) && <div className="schemacont default-table-height">
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Index Name</TableCell>
                                                        <TableCell>Fields</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align={"center"} colSpan={2}>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={0}>
                                                        <TableCell align={"center"} colSpan={2}>
                                                           <span className="not-found"> No Data Found</span>
                                                    </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align={"center"} colSpan={2}>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                        } */}
                                        </TabPanel>
                                        <TabPanel value={this.state.value} index={2}>
                                            <div className="searchBarWidth">
                                                <div>
                                                    <Checkbox
                                                        checked={this.state.isSystemFieldData}
                                                        onChange={(event) => this.setFileteval(event)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'System Fields' }} />
                                                    <span> System Fields</span>
                                                </div>

                                            </div>
                                            <div className="schemacont etiddata">

                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {this.state.etidColumnList.length > 0 && <TableCell>Audit</TableCell>}
                                                            {this.state.etidColumnList && this.state.etidColumnList.map((column: any, index: number) => (!this.state.keysToHide.includes(column)) && <TableCell key={index}>
                                                                {<div className="table-sort">
                                                                    <div>{column}</div>
                                                                    {this.state.indexedFields.length > 0 && this.state.indexedFields.includes(column) && <div className="sort-icons">
                                                                        {this.state.orderIndexedFields.find(x => x.column === column && x.order === 'asc') ?
                                                                            <Tooltip title="descending">
                                                                                <ArrowUpwardIcon onClick={() => this.onSortingETIdData(column, 'desc')} className="arrowicon" />
                                                                            </Tooltip> :
                                                                            <Tooltip title="ascending">
                                                                                <ArrowDownwardIcon onClick={() => this.onSortingETIdData(column, 'asc')} className="arrowicon" />
                                                                            </Tooltip>


                                                                        }</div>
                                                                    }
                                                                </div>}

                                                            </TableCell>)}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.etidDataList && this.state.etidDataList.length > 0 ?
                                                            this.state.etidDataList.map((item: any, index: any) => <TableRow key={index}>
                                                                <TableCell>
                                                                    <div className="action-icon">
                                                                        <Tooltip title="View" placement="right">
                                                                            <Visibility color="primary" onClick={() => this.handleAuditAction(item, 'view')} />
                                                                        </Tooltip>
                                                                        <Tooltip title="Validate" placement="right">
                                                                            < VerifiedUser color="primary" onClick={() => this.handleAuditAction(item, 'validate')} />
                                                                        </Tooltip>
                                                                    </div>

                                                                </TableCell>
                                                                {this.state.etidColumnList && this.state.etidColumnList.length > 0 ? this.state.etidColumnList.map((key: any, index: any) =>
                                                                    (!this.state.keysToHide.includes(key)) && <TableCell className={`${(this.convertValue(item[key], key) === 'null') ? 'etid-null-text' : ''}`} key={index}>{this.convertValue(item[key], key)}</TableCell>) : <TableCell>Undefined</TableCell>}
                                                            </TableRow>) : <TableRow><TableCell>No Data Found</TableCell></TableRow>}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            {this.state.etidDataList.length > 0 && <PagingComponent
                                                goToPreviousPage={(page: any) => this.goToPreviousPage(page, 'etiddata')}
                                                goToNextPage={(page: any) => this.goToNextPage(page, 'etiddata')}
                                                changePageSize={(page: any) => this.changePageSize(page, 'etiddata')}
                                                pageSize={this.state.pageSize}
                                                currentPageNo={this.state.currentPageNo}
                                                totalPage={this.state.totalPage}
                                                totalRecord={this.state.totalRecord}
                                                showPageSize={true}
                                                isLessthanTotalElement={this.isLessthanTotalElement('etiddata')}
                                            />}
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>

                            {(this.state.schemaDataAvailable === false) && <div className="schema-details-unavailable"><h3>Schema details do not exist</h3></div >}
                            <div className={`schemaedits ${this.state.isEditOpenPopup === true && (this.state.mode === 'EDIT' || this.state.mode === 'ADD') ? "" : "hide"}`}>
                                <Formik
                                    initialValues={this.state.initialState}
                                    render={this._renderForm}
                                    enableReinitialize
                                    validationSchema={
                                        yup.object().shape({
                                            ETId: yup.number().moreThan(1000).required('ETId Field is Required'),
                                            DoSummary: yup.string().required('DoSummary Field is Required'),
                                            TableName: yup.string().required('TableName Field is Required'),
                                            ORGId: yup.string().required('Organization Field is Required'),
                                            Description: yup.string().notRequired(),
                                            Family: yup.string().notRequired(),
                                            Fields: yup.array().of(yup.lazy<any>((item: any) => {
                                                return (item.DataType === 'BOOLEAN' || item.DataType === 'DATETIME(EPOCH)' || item.DataType === 'INTEGER') ? yup.object().shape({
                                                    FieldName: yup.string().required('FieldName Field is Required'),
                                                    DataType: yup.string().required('DataType Field is Required'),
                                                    MaxLength: yup.number().notRequired(),
                                                    Required: yup.string().required('Required Field is Required'),
                                                }) as yup.Schema<any> : yup.object().shape({
                                                    FieldName: yup.string().required('FieldName Field is Required'),
                                                    DataType: yup.string().required('DataType Field is Required'),
                                                    MaxLength: yup.number().required('MaxLength Field is Required'),
                                                    Required: yup.string().required('Required Field is Required'),
                                                }) as yup.Schema<any>
                                            })),
                                            Indexes: yup.array().of(yup.object().shape({
                                                Fields: yup.array().of(yup.string()),
                                                IndexValue: yup.string(),
                                                ForceUpdate: yup.boolean(),
                                                Delete: yup.boolean()
                                            }))
                                        })}
                                    onSubmit={values => this.saveSchema(values)}>
                                </Formik>
                            </div>
                        </div>
                        {/* </ResizablePanels> */}
                    </div>
                </div>

                <Dialog aria-labelledby="customized-dialog-title" onClose={this.handleModalClose} open={this.state.openAuditModal} className="modalpopoup audit" fullWidth={true} maxWidth={"md"}>
                    <DialogTitle disableTypography >
                        <Typography variant="h6">Assisted Data Audit</Typography>
                        <IconButton aria-label="close" onClick={this.handleModalClose}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent  >
                        <Typography align="center" >Record State and Comparison</Typography>
                        <div className="status-block">
                            <div className="status-section">
                                <span className={`${(this.state.chainHistoryStatus === 'NotAvailable' || this.state.chainHistoryStatus === '') ? 'logo1' : 'logo'}`}>
                                    {(this.state.chainHistoryStatus === 'NotAvailable' || this.state.chainHistoryStatus === '') && <img src={require('../../../assets/images/yellolight1.png')} alt="Audit" title={"Not Audit"} />}
                                    {this.state.chainHistoryStatus === 'pending' && <img src={require('../../../assets/images/redlight1.png')} alt="Audit" title={"Bad Audit"} />}
                                    {this.state.chainHistoryStatus === 'complete' && <img src={require('../../../assets/images/greenlight1.png')} alt="Audit" title={"Good Audit"} />}
                                </span>
                                <Typography variant="body2" align="left" >Status: <span>{this.state.chainHistoryStatus}</span></Typography>
                            </div>
                            {this.state.chainHistoryStatus !== 'complete' && <Tooltip title="Refresh" placement="right">
                                <IconButton aria-label="refresh" onClick={this.refreshRecordComparisonData} disabled={this.state.chainHistoryStatus === 'complete' || (this.state.chainHistoryStatus === "")} color="primary" >
                                    <Refresh />
                                </IconButton>
                            </Tooltip>}
                        </div>
                        <TableContainer>
                            <div className="schemacont comp-data">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {this.state.recordComparisonColumnList && this.state.recordComparisonColumnList.map((column: any, index: number) => (!this.state.keysToHide.includes(column)) && <TableCell key={index}>
                                                {column}
                                            </TableCell>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.recordComparisonData && this.state.recordComparisonData.length > 0 ?
                                            this.state.recordComparisonData.map((item: any, index: any) => <TableRow key={index}>
                                                {this.state.recordComparisonColumnList && this.state.recordComparisonColumnList.length > 0 ? this.state.recordComparisonColumnList.map((key: any, index: any) =>
                                                    (!this.state.keysToHide.includes(key)) && <TableCell className={`${(this.convertValue(item[key], key) === 'null') ? 'audit-null-text' : ''}`} key={index}>{this.convertValue(item[key], key)}</TableCell>) : <TableCell>Undefined</TableCell>}
                                            </TableRow>) : <TableRow><TableCell>No Data Found</TableCell></TableRow>}
                                    </TableBody>
                                </Table>

                            </div>
                        </TableContainer>
                        <Typography align="center" className="timeline-typo">Record Timeline</Typography>
                        <TableContainer>
                            <div className="schemacont audit-data">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {this.state.recordTimelineColumnList && this.state.recordTimelineColumnList.map((column: any, index: number) => (!this.state.keysToHide.includes(column)) && <TableCell key={index}>
                                                {column}
                                            </TableCell>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.recordTimelineData && this.state.recordTimelineData.length > 0 ?
                                            this.state.recordTimelineData.map((item: any, index: any) => <TableRow key={index}>
                                                {this.state.recordTimelineColumnList && this.state.recordTimelineColumnList.length > 0 ? this.state.recordTimelineColumnList.map((key: any, index: any) =>
                                                    (!this.state.keysToHide.includes(key)) && <TableCell className={`${(this.convertValue(item[key], key) === 'null') ? 'audit-null-text' : ''}`} key={index}>{this.convertValue(item[key], key)}</TableCell>) : <TableCell>Undefined</TableCell>}
                                            </TableRow>) : <TableRow><TableCell>No Data Found</TableCell></TableRow>}
                                    </TableBody>
                                </Table>
                            </div>
                            {<PagingComponent
                                goToPreviousPage={(page: any) => this.goToPreviousPage(page, 'auditdata')}
                                goToNextPage={(page: any) => this.goToNextPage(page, 'auditdata')}
                                changePageSize={(page: any) => this.changePageSize(page, 'auditdata')}
                                pageSize={this.state.timelinePageSize}
                                currentPageNo={this.state.timelineCurrentPageNo}
                                totalPage={this.state.timelineTotalPage}
                                totalRecord={this.state.timelineTotalRecord}
                                showPageSize={true}
                                isLessthanTotalElement={this.isLessthanTotalElement('auditdata')}
                            />}
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        {(this.state.chainHistoryStatus === 'NotAvailable' || this.state.chainHistoryStatus === 'pending') && <button type="button" className="btn btn-primary" color="primary" onClick={() => this.validateRecord('modal')}>
                            Validate
                        </button>}
                        {(this.state.chainHistoryStatus !== 'NotAvailable' && this.state.chainHistoryStatus !== 'pending' && this.state.chainHistoryStatus !== "") && <button type="button" className="btn btn-primary" color="primary" onClick={() => this.validateRecord('modal')}>
                            Revalidate
                        </button>}
                        <button type="button" className="btn btn-primary" color="primary" onClick={this.handleModalClose}>
                            OK
                        </button>
                    </DialogActions>
                </Dialog>

            </div >
        );
    }


}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewSchemaListComponent);



