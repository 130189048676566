import React, { useEffect } from 'react';
import './pages.scss'
import PageRoute from './pages.route';
import { Drawer, List, ListItemIcon, ListItemText } from '@material-ui/core';
import SideBarComponent from '../shared/components/sidebar/sideBar';
import HeaderComponent from '../shared/components/header/header';
import Loader from '../shared/components/loader/loader';
import { connect } from 'react-redux';

const PagesComponent = (props: any) => {

    const [openAdminMenu, setOpenAdminMenu] = React.useState<any>(false);
    const [openMenu, setOpenMenu] = React.useState<any>(true);


    useEffect(() => {
        detectMobileView();
    }, []);

    const isOpenAdminMenu = (value) => {
        setOpenAdminMenu(value)
    }


    const isOpenMenu = (value: any) => {
        setOpenMenu(value)
    }

    const detectMobileView = () => {
        if (window.innerWidth <= 767) {
            setOpenMenu(false)
        } else {
            setOpenMenu(true);
        }
    }
    return (
        <div className="main-view">
            <HeaderComponent openMenu={openMenu} onClick={isOpenMenu} />
            <div className="main-content">
                <div className="sidebar-cover">
                    <SideBarComponent openMenu={openMenu} />
                </div>
                <div className={`dashboard-cover ${!openMenu ? "hide-sidebar" : ""}`}>
                    {PageRoute(props)}
                </div>
            </div>
            {props.isLoaderShow && <Loader />}
        </div>
    );
}


const mapStateToProps = (state: any) => {
    return {
        isLoaderShow: state.appLoaderReducer.isLoaderShow,
        isShowSideBar: state.appSidebarReducer
    }
}

export default connect(mapStateToProps)(PagesComponent);