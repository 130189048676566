const AppConstants = {
	API_URL: '/api',
	SCHEMA: {
		ETId: 50,
		SV: 1,
	},
	SCHEMA_DATA: {
		ETId: 1000,
		SV: 1,
	},
	THEME: {
		LIGHT: 'Light',
		DARK_BLUE: 'Dark Blue',
		ALICE_BLUE: 'Alice Blue'
	},
	SYSTEM_GENERATED_ETIDS: {
		clustering: {
			clusters: { ETId: 30, SV: 1 },
			nodes: { ETId: 32, SV: 1 }
		},
		system: {
			binary_files: { ETId: 17, SV: 1 },
			hash_supported: { ETId: 43, SV: 1 }
		},
	}
}
export default AppConstants;

