import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TextField, Button, Paper, Grid, IconButton, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Tooltip } from '@material-ui/core';
import { ExitToApp, WbIncandescent, Link, Email, ChatBubble, VideoLabel, PhotoAlbum, Assignment, MenuBook } from '@material-ui/icons';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PersonIcon from '@material-ui/icons/Person';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./landingPage.scss";
import { setCurrentUser, changePassword } from '../redux/actions/authActions';
import ChangePassword from '../pages/security/ChangePassword';
import { AuthService } from '../core/interceptors/auth.interceptor';
import Toaster from '../services/toaster.services';
import { toggleLoader } from '../redux/actions/loader.action';
import SearchBar from 'material-ui-search-bar';
import { CookieStorage } from 'cookie-storage';
import RestApiService from "../services/http-services";
import { ApiEndPoints } from '../models/api-endpoint';
import axios, { AxiosError } from 'axios';
import appConstants from "../core/constants/appConstants";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PublicIcon from '@material-ui/icons/Public';
import Loader from '../shared/components/loader/loader';

class LandingPageComponent extends Component<any, any> {
  public authSerivce = new AuthService();
  public cookies = new CookieStorage();
  public state = {
    openLogin: false,
    userName: '',
    userFullName: '',
    password: '',
    isLogin: false,
    isFirstUser: false,
    selectedTheme: '',
    authMessage: '',
    selectedFont: 'small',
    Products: [{ "productList": "Platform (PaaD)", "productDocument": "API Document" }, { "productList": "FiVe", "productDocument": "Admin Document" }],
    upcomingRelease: [
      // { "launch": "Platform Version 1.1.1.0", "date": "01 Sept 2022", "status": "Future" },
      // { "launch": "Notary Version 2.0.1.0", "date": "01 Sept 2022", "status": "Future" },
      { "launch": "Platform Version 2.0 ", "date": "22 Jun 2023", "status": "Current" },
      { "launch": "Notary Version 2.0.1.1", "date": "16 Nov 2022", "status": "Current" },
      { "launch": "Platform Version 1.6 ", "date": "02 Feb 2023", "status": "Previous" },
      { "launch": "Notary Version 2.0.1.0", "date": "01 Sep 2022", "status": "Previous" },
    ],
    themeArray: ["Light", "Dark Blue", "Alice Blue"],
    currentYear: new Date().getFullYear()
  }
  constructor(props: any) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {

    this.setState({ selectedTheme: this.cookies.getItem('theme') });
    this.setState({ userFullName: this.cookies.getItem('firstname') + " " + this.cookies.getItem('lastname') });
    this.setState({ userName: this.cookies.getItem('firstname') });
    this.dynamicThemeApply();
    this.onChangeFontSize(this.cookies.getItem('fontSize'));
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }
  componentDidUpdate() {
    this.dynamicThemeApply();
  }

  dynamicThemeApply() {
    if (this.state.selectedTheme === appConstants.THEME.DARK_BLUE) {
      document.documentElement.style.setProperty('--color', '#fff');
      document.documentElement.style.setProperty('--font-color', '#fff');
      document.documentElement.style.setProperty('--tab_background_color', '#23395d');
      document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
      document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
      document.documentElement.style.setProperty('--border_color', '#0078d4');
      document.documentElement.style.setProperty('--border_color1', 'transparent');
      document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
      document.documentElement.style.setProperty('--selected_background_color', '#7488aa');
      document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
      document.documentElement.style.setProperty('--Bad_color', '#c9817d');
      document.documentElement.style.setProperty('--Good_color', '#9bc798');
      document.documentElement.style.setProperty('--background-color', '#23395d');
      document.documentElement.style.setProperty('--hover_background_color', '#305695');
      document.documentElement.style.setProperty('--link-color', '#0078d4');
      document.documentElement.style.setProperty('--table_header_border_color', 'darkslateblue');
      document.documentElement.style.setProperty('--social_media_icon_color', 'white');
    }
    else if (this.state.selectedTheme === appConstants.THEME.ALICE_BLUE) {
      document.documentElement.style.setProperty('--color', '#fff');
      document.documentElement.style.setProperty('--font-color', '#fff');
      document.documentElement.style.setProperty('--tab_background_color', '#3E849E');
      document.documentElement.style.setProperty('--table_header_color', '#2C5F78');
      document.documentElement.style.setProperty('--table_header_background', '#2C5F78');
      document.documentElement.style.setProperty('--border_color', '#06b5f4');
      document.documentElement.style.setProperty('--border_color1', 'transparent');
      document.documentElement.style.setProperty('--btn_background_color', '#2C5F78');
      document.documentElement.style.setProperty('--selected_background_color', '#2e7691');
      document.documentElement.style.setProperty('--Pending_color', '#cfb86e');
      document.documentElement.style.setProperty('--Bad_color', '#c9817d');
      document.documentElement.style.setProperty('--Good_color', '#9bc798');
      document.documentElement.style.setProperty('--background-color', '#3E849E');
      document.documentElement.style.setProperty('--hover_background_color', '#2e7691');
      document.documentElement.style.setProperty('--link-color', '#1b09b0f2');
      document.documentElement.style.setProperty('--table_header_border_color', '#d6d6d6');
      document.documentElement.style.setProperty('--social_media_icon_color', 'white');
    }

    else {
      document.documentElement.style.setProperty('--color', '#0078d4');
      document.documentElement.style.setProperty('--font-color', '#000000');
      document.documentElement.style.setProperty('--tab_background_color', '#ffffff');
      document.documentElement.style.setProperty('--table_header_color', '#c2c0bd');
      document.documentElement.style.setProperty('--table_header_background', '#c2c0bd');
      document.documentElement.style.setProperty('--border_color', '#f1f6ff');
      document.documentElement.style.setProperty('--border_color1', '#f1f6ff');
      document.documentElement.style.setProperty('--btn_background_color', '#0078d4');
      document.documentElement.style.setProperty('--selected_background_color', '#c7e0f4');
      document.documentElement.style.setProperty('--Pending_color', '#fdf0c8');
      document.documentElement.style.setProperty('--Bad_color', '#f8cecc');
      document.documentElement.style.setProperty('--Good_color', '#d5e8d4');
      document.documentElement.style.setProperty('--background-color', '#f1f6ff');
      document.documentElement.style.setProperty('--hover_background_color', '#edebe9');
      document.documentElement.style.setProperty('--link-color', '#0078d4');
      document.documentElement.style.setProperty('--table_header_border_color', '#d6d6d6');
      document.documentElement.style.setProperty('--social_media_icon_color', '#0078d4');
    }
  }
  handleClose = () => {
    this.setState({ openLogin: false });
  };
  onClickPD = (pDirctry: any) => {
    if (this.cookies.getItem('firstname') != null && this.state.userName != '' && this.state.userName != undefined && this.cookies.getItem('access_token') != null && this.cookies.getItem('access_token') != undefined) {
      if (pDirctry === "FiVe") {
        window.open(window.location.origin + "/notary", "_blank")
      }
      else {
        window.open(window.location.origin + "/page/dashboard", "_blank")
      }
    }
    else {

      if (pDirctry === "FiVe") {
        window.open(window.location.origin + "/notary", "_blank")
      }
      else {
        alert("Please Sign-in");
      }
    }
  };
  onLogin = (propsData: any, pathName: any) => {
    this.props.history.push({
      pathname: pathName
    });
  }
  secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);
    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  login = (userName: string, password: string) => {
    this.props.toggleLoader(true);
    this.authSerivce.loginAndSetToken(userName, password).then(res => {
      this.props.toggleLoader(false);
      if (res?.data?.data) {
        if (res.data.data.user.resetPasswordCount === 0) {
          this.setState({ isFirstUser: true });
          this.cookies.setItem("theme", this.state.selectedTheme);
        }
        else {
          this.setState({ isLogin: false });
          document.cookie = "theme" + "=" + this.state.selectedTheme;
          document.cookie = "firstname" + "=" + res.data.data.user.firstName;
          document.cookie = "lastname" + "=" + res.data.data.user.lastName;
          document.cookie = "usershortname" + "=" + res.data.data.user.firstName.charAt(0) + '' + res.data.data.user.lastName.charAt(0);
          // this.cookies.setItem("theme", this.state.selectedTheme);
          // this.cookies.setItem("firstname", res.data.data.user.firstName);
          // this.cookies.setItem("lastname", res.data.data.user.lastName);
          // this.cookies.setItem("usershortname", res.data.data.user.firstName.charAt(0) + '' + res.data.data.user.lastName.charAt(0));
          this.props.setCurrentUser(res.data.data);
          this.setState({ userFullName: res.data.data.user.firstName + " " + res.data.data.user.lastName });
          // this.props.history.push("/page/dashboard");
        }
      }
      else {
        this.props.setCurrentUser(res);
      }
    }).catch((error: AxiosError) => {
      if (error.message === 'Network Error') {
        Toaster.errorToaster(error.message)
      }
      this.props.toggleLoader(false);
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          if (error.response.status !== 422)
            Toaster.errorToaster(response.message);
          if (error.response.status === 422) {
            const responseMessage = response.message.split(' ');
            const errorMessage = response.message.split('for');
            const time = this.secondsToHms(responseMessage[4]);
            Toaster.errorToaster(errorMessage[0] + 'for ' + time + ".Please try later.");
            this.setState({ authMessage: errorMessage[0] + 'for ' + time + ".Please try later." });
            const milesecond = parseInt((responseMessage[4] * 1000).toFixed(0));
            setTimeout(() => {
              this.setState({ authMessage: '' });
            }, milesecond);
          }
        });
      }
    });
  }

  onSubmit = (event: any) => {
    if (event?.preventDefault) {
      event.preventDefault();
      this.login(this.state.userName, this.state.password);
    } else {
      this.resetPassword(event);
    }
  }


  resetPassword = async (userData: any) => {
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.RESET_PASSWORD, null, userData, null, {
      ETId: 10,
      SV: 1,
    }).then((response: any) => {
      this.props.toggleLoader(false);
      Toaster.successToaster("passwordChange", "Password Changed Successfully. Please Login with New password");
      if (response) {
        delete axios.defaults.headers.common['Authorization'];
        this.cookies.clear();
        this.setState({ isFirstUser: false })
        this.setState({ password: '' });
      }
    }).catch(error => {
      this.props.toggleLoader(false);
      if (error.message === 'Network Error') {
        Toaster.errorToaster(error.message)
      }
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });


  }
  onChangeTheme = (theme: any) => {
    this.cookies.removeItem('theme');
    this.setState({ selectedTheme: theme });
    this.cookies.setItem('theme', theme);
    this.dynamicThemeApply();
  }

  onLogout = () => {
    this.cookies.removeItem("firstname");
    this.cookies.removeItem("lastname");
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    this.cookies.clear();
    this.setState({ isLogin: false })
    this.setState({ userName: '' });
  }
  onChangeFontSize = (fontSize?: any) => {
    this.setState({ selectedFont: fontSize });
    this.cookies.setItem('fontSize', fontSize);
    switch (fontSize) {
      case 'Large':
        document.documentElement.style.setProperty('--h3-font-size', '22px');
        return document.documentElement.style.setProperty('--font-text-size', '20px');
      case 'Medium':
        document.documentElement.style.setProperty('--h3-font-size', '20px');
        return document.documentElement.style.setProperty('--font-text-size', '18px');
      default:
        document.documentElement.style.setProperty('--h3-font-size', '17px');
        return document.documentElement.style.setProperty('--font-text-size', '15px');
    }

  }




  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.onSubmit(event)
    }
  };

  render() {
    return (
        <div className={`${this.state.selectedTheme === 'Dark Blue' ? "Innerheadinglandingtheme1" : this.state.selectedTheme === 'Alice Blue' ? "Innerheadinglandingtheme2" : "Innerheadinglanding"}`}>
          <div className="header">
            <div className="headertitle">
              <div className="headeraction">
                <div className="without-login">
                  <span className="logo">
                    <img src={require('../../assets/images/Walacor_Logo2.png')} alt="Walacor" title={"Walacor Workspace"} />
                  </span>
                </div>
                {!this.state.isLogin && <div className="sign-in-btn">
                  {this.state.userName != null && this.state.userName != "" ? <div className="logodiv">
                    <span><div className="user-name">{"Welcome " + "" + this.state.userFullName}</div></span></div> : <Button
                      className={"btn-primary btn-primary-signin"}
                      onClick={() => this.setState({ isLogin: true })}
                    ><PersonIcon />Sign-in</Button>}
                </div>}
                {this.state.isLogin && <div className="login-form">
                  <div className="form-group">
                    <span>Username/Email</span>
                    <TextField
                      id="standard-uncontrolled"
                      margin="normal"
                      onChange={(text) => this.setState({ userName: text.target.value })}
                    />	</div>
                  <div className="form-group password-field">
                    <span>Password</span>
                    <TextField
                      id="standard-password-input"
                      type="password"
                      value={this.state.password}
                      margin="normal"
                      onChange={(text) => this.setState({ password: text.target.value })}
                    />
                  </div>
                  <div className="form-group button-group">
                    <Button
                      className={"btn-primary btn-primary-submit"}
                      onClick={(res: any) => this.onSubmit(res)}
                    >Submit</Button>
                  </div>
                  <div className="account-lock">
                    <span>{this.state.authMessage}</span>
                  </div>
                </div>}
                <div className="selecttheme">
                  <span className="statuslabel">Size</span>
                  <div className="filter-ddl">
                    <select
                      className="form-control"
                      name=''
                      value={this.state.selectedFont}
                      onChange={(event) => this.onChangeFontSize(event.target.value)}
                    >
                      <option key={0} value={"Small"} label={"Small"} style={{ fontSize: 12 }} ></option>
                      <option key={1} value={"Medium"} label={"Medium"} style={{ fontSize: 16 }}></option>
                      <option key={2} value={"Large"} label={"Large"} style={{ fontSize: 20 }}></option>
                    </select>
                  </div>
                </div>
                <div className="selecttheme">
                  <span className={`${this.state.userName != null ? "themelabel1" : "themelabel"}`}>Theme</span>
                  <select
                    className={`${this.state.userName != null ? "form-control1" : "form-control"}`}
                    name=''
                    value={this.state.selectedTheme}
                    onChange={(event) => this.onChangeTheme(event.target.value)}
                  >
                    {this.state.themeArray.map((item: any, index: any) =>
                      <option key={2} value={item} label={item}></option>
                    )}
                  </select>
                </div>
                <Tooltip title={'Sign-out'} placement={'bottom'}>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => this.onLogout()}
                    className='button-signout'
                  >
                    <ExitToApp />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div className="table-header-title">
              <div className="sub-header">
                <h2 className="title">Walacor Workspace</h2>
              </div>
              <Grid item xs={12} md={3}>
                <div className="textsearch">
                  <SearchBar
                    className={'search-bar search'}
                    placeholder={`Search`}
                    value=""
                  />
                </div>
              </Grid>

            </div>
            <div className={`${this.state.selectedTheme === 'Dark Blue' ? "landingmaintheme1" : this.state.selectedTheme === 'Alice Blue' ? "landingmaintheme2" : "landingmaintheme"}`}>
              <div className='gridclass'>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <Paper>
                      <div className="headercontent product">
                        <Grid container direction="row" justify="space-between">
                          <Grid item xs={12}>
                            <div>
                              <div className="table-header">
                                <span><h3>Product </h3></span>
                              </div>

                            </div>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <div className={`${this.state.selectedFont === 'Large' ? "table-header-large" : "table-header"}`}>
                            <TableContainer className="tableContainerRoot">
                              <Table aria-label="customized table" >

                                <TableBody>
                                  {this.state.Products.map((item: any, index: any) =>
                                    <TableRow>
                                      <TableCell align={"left"} component="th" scope="row">
                                        <div className="productlisturl">
                                          <div className="producturl">
                                            <div className="productIcon"><Link onClick={() => this.onClickPD(item.productList)} /></div>
                                            <span onClick={() => this.onClickPD(item.productList)}>{item.productList} </span>
                                          </div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  )
                                  }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </Grid>

                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Paper>
                      <div className="headercontent">
                        <Grid container direction="row" justify="space-between">
                          <Grid item xs={12}>
                            <div>
                              <div className="table-header">
                                <span><h3>Documentation </h3></span>
                              </div>

                            </div>
                          </Grid>

                        </Grid>
                        <Grid item xs={12}>
                          <div className={`${this.state.selectedFont === 'Large' ? "table-header-large" : "table-header"}`}>
                            <TableContainer className="tableContainerRoot">
                              <Table aria-label="customized table" >
                                <TableBody>
                                  {this.state.Products.map((item: any, index: any) =>
                                    <TableRow>
                                      <TableCell align={"left"} component="th" scope="row">
                                        <div className="producturl">
                                          <div><Link /></div>
                                          <div className="product-document">{item.productDocument}</div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  )
                                  }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </Grid>

                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Paper>
                      <div className="importantcontent">

                        <div className="tableContainerDiv"><span className="icon"><MenuBook /></span><div className="textcontent"><h3>Learn</h3></div> </div>
                        <div className="tableContainerDiv"><span className="icon"><Assignment /></span><div className="textcontent"><h3>Documents</h3></div> </div>
                        <div className="tableContainerDiv"><span className="icon"><VideoLabel /></span><div className="textcontent"><h3>Use Case Demos</h3></div> </div>
                        <div className="tableContainerDiv"><span className="icon"><PhotoAlbum /></span><div className="textcontent"><h3>Installation Guides</h3></div> </div>

                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Paper>

                      <div className="headercontent">
                        <Grid item xs={12}>
                          <div className="table-header">
                            <span className="suport-desk"><h3>Support Desk</h3></span>
                            <span><p>Direct ways to get in touch with us.</p></span>
                          </div>
                        </Grid>
                        <div className="supportcontainer">
                          <Grid item xs={12}>
                            <div className="supportbtn">
                              <a><Button
                                className={"btn-primary"}

                              ><ChatBubble />Submit a ticket</Button></a></div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="supportbtn">
                              <Button
                                className={"btn-primary"}
                              ><Email />Email us</Button></div>
                          </Grid>

                        </div>

                      </div>

                    </Paper>
                  </Grid>

                </Grid>
                <div className='gridclass1'>
                  <Grid container spacing={1}  >

                    <Grid item xs={12} md={3}>
                      <Paper>
                        <div className="headercontent">
                          <Grid item xs={12}>
                            <div className="table-header">
                              <span><h3>Technical Tips & Tricks</h3></span>
                              <span><p>Get the latest technical bulletins and news to help enhance your product experience.</p></span>
                            </div>
                          </Grid>
                          <div className="technical-tips">
                            <Grid item xs={12}>
                              <div className="newsslider">
                                <WbIncandescent className="wbincandescent" /><span><p>
                                  Get the latest technical bulletins and news to help enhance.
                                </p>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div className="newsslider">
                                <WbIncandescent className="wbincandescent" />
                                <span><p>
                                  Get the latest technical bulletins and news to help enhance.
                                </p>
                                </span>
                              </div>
                            </Grid>

                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Paper>
                        <div className="headercontent">
                          <Grid item xs={12}>
                            <div className="table-header">
                              <span><h3>Product Release Schedule </h3></span>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="table-header">
                              <TableContainer className={`${this.state.selectedFont === 'Large' ? "tableContainerRoot productReleaseLarge" : "tableContainerRoot productRelease"}`}>
                                <Table aria-label="customized table" >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align={"left"}><div className="release">Launch
                                      </div>
                                      </TableCell>
                                      <TableCell align={"left"}><div className="release">Date
                                      </div>
                                      </TableCell>
                                      <TableCell align={"left"}><div className="release">Product Status
                                      </div>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.upcomingRelease.map((item: any, index: any) =>
                                      <TableRow>
                                        <TableCell align={"left"} component="th" scope="row">
                                          <div className="productlisturl">
                                            {item.launch}
                                          </div>
                                        </TableCell>
                                        <TableCell align={"left"} component="th" scope="row">
                                          <div className="producturl">
                                            {item.date}
                                          </div>
                                        </TableCell>
                                        <TableCell align={"left"} component="th" scope="row">
                                          <div className="producturl">
                                            {item.status}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                            </div>
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Paper>
                        <div className="headercontent">
                          <Grid item xs={12}>
                            <div className="table-header">
                              <span><h3>Visit Walacor Online </h3></span>
                            </div>
                          </Grid>
                          <Grid item xs={12} className="links-grid">
                            <div className="table-header">
                              <TableContainer className={`${this.state.selectedFont === 'Large' ? "tableContainerRoot productReleaseLarge  social-account1" : "tableContainerRoot productRelease social-account"}`}>
                                <Table aria-label="customized table" >
                                  {/* <TableHead>
                                  <TableRow>
                                    <TableCell align={"left"}><div className="our-online-presence">Our Online Presence
                                  </div>
                                    </TableCell>
                                  </TableRow>
                                </TableHead> */}
                                  <TableBody>
                                    <TableRow>
                                      <Grid item xs={12} className="content-grid">
                                        <TableCell style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                          <div className="visitwalacor">
                                            <div className="visitwalacorlink"> <a target="_blank" href="https://www.walacor.com/"><PublicIcon className="socialMediaIcon" style={{ fontSize: 60 }} /></a></div>
                                          </div>
                                          <div className="visitwalacor">
                                            {/* <div className="content-block"> */}
                                            <a target="_blank" href="https://youtube.com/Walacor"><YouTubeIcon className="socialMediaIcon" style={{ fontSize: 60 }} /></a>
                                            {/* </div> */}
                                            <div className="visitwalacorlink"></div>
                                          </div>
                                          <div className="visitwalacor">

                                            <div className="visitwalacorlink"><a target="_blank" href="https://www.facebook.com/Walacor"><FacebookIcon className="socialMediaIcon" style={{ fontSize: 60 }} /></a></div>
                                          </div>
                                          <div className="visitwalacor">
                                            <div className="visitwalacorlink"><a target="_blank" href="http://twitter.com/@walacorcorp"><TwitterIcon className="socialMediaIcon" style={{ fontSize: 60 }} /></a></div>
                                          </div>
                                          <div className="visitwalacor">
                                            <div className="visitwalacorlink"><a target="_blank" href="http://linkedin.com/company/walacor"><LinkedInIcon className="socialMediaIcon" style={{ fontSize: 60 }} /></a></div>
                                          </div>

                                        </TableCell>

                                      </Grid>
                                    </TableRow>
                                  </TableBody>

                                </Table>
                              </TableContainer>

                            </div>
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>

                  </Grid>
                </div>
                <Grid container direction="row" justify="space-between" >
                  <div className="footer">
                    <div className="privacy">
                      <span><p>Privacy Policy</p></span>
                    </div>
                    <div className="privacy">
                      <span><p>Terms of Use</p></span>
                    </div>
                    <div className="privacy">
                      <span><p>Contact Us</p></span>
                    </div>
                    <div className="copyright">
                      <span><p style={{ marginRight: "60px" }}>Copyright {this.state.currentYear} Walacor Corporation</p></span>
                    </div>
                  </div>
                </Grid>
              </div>
              {
                this.state.isFirstUser &&
                <ChangePassword
                  show={this.state.isFirstUser}
                  sendData={(resp: any) => this.onSubmit(resp)}
                  hide={() => this.setState({ isFirstUser: false })} />
              }
            </div>
          </div >
          {<div className='loader-landingPage'>
            {this.props.isLoaderShow? <Loader /> : null}
        </div>}
        </div >
    );
  }


}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  isLoaderShow: state.appLoaderReducer.isLoaderShow
});


const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader, setCurrentUser: setCurrentUser, changePassword: changePassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageComponent);




