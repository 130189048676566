import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './loader.scss';
class Loader extends Component {
    render() {
        return (
            <div id="loader-wrapper">
                {/* <strong className="loadertext">Please Wait...</strong> */}
                <div id="loader"> <CircularProgress size={70} thickness={6} color="primary" /></div>
            </div>
        );
    }
}

export default Loader;

