import React, {  } from 'react';
import './SignUp.scss';
import "../../../assets/themes/default/share.scss";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Typography, InputAdornment } from '@material-ui/core';
import { Link} from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EmailIcon from '@material-ui/icons/Email';
import { AuthService } from '../../core/interceptors/auth.interceptor';
import { ApiEndPoints } from '../../models/api-endpoint';
import RestApiService from '../../services/http-services';

const SignUpComponent = (props: any) => {

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  //  const [rememberMe, setRememberMe] = React.useState(true);

  const authService = new AuthService();

  const onSubmit = (e: any) => {
    e.preventDefault();

    const newUser = {
      firstName,
      lastName,
      email,
      password,
    };
    RestApiService
      .invoke(ApiEndPoints.SIGN_UP, null, newUser)
      .then(res => props.history.push('/sign-in'))
      .catch(err => { console.log(err); }
      );
    // this.props.registerUser(newUser, this.props.history);
  }

  return (
    <div className="content-box">
      <div className="prelogin-box">
        <form action="post" className="form">

          <div className="loginimg">
            <img src={require('../../../assets/images/output-onlinepngtools.png')} alt="logoimage"></img>
          </div>
          <h1 className="form-heading" color="primary">Sign Up</h1>
          <div className="form-group">
            <TextField
              id="standard-uncontrolled"
              label="First Name"
              margin="normal"
              onChange={(text) => setFirstName(text.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group">
            <TextField
              id="standard-uncontrolled"
              label="Last Name"
              margin="normal"
              onChange={(text) => setLastName(text.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group">
            <TextField
              id="standard-uncontrolled"
              label="Email"
              margin="normal"
              onChange={(text) => setEmail(text.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group password-field">
            <TextField
              id="standard-password-input"
              label="Password"
              type="password"
              margin="normal"
              onChange={(text) => setPassword(text.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="login-button">
            <Button className="submit-button" variant="contained" color="primary" onClick={onSubmit}>
              SIGN Up
            </Button>
            <Typography className="sign-up-cover" >
              <Link href="#" to={'/Login'} className="sign-up-link" >
                Login
           </Link>
            </Typography>
          </div>

        </form>
      </div>
    </div>
  );
}

export default SignUpComponent;
