import React, { Fragment, useEffect } from 'react';
import {
    MenuItem, FormControl, TextField, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip, AppBar, Toolbar, Typography, Button
} from '@material-ui/core';
import "../../../../assets/themes/default/share.scss";
import "./sub-header.scss";
import '../../../../app.config.scss';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SearchBar from 'material-ui-search-bar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const SubHeaderComponent = (props: SubHeaderProps) => {

    const [searchData, setSearchData] = React.useState<any>(props.searchKey);
    const [userStatus, setUserStatus] = React.useState('');
    const [selectedFont, setSelectedFont] = React.useState('Small');
    useEffect(() => {
        if (props.showFontSizeDDL) {
            let selectedFontSize = document.documentElement.style.getPropertyValue('--font-text-size');
            selectedFontSize === '20px' ? setSelectedFont('Large') : selectedFontSize === '16px' ? setSelectedFont('Medium') : setSelectedFont('Small')
        }
    }, [searchData]);
    useEffect(() => {
        if (searchData) {
            props.onSearchData(searchData);
        }
    }, [searchData]);

    const handleChangeSearch = (text: any) => {
        if (text !== undefined && text !== '') {
            setSearchData(text);
        }
    };
    useEffect(() => {
        if (userStatus) {
            props.filterUserStatus(userStatus);
        }
    }, [userStatus])
    const onChangeUserStatus = (status: any) => {
        setUserStatus(status);
    }
    const onChangeFontSize = (fontSize: any) => {
        setSelectedFont(fontSize);
        switch (fontSize) {
            case 'Large':
                return document.documentElement.style.setProperty('--font-text-size', '20px');
            case 'Medium':
                return document.documentElement.style.setProperty('--font-text-size', '16px');
            default:
                return document.documentElement.style.setProperty('--font-text-size', '12px');
        }

    }



    return (
        <div className="SubHeader">
            <Toolbar className='subHeaderContainer'>
                <div className="headDiv">
                    {props.isbackEnabled && <ArrowBackIcon className='arrowBack' onClick={props.onBackButtonClick} />}

                    <Typography className="sub-header-text" >
                        {props.headingText}
                    </Typography>
                </div>
                <div className="subheader-filter">
                    {props.showFontSizeDDL &&
                        <div className="user-filter">
                            <p className="statuslabel">Font Size: </p>
                            <div className="filter-ddl">
                                <select
                                    className="form-control"
                                    name=''
                                    value={selectedFont}
                                    onChange={(event) => onChangeFontSize(event.target.value)}
                                >
                                    <option key={0} value={"Small"} label={"Small"} style={{ fontSize: 12 }} ></option>
                                    <option key={1} value={"Medium"} label={"Medium"} style={{ fontSize: 16 }}></option>
                                    <option key={2} value={"Large"} label={"Large"} style={{ fontSize: 20 }}></option>

                                </select>
                            </div>
                        </div>
                    }
                    {props.showFilter &&
                        <div className="user-filter">
                            <p className="statuslabel">{props.filterHeading} </p>
                            <div className="filter-ddl">
                                <select
                                    className="form-control"
                                    name=''
                                    value={props.userStatus}
                                    onChange={(event) => onChangeUserStatus(event.target.value)}
                                >
                                    {props.filterArray.map((item: any, index: any) => (
                                        <option key={index} value={item} label={item}></option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                    }
                    {props.showSearch &&
                        <SearchBar
                            className={'search-bar'}
                            placeholder={`Search ${props.searchLabel}`}
                            value={props.searchKey}
                            onChange={handleChangeSearch}
                            onCancelSearch={() => props.onCancelSearch(true)}
                        />}
                    {props.showBtn3 &&
                        <Tooltip title={props.btnText3} placement="bottom" >
                            <button
                                className={`btn-primary ${props.blurBtn===false&&"compare"}`}
                                onClick={() => props.onButtonClick3(true)}
                            >{props.btnText3}</button>
                        </Tooltip >
                    }
                    {props.showBtn &&
                        <Tooltip title={props.btnText} placement="bottom" >
                            <button
                                className={"btn-primary"}
                                onClick={() => props.onButtonClick(true)}
                                disabled={props.blurBtn}
                            >{props.btnText}</button>
                        </Tooltip >
                    }
                    {props.showBtn2 &&
                        <Tooltip title={props.btnText2} placement="bottom" >
                            <button
                                className={"btn-primary"}
                                onClick={() => props.onButtonClick2(true)}
                                disabled={props.blurBtn}
                            >{props.btnText2}</button>
                        </Tooltip >
                    }

                    {props.fromFileList && <form
                        onSubmit={(e: any) => e.preventDefault()}
                    > <div className="file-section">
                            <span className="file-name">{props.fileDetails}</span>
                            <input
                                type="file"
                                className={"hidefile"}
                                onChange={props.onFileChange(true)}
                                id="icon-button-file"
                            />
                            <label htmlFor="icon-button-file">
                                <Tooltip title="Choose File" placement="bottom" key={"choosefile1"}>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        size="large"

                                        className={"btn-primary"}
                                    >
                                        <span className="labelspan">Choose File</span>
                                        <InsertDriveFileIcon />
                                    </Button>
                                </Tooltip>
                            </label>
                        </div>
                    </form>}
                </div>
            </Toolbar>

        </div>
    );

}

export default SubHeaderComponent;
interface SubHeaderProps {
    headingText: string;
    btnText?: string;
    searchLabel?: string;
    showBtn?: boolean;
    showSearch?: boolean;
    onButtonClick?: any;
    fromFileList?: boolean;
    fileDetails?: any;
    onFileChange?: any;
    onSearchData?: any;
    onCancelSearch?: any;
    searchKey?: any;
    userStatus?: any;
    filterUserStatus?: any;
    fontSizes?: any;
    showFilter: boolean;
    showFontSizeDDL: boolean;
    filterHeading?: string;
    filterArray?: any;
    btnText2?: string;
    showBtn2?: boolean;
    onButtonClick2?: (arg: any) => void;
    btnText3?: string;
    showBtn3?: boolean;
    onButtonClick3?: (arg: any) => void;
    isbackEnabled?: boolean;
    onBackButtonClick?: () => void;
    blurBtn?:boolean
}
SubHeaderComponent.defaultProps = {
    showFilter: false,
    showFontSizeDDL: false,
    showBtn2: false,
    showBtn3: false,
    isbackEnabled: false,
    blurBtn:false
}