import React, { useEffect } from 'react';
import { AppBar, Toolbar, IconButton, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import './header.scss';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { AuthService } from '../../../core/interceptors/auth.interceptor';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { logoutUser, changePassword } from '../../../redux/actions/authActions';
import ChangePassword from '../../../pages/security/ChangePassword';
import { ApiEndPoints } from '../../../models/api-endpoint';
import RestApiService from "../../../services/http-services";
import { toggleLoader } from '../../../redux/actions/loader.action';
import Toaster from '../../../services/toaster.services';
import { LockOpen, VpnKey, Home, DynamicFeed } from '@material-ui/icons';
import { CookieStorage } from 'cookie-storage';
import MenuIcon from '@material-ui/icons/Menu';
const cookies = new CookieStorage();


const HeaderComponent = (props: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const authService = new AuthService();
    const [errors, setErrors] = React.useState({});
    const [showResetPassword, setShowResetPassword] = React.useState<any>(false);
    const [user, setUser] = React.useState<any>();

    useEffect(() => {
        setUser(props.auth.user)
    }, [props.auth]);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onHome = () => {
        props.history.push('/landing');
    }

    const onLogout = () => {
        let theme = cookies.getItem('theme');
        // authService.logout();
        props.logoutUser();
        //  document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        //  cookies.removeItem("firstname");
        //.. cookies.removeItem("lastname");
        cookies.setItem("theme", theme);
        // props.history.push({
        //     pathname: `/login`
        // });
        // props.history.push('/login');
        props.history.push('/landing');
        // cookies.removeItem('access_token');
        // cookies.clear();
    }
    useEffect(() => {
        onFontSize();
    })

    const onSubmit = (event) => {
        toggleLoader(true);
        props.changePassword(false);
        RestApiService.invoke(ApiEndPoints.RESET_PASSWORD, null, event, null, {
            ETId: 10,
            SV: 1,
        }).then(res => {
            toggleLoader(false);
            props.changePassword(true);
            Toaster.successToaster("passwordChange", "Password Changed Successfully");
            setShowResetPassword(false);
        }).catch(error => {
            props.changePassword(false);
            toggleLoader(false);
            console.log('Get error', error);
            setErrors(error);
            Toaster.errorToaster(error);
        });
    }
    const onFontSize = () => {
        let fontSide = cookies.getItem('fontSize');

        switch (fontSide) {
            case 'Large':
                document.documentElement.style.setProperty('--h3-font-size', '19px');
                return document.documentElement.style.setProperty('--font-text-size', '18px');
            case 'Medium':
                document.documentElement.style.setProperty('--h3-font-size', '17px');
                return document.documentElement.style.setProperty('--font-text-size', '15px');
            default:
                document.documentElement.style.setProperty('--h3-font-size', '15px');
                return document.documentElement.style.setProperty('--font-text-size', '12px');
        }

    }
    return (

        <div className="header-page">
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start"
                        className="menuButton"
                        color="inherit" aria-label="menu"
                        onClick={() => props.onClick(!props.openMenu)}>
                        <MenuIcon />
                    </IconButton>

                    <span className="header-logo">
                        <img src={require('../../../../assets/images/Walacor_Logo2.png')} alt="The Platform" title={"The Platform"} />
                    </span>
                    <Typography variant="h6" className="title">
                        The Platform
                    </Typography>
                    <Tooltip title="Profile" placement="bottom">
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleMenu}
                        >
                            <span className="username"><div>{cookies.getItem('usershortname')}</div></span>
                            {/* <AccountCircle /> */}
                        </IconButton>

                    </Tooltip>

                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className={"logoutmenu"}
                    >
                        <MenuItem className={"logoutmenuitem"} onClick={() => onHome()}>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </MenuItem>
                        <MenuItem className={"logoutmenuitem"}>
                            <ListItemIcon>
                                <AccountCircle />
                            </ListItemIcon>
                            <ListItemText primary={cookies.getItem('firstname') + " " + cookies.getItem('lastname')} /></MenuItem>
                        <MenuItem onClick={() => onLogout()} className={"logoutmenuitem"}>
                            <ListItemIcon>
                                <LockOpen />
                            </ListItemIcon>
                            <ListItemText primary="Logout" /></MenuItem>
                        <MenuItem onClick={() => setShowResetPassword(true)} className={"logoutmenuitem"}><ListItemIcon>
                            <VpnKey />
                        </ListItemIcon>
                            <ListItemText primary="Change Password" /></MenuItem>
                        <MenuItem className={"logoutmenuitem"}>
                            <ListItemIcon>
                                <DynamicFeed />
                            </ListItemIcon>
                            <ListItemText primary="Release: Version 2.0" />
                        </MenuItem>
                    </Menu>

                </Toolbar>
                {
                    showResetPassword &&
                    <ChangePassword
                        show={showResetPassword}
                        sendData={(resp: any) => onSubmit(resp)}
                        hide={() => setShowResetPassword(false)} />
                }
            </AppBar>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    isShowSideBar: state.appSidebarReducer
});
export default connect(mapStateToProps, { logoutUser, changePassword })(withRouter(HeaderComponent));