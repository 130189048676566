export const filterParams = (filter: any): Object => {
  Object.keys(filter).forEach(element => {
    if (filter[element] === '' || filter[element] === null || filter[element] === undefined) {
      delete filter[element];
    }
  });
  return { ...filter };
}

export const changeUTCTimeToMilliSecond = (date: Date) => {
  return date.getTime() + date.getTimezoneOffset() * 60000;
}

export const changeUTCMillisecondToDate = (date: number) => {
  const utcTime = new Date(date);
  return new Date(utcTime.getTime() + (utcTime.getTimezoneOffset() * -1) * 60000);
}

export const changeEpochToDate = (date: number) => {
  if (Math.floor(date).toString().length === 10) {
    return new Date(date * 1000);
  }

  if (Math.floor(date).toString().length === 13) {
    return new Date(date);
  }
 else{
  return date;
 }
   
}