import React, { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getEnvelopeList } from '../../redux/actions/envelopeActions';
import { Button, Tooltip, Dialog, DialogContent, DialogTitle, IconButton, Typography, TableContainer, Table, TableBody, TableRow, TableCell, DialogActions, Grid } from '@material-ui/core';
import "../../../assets/themes/default/share.scss";
import "./envelopeList.scss";
import DynamicList from '../../shared/components/dynamic-list/dynamic-list';
import Toaster from '../../services/toaster.services';
import { toggleLoader } from '../../redux/actions/loader.action';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { ApiEndPoints } from '../../models/api-endpoint';
import RestApiService from '../../services/http-services';
import { Visibility } from '@material-ui/icons';
import MultiSelect from 'react-select';
import SubHeaderComponent from '../../shared/components/sub-header/sub-header';
import * as _ from 'lodash';



const EnvelopeListComponent: React.FC<Props> = (props: Props) => {
  const [envelopeData, setEnvelopeData] = React.useState(false);
  const [activeEncryptedHash, setActiveEncryptedHash] = React.useState<any>();
  const [activeDcryptedHash, setActiveDcryptedHash] = React.useState<any>();
  const [activeHashDH, setActiveHashDH] = React.useState<any>();
  const [showDecrypt, setShowDecrypt] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [direction, setDirection] = React.useState('desc');
  const [sortedBy, setSortedBy] = React.useState('UpdatedAt');
  const [search, setSearch] = React.useState<any>();
  const [selectedEnvelope, setSelectedEnvelope] = React.useState<any>();
  const [etIdsList, setEtidsList] = React.useState<any>();
  const [dvList, setDvList] = React.useState<any>();
  const [etiSelected, setEtiSelect] = React.useState<any>();
  const [divSelected, setDvSelect] = React.useState<any>();
  const [totalReq, setTotalReq] = React.useState(true);
  const [eId, setEId] = React.useState<any>();
  const [eS, setES] = React.useState<any>();
  const [envelopeHashData, setEnvelopeHashData] = React.useState<any>();
  const [orderIndexedFields, setOrderIndexedFields] = React.useState([{ 'orderBy': 'EId', 'order': 'desc', 'isActive': false }, { 'orderBy': 'ETId', 'order': 'desc', 'isActive': false }, { 'orderBy': 'CreatedAt', 'order': 'desc', 'isActive': false }, { 'orderBy': 'UpdatedAt', 'order': 'desc', 'isActive': false }])

  React.useEffect(() => {
    getEtidsList({});
    //  getDvByEtidsHandle({}, false);
  }, []);


  React.useEffect(() => {

    if (pageSize && currentPageNo && direction && sortedBy) {
      getEnvelopeList({});
    }

  }, [pageSize, currentPageNo, direction, sortedBy]);


  const getEnvelopeList = (query) => {

    props.toggleLoader(true);
    let baseQuery = "/ui/api/envelopes?pageNo=" + currentPageNo + "&pageSize=" + pageSize + "&totalReq=" + totalReq + "&order=" + direction + "&orderBy=" + sortedBy;
    let firstQuery = ""; let secondQuery = "";
    if (etiSelected && etiSelected.length > 0) {
      let etiQuery = "&etid=" + etiSelected.join("&etid=");
      firstQuery = etiQuery;
    }
    if (divSelected && divSelected.length > 0) {
      let dvQuery = "&sv=" + divSelected.join("&sv=");
      secondQuery = dvQuery;
    }

    RestApiService.getRequest(baseQuery + firstQuery + secondQuery, query, {
      ETId: 1000,
      SV: 1
    }).then((res: any) => {
      props.toggleLoader(false);
      setEnvelopeData(res.data.data);
      setTotalRecord(res.data.totalRecord);
      if (parseInt(res.data.totalRecord) % pageSize === 0) {
        setTotalPage((parseInt(res.data.totalRecord) / pageSize));
      }
      else {
        setTotalPage((Math.floor(parseInt(res.data.totalRecord) / pageSize)) + 1);
      }
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const openHashesModel = (DH: any, decryptType?: any) => {
    setActiveHashDH(DH);
    let query;
    if (decryptType === 'EID') {
      query = { DH: DH, EId: eId };
    }
    else {
      query = { DH: DH };
    }
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.VIEW_DATAHASH, query, null, null, {
      ETId: 1000,
      SV: 1
    }).then((res: any) => {
      props.toggleLoader(false);
      if (res.data.success) {
        setActiveEncryptedHash(res.data.data);
        setOpen1(true);
      }
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });

  }


  const dcryptHash = (DataHash: any, decryptType?: any) => {
    let query;
    if (decryptType === 'EID') {
      query = { DH: DataHash, EId: eId };
    }
    else {
      query = { DH: DataHash };
    }
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.DECRYPT_DATAHASH, query, null, null, {
      ETId: 1000,
      SV: 1

    }).then((res: any) => {
      props.toggleLoader(false);
      if (res.data.success) {
        setActiveDcryptedHash(res.data.data);
        setShowDecrypt(true);
      }
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }



  const onSearchData = (searchData: any) => {
    setSearch(searchData);
    // props.toggleLoader(true);
    // props.getFileList({search:search,
    //   pageno:currentPageNo,
    //   pagesize:pageSize,
    //   order:direction,
    //   orderBy:sortedBy});
    //   getEnvelopeList({ search: searchData });
    // props.toggleLoader(false);
  }
  const onSortingData = (order, orderBy, data) => {

    if (orderBy && order && data) {
      setSortedBy(orderBy);
      setDirection(order);
      setOrderIndexedFields(data);
    }

  }
  const handleAction = (item, action) => {
    if (action == 'view') {
      setSelectedEnvelope(item);
      setEId(item.EId);
      setES(item.ES);
      setOpen(true);
    }
  }

  const goToPreviousPage = (page: any) => {
    setCurrentPageNo(page);
  }

  const goToNextPage = (page: any) => {
    setCurrentPageNo(page);
  }

  const isLessthanTotalElement = () => {
    if ((currentPageNo * pageSize) === (totalPage - 1))
      return true;
    else
      return false;
  }

  const changePageSize = (value: any) => {
    setPageSize(value);
    setCurrentPageNo(1);
  }

  const changeDirection = (value: any) => {
    // setDirection(value);
    // toggleLoader(true);
    // props.getFileList({
    //   search: search,
    //   pageno: currentPageNo,
    //   pagesize: pageSize,
    //   order: direction,
    //   orderBy: sortedBy
    // });
    // toggleLoader(false);
  }


  const handleClose = (text) => {
    if (text === 'env') {
      setOpen(false);
    }
    else {
      setOpen1(false);
      setShowDecrypt(false);
    }
  };

  const getEtidsList = (query) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_ETIDs, null, null, query, {
      ETId: 50,
      SV: 1
    }).then((res: any) => {
      props.toggleLoader(false);
      res.data.data.unshift({ ETId: 50, TableName: "schemas" });
      let etidListValue = [];
      res.data.data.map((item: any, index: any) => {
        etidListValue.push({ value: String(item.ETId), label: `${item.TableName} (${item.ETId})` })
      });
      setEtidsList(etidListValue);
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const getDvByEtidsHandle = (etids: any, isEtid: boolean) => {
    setCurrentPageNo(1);
    let query = "";
    if (isEtid == true) {
      let queryData = [];
      etids.map((item: any, index: any) => {
        queryData.push(String(item.value));
      });
      setEtiSelect(queryData);
      query = "?ETIds=" + queryData.join("&ETIds=");
    }
    else {
      setEtiSelect(null);
    }
    RestApiService.invoke(ApiEndPoints.GET_VERSIONS, { queryString: query }, null, null, null).then((res: any) => {
      let dvListValue = [];
      let objArray = []
      res.data.data.forEach((item: any, index: any) => {
        item.versions.map((vers: any, index: any) => {
          objArray.push({ value: String(vers), label: String(vers) })
          dvListValue = _.uniqWith(objArray, _.isEqual);
        });
      });
      setDvList(dvListValue);
    }).catch(error => {
      console.log('Get error', error.response);
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((response) => {
          Toaster.errorToaster(response.message);
        });
      }
    });
  }

  const getDvValueHandler = (dvs: any) => {
    setCurrentPageNo(1);
    if (dvs) {
      let queryData = [];
      dvs.map((item: any, index: any) => {
        queryData.push(String(item.label));
      });
      setDvSelect(queryData);
    } else {
      setDvSelect(null);
    }

  }

  const getFilter = () => {
    setCurrentPageNo(1);
    getEnvelopeList({});
  }

  return (
    <div className="Innerheading">
      <SubHeaderComponent
        headingText={'Envelope List'}
        showBtn={false}
        onSearchData={(searchData: any) => onSearchData(searchData)} />
      <div className="Innerdetails">
        <div className="envelope-filter">
          <Grid container spacing={3} className={"filter"}>
            <Grid item xs={2} md={1}>
              <label>ETId</label>
            </Grid>
            <Grid item xs={10} md={4}>
              <MultiSelect isMulti className="basic-multi-select" classNamePrefix="select" options={etIdsList} onChange={(e) => getDvByEtidsHandle(e, e != null)} />
            </Grid>
            <Grid item xs={2} md={1}>
              <label>SV</label>
            </Grid>
            <Grid item xs={10} md={4}>
              <MultiSelect isMulti className="basic-multi-select" classNamePrefix="select" options={dvList} isClearable={true} onChange={(e) => getDvValueHandler(e)} />
            </Grid>
            <Grid item xs={12} md={2} className="filter-section">
              <Button variant="contained" color="primary" size="small" className="btn-filter" onClick={() => getFilter()}>
                Filter
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="grid">
          <DynamicList
            dataList={envelopeData}
            columnList={[{ name: "Envelope Id", db: "EId", sort: true }, { name: "ETId", db: "ETId", sort: true }, { name: "SV", db: "SV" }, { name: "Envelope Status", db: "ES" }, { name: "Created At", db: "CreatedAt", sort: true }, { name: "Updated At", db: "UpdatedAt", sort: true }]}
            primaryColumn={"_id"}
            orderIndexedFields={orderIndexedFields}
            onSortingData={(order: string, orderBy: any, data: any) => onSortingData(order, orderBy, data)}
            // totalCount={100} //required from api
            handleAction={(item: any, action: string) => handleAction(item, action)}
            showEdit={false}
            showDelete={false}
            showView={true}
            showActions={true}
            keysToHide={['']}
            fromComponent={'EnvelopeListComponent'}
            goToPreviousPage={(page: any) => goToPreviousPage(page)}
            goToNextPage={(page: any) => goToNextPage(page)}
            changePageSize={(page: any) => changePageSize(page)}
            pageSize={pageSize}
            currentPageNo={currentPageNo}
            totalPage={totalPage}
            totalRecord={totalRecord}
            isLessthanTotalElement={isLessthanTotalElement()}
            SNo={false}
          />
        </div>
      </div>

      {selectedEnvelope && <Dialog onClose={() => handleClose('env')} aria-labelledby="customized-dialog-title" open={open} className="modalpopoup" fullWidth={true} maxWidth={"md"}>
        <DialogTitle disableTypography >
          <Typography variant="h6">Envelope Detail</Typography>
          <IconButton aria-label="close" onClick={() => handleClose('env')}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers >
          <Typography gutterBottom>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><strong>ES</strong></TableCell>
                    <TableCell>{selectedEnvelope.ES != null ? selectedEnvelope.ES : ''}</TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Envelope ID</strong></TableCell>
                    <TableCell>{selectedEnvelope.EId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>ETId</strong></TableCell>
                    <TableCell>{selectedEnvelope.ETId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>TS</strong></TableCell>
                    <TableCell>{moment.utc(selectedEnvelope.TS).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>SV</strong></TableCell>
                    <TableCell>{selectedEnvelope.SV}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>DH</strong></TableCell>
                    {selectedEnvelope.ES > 40 && <TableCell>{selectedEnvelope.DH} <IconButton onClick={() => openHashesModel(selectedEnvelope.DH)}><Visibility /></IconButton></TableCell>}
                    {selectedEnvelope.ES <= 10 && <TableCell> {JSON.stringify(selectedEnvelope.Data, undefined, 4)}</TableCell>}
                    {selectedEnvelope.ES > 10 && selectedEnvelope.ES <= 40 && <TableCell>{selectedEnvelope.DH} <IconButton onClick={() => openHashesModel(selectedEnvelope.DH, 'EID')}><Visibility /></IconButton></TableCell>}
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Created At</strong></TableCell>
                    <TableCell>{moment.utc(selectedEnvelope.CreatedAt).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Updated At</strong></TableCell>
                    <TableCell>{moment.utc(selectedEnvelope.UpdatedAt).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Data</strong></TableCell>
                    <TableCell>
                      {
                        selectedEnvelope.ES > 40 && selectedEnvelope.Data &&
                        <table className="statusinfo">
                          <tbody>
                            {
                              selectedEnvelope.Data.map((item: any) => {
                                return (
                                  <React.Fragment key={item._id}>
                                    <TableRow>
                                      <TableCell><strong>Status Info</strong></TableCell>
                                      <TableCell>{(item.StatusInfo && item.StatusInfo.label) ? item.StatusInfo.label : ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell><strong>UID</strong></TableCell>
                                      <TableCell>{item.UID}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell><strong>Deleted</strong></TableCell>
                                      <TableCell>{item.Deleted}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell><strong>Blockchain Info</strong></TableCell>
                                      <TableCell>{(item.BlockChainInfo && item.BlockChainInfo.label) ? item.BlockChainInfo.label : ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell><strong>Blockchain Entity</strong></TableCell>
                                      <TableCell>{item.BlockChainEntity}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell><strong>Item Type</strong></TableCell>
                                      <TableCell>{item.ItemType}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell><strong>Item</strong></TableCell>
                                      <TableCell>{item.Item}</TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>

        </DialogContent>
      </Dialog>
      }

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open1} className="modalpopoup">
        <DialogTitle disableTypography>
          <h3>{activeHashDH}</h3>
          <IconButton aria-label="close" onClick={() => handleClose('secure')}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <strong>Encrypted DataHash</strong>
            <div className="activeEncryptedHash">{activeEncryptedHash}</div>
          </Typography>
          {showDecrypt && <Typography gutterBottom>
            <strong>Decrypted DataHash</strong>
            <div className="decrypted-text">
              {JSON.stringify(activeDcryptedHash, undefined, 4)}
            </div>
          </Typography>}
        </DialogContent>
        {!showDecrypt && eS > 40 && <DialogActions>
          <button type="button" className="btn btn-primary" onClick={() => dcryptHash(activeHashDH)} color="primary">
            Decrypt
          </button>
        </DialogActions>}
        {!showDecrypt && eS <= 40 && <DialogActions>
          <button type="button" className="btn btn-primary" onClick={() => dcryptHash(activeHashDH, 'EID')} color="primary">
            Decrypt
          </button>
        </DialogActions>}
      </Dialog>






    </div>
  );
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader, getEnvelopeList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnvelopeListComponent);


interface Props {
  toggleLoader: any;
}