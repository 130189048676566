import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import "../../../assets/themes/default/share.scss";
import "./List.scss";
import { toggleLoader } from '../../redux/actions/loader.action';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, Box, AppBar } from '@material-ui/core';
import FileListComponent from '../file/fileList';
import SecureDataListComponent from '../securedata/secureDataList';
import EnvelopeListComponent from '../envelope/envelopeList';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function tabIndexProps(index: any) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const PageListComponent: React.FC<Props> = (props: Props) => {
    const [value, setValue] = React.useState("Envelope");

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };

    return (
        <div className="Container">
            <Paper square className={"paging-tab"}>
                <Tabs value={value} onChange={handleChange} aria-label="Envelope" className="tab-color" centered >
                    <Tab
                        value="Envelope"
                        label="Envelope"
                        wrapped
                        {...tabIndexProps('Envelope')}
                    />
                    {/* <Tab value="SecureData" label="Secure Data" {...tabIndexProps('SecureData')} /> */}
                    <Tab value="File" label="File" {...tabIndexProps('File')} />
                </Tabs>
            </Paper>
            <TabPanel value={value} index="Envelope">
                <EnvelopeListComponent />
            </TabPanel>
            {/* <TabPanel value={value} index="SecureData">
                <SecureDataListComponent />
            </TabPanel> */}
            <TabPanel value={value} index="File">
                <FileListComponent />
            </TabPanel>
        </div>
    );
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageListComponent);

interface Props {

}