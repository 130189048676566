import axios from 'axios';
import { APIDef, APIInput } from '../models/api-endpoint';
import { AuthService } from '../core/interceptors/auth.interceptor';
import { JwtServices } from './jwt.services';
class RestApiService {
    public authSerivce = new AuthService();
    public jwtServices = new JwtServices();
    public blackListAPI = ['oauth/token', 'api/otps/registerUser', 'api/users', 'api/otps/resetPassword',
        'api/reset-password', 'api/specialities']
    public invoke<T>(def: APIDef, apiInput?: APIInput, data?: T, queryMap?: any, headerObject?: any) {
        if (this.blackListAPI.indexOf(def.api) === -1) {
            this.authSerivce.setAuthorizationToken(this.jwtServices.getJWTToken());
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }

        return this.invokeAPI(def.api(apiInput), def.method, data, queryMap, headerObject);
    }

    private invokeAPI<T>(api: string, method: string, body?: T, queryMap?: any, headerObject?: any) {
        const headers: any = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        if (headerObject) {
            headers.ETId = headerObject.ETId;
            headers.SV = headerObject.SV;
        }

        const httpOptions = { headers: headers, params: queryMap, observe: 'body' };
        switch (method) {
            case 'POST':
                return this.post<T>(api, body, httpOptions);
            case 'GET':
                return this.get<T>(api, httpOptions);
            case 'PUT':
                return this.put<T>(api, body, httpOptions);
            case 'DELETE':
                return this.delete<T>(api, httpOptions);
            default:
                break;
        }
    }


    private post<T>(apiUrl: any, body: any, httpOptions: any) {
        return axios({
            method: 'post',
            url: apiUrl,
            headers: httpOptions.headers,
            params: httpOptions.params,
            data: body
        }).catch(err => this.handleError<T>(err))
    }

    private get<T>(apiUrl: any, httpOptions: any) {
        return axios({
            method: 'get',
            url: apiUrl,
            params: httpOptions.params,
            headers: httpOptions.headers
        }).catch(err => this.handleError<T>(err))
    }

    private put<T>(apiUrl: any, body: any, httpOptions: any) {
        return axios({
            method: 'put',
            url: apiUrl,
            headers: httpOptions.headers,
            params: httpOptions.params,
            data: body
        }).catch(err => this.handleError<T>(err))
    }

    private delete<T>(apiUrl: any, httpOptions: any) {
        return axios({
            method: 'delete',
            url: apiUrl,
            headers: httpOptions.headers,
            params: httpOptions.params
        }).catch(err => this.handleError<T>(err))
    }

    private handleError<T>(error: any) {
        if (error && error.response && error.response.status && (error.response.status === 422 || error.response.status === 409 ||
            error.response.status === 403) && error.response.data && error.response.data.errorMessages.length > 0) {
            error.response.data.errors.forEach(message => {
                // this.toasterService.togglerToast(mesaage);
                console.log(message);
            });
        }
        return Promise.reject(error);
    }

    public getRequest<T>(url: any, params?: any, headerObject?: any) {
        const headers: any = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        if (headerObject) {
            headers.ETId = headerObject.ETId;
            headers.SV = headerObject.SV;
        }
        axios.defaults.headers.common['Authorization'] = this.jwtServices.getJWTToken();
        return axios({
            method: 'get',
            url: url,
            params: params,
            headers: headers
        }).catch(err => this.handleError<T>(err))

    }

    public postRequest<T>(url: any, params?: any, headerObject?: any, body?: any, token?: any) {
        const headers: any = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        if (headerObject && token) {
            headers['Authorization'] = token
        }

        if (headerObject) {
            headers.ETId = headerObject.ETId;
            headers.SV = headerObject.SV;
        }
        console.log(url, headers, params, body, "header")
        return axios({
            method: 'post',
            url: url,
            headers: headers,
            params: params,
            data: body
        }).catch(err => this.handleError<T>(err))

    }

    public fileSave<T>(url?: any, body?: any, headerObject?: any) {
        const headers: any = {
            'Content-Type': 'multipart/form-data',
            ...headerObject
        }
        axios.defaults.headers.common['Authorization'] = this.jwtServices.getJWTToken();
        return axios({
            method: 'post',
            url: url,
            headers: headers,
            data: body
        })

    }
}

export default new RestApiService();