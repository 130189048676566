
import * as XLSX from "xlsx";

class FileDownloadService {
    public arrayOfObjectToXLSXFileDownload(data: Array<any>, fileName: string = "default"): any {
        try {
            let ws = XLSX.utils.json_to_sheet(data);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "sheet");
            return XLSX.writeFile(wb, fileName);
        }
        catch (downloadXLSError: any) {
            return downloadXLSError
        }
    }
}



export default FileDownloadService