import React, { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Tooltip, Dialog, DialogContent, DialogTitle, IconButton, Typography, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import "../../../assets/themes/default/share.scss";
import "./fileList.scss";
import DynamicList from '../../shared/components/dynamic-list/dynamic-list';
import Toaster from '../../services/toaster.services';
import RestApiService from '../../services/http-services';
import { ApiEndPoints } from '../../models/api-endpoint';
import { toggleLoader } from '../../redux/actions/loader.action';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import appConstants from "../../core/constants/appConstants";
import SubHeaderComponent from '../../shared/components/sub-header/sub-header';


const FileListComponent: React.FC<Props> = (props: Props) => {
  const [file, setFile] = React.useState<any>();
  const [fileDetails, setFileDetails] = React.useState<any>();
  const [itemDetails, setItemDetails] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [direction, setDirection] = useState(-1);
  const [orderBy, setOrderBy] = useState("CreatedAt");
  const [search, setSearch] = React.useState<any>();
  const [orderIndexedFields, setOrderIndexedFields] = React.useState([{ 'orderBy': 'uploadDate', 'order': 'desc', 'isActive': false }])

  useEffect(() => {
    if (pageSize && currentPageNo && direction) {
      getFileList({ pageNo: currentPageNo, pageSize: pageSize, order: direction, orderBy: orderBy });
    }
  }, [pageSize, currentPageNo, direction, orderBy]);


  const getFileList = (query: object = {}) => {
    props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_FILES_LIST, null, null, query, {
      ETId: 10000,
      SV: appConstants.SCHEMA_DATA.SV,
    }).then(res => {
      props.toggleLoader(false);
      const updatedFile = res.data.data.map((item) => {
        const createdAt = moment(item && item.CreatedAt).format('YYYY-MM-DD HH:mm:ss').toLocaleString() || 'N/A';
        return { ...item, ...item.documentVersion, createdAt }
      })
      setFile(updatedFile);
      setTotalRecord(res.data.total);
      if (parseInt(res.data.total) % pageSize === 0) {
        setTotalPage((parseInt(res.data.total) / pageSize));
      }
      else {
        setTotalPage((Math.floor(parseInt(res.data.total) / pageSize)) + 1);
      }
    }).catch(error => {
      props.toggleLoader(false);
      console.log('Get error in file list', error);
      Toaster.errorToaster(error);
    })
  };

  const onFileChange = (file: any) => {
    if (file.target && file.target.files && file.target.files.length > 0) {
      setFileDetails(file.target.files[0].name);
      props.toggleLoader(true);
      let dataResult = new FormData();
      dataResult.append('file', file.target.files[0]);

      RestApiService.invoke(ApiEndPoints.UPLOAD_FILES, null, dataResult, { from: 'UPLOAD' }, {  // UPLOAD FILE WITH V2 API
        // ETId: 17,
        SV: appConstants.SCHEMA_DATA.SV,
      }).then(res => {
        props.toggleLoader(false);
        setFileDetails("");
        Toaster.successToaster("onFileChange", "Upload File Successful");
        getFileList({ pageNo: 1, pageSize: pageSize, order: direction, orderBy: orderBy });
      }).catch(error => {
        props.toggleLoader(false);
        console.log('Get error in file list', error);
        Toaster.errorToaster(error);
      })
    }
  }

  useEffect(() => {
    if (search) {
      getFileList({ pageNo: 1, search: search });
    }
  }, [search]);

  const onSearchData = (searchData: any) => {
    setSearch(searchData);

  }

  const onSortingData = (order: any, orderBy: any, data: any) => {
    setCurrentPageNo(1);
    setDirection(order);
    setOrderIndexedFields(data);
  }
  const handleAction = (item: any, action: any) => {
    if (action == 'view') {
      setItemDetails(item);
      handleOpen();
    }
  }

  const goToPreviousPage = (page: any) => {
    setCurrentPageNo(page);
  }

  const goToNextPage = (page: any) => {
    setCurrentPageNo(page);
  }

  const isLessthanTotalElement = () => {
    if ((currentPageNo * pageSize) === (totalPage - 1))
      return true;
    else
      return false;
  }

  const changePageSize = (value: any) => {
    setPageSize(value);
    setCurrentPageNo(1);

  }


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="Innerheading">
      <SubHeaderComponent
        headingText={'File List'}
        showBtn={false}
        fromFileList={false}
        fileDetails={fileDetails}
        onFileChange={(value: any) => onFileChange}
        onSearchData={(searchData: any) => onSearchData(searchData)} />
      <div className="Innerdetails">
        <div className="grid fileList">
          <DynamicList
            dataList={file}
            // columnList={[{ name: "Id", db: "_id" }, { name: "File Name", db: "filename" }, { name: "Content Type", db: "contentType" }, { name: "Upload Date", db: "uploadDate", sort: true }]}
            columnList={[{ name: "Id", db: "_id" }, { name: "File Name", db: "fileName" }, { name: "Content Type", db: "mimetype" }, { name: "Upload Date", db: "createdAt", sort: true }]}
            primaryColumn={"_id"}
            orderIndexedFields={orderIndexedFields}
            onSortingData={(order: string, orderBy: any, data: any) => onSortingData(order, orderBy, data)}
            // totalCount={100}
            handleAction={(item: any, action: string) => handleAction(item, action)}
            showEdit={false}
            showDelete={false}
            showView={true}
            showActions={false}
            keysToHide={['']}
            goToPreviousPage={(page: any) => goToPreviousPage(page)}
            goToNextPage={(page: any) => goToNextPage(page)}
            changePageSize={(page: any) => changePageSize(page)}
            pageSize={pageSize}
            currentPageNo={currentPageNo}
            totalPage={totalPage}
            totalRecord={totalRecord}
            isLessthanTotalElement={isLessthanTotalElement()}
            SNo={false}
          />
        </div>
      </div>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="modalpopoup">
        <DialogTitle disableTypography>
          <Typography variant="h6">View Detail</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow hover>
                  <TableCell >
                    <strong>Id</strong>
                  </TableCell>
                  <TableCell >
                    <span>{itemDetails && itemDetails._id}</span>
                  </TableCell>

                </TableRow>
                <TableRow hover>
                  <TableCell >
                    <strong>File Name</strong>
                  </TableCell>
                  <TableCell >
                    <span>{itemDetails && itemDetails.filename}</span>
                  </TableCell>

                </TableRow>
                <TableRow hover>
                  <TableCell >
                    <strong>Length</strong>
                  </TableCell>
                  <TableCell >
                    <span>{itemDetails && itemDetails.length}</span>
                  </TableCell>

                </TableRow>
                <TableRow hover>
                  <TableCell >
                    <strong>Md5</strong>
                  </TableCell>
                  <TableCell >
                    <span>{itemDetails && itemDetails.md5}</span>
                  </TableCell>

                </TableRow>
                <TableRow hover>
                  <TableCell >
                    <strong>Update date </strong>
                  </TableCell>
                  <TableCell >
                    <span>{moment(itemDetails && itemDetails.uploadDate).format('YYYY-MM-DD HH:mm:ss').toLocaleString() || 'N/A'}</span>
                  </TableCell>

                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>

        </DialogContent>

      </Dialog>

    </div>
  );
}

const mapStateToProps = (state: any) => ({
  // file: state.file,
});

const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FileListComponent);


interface Props {
  //getFileList: any;
  //uploadFile: any;
  //deleteFileByFileName: any;
  //getFileByFileName: any;
  //file: any;
  toggleLoader: any;
}