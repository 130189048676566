import React, { Component, Fragment } from 'react';
import './dashboard.scss';
import { Card, CardHeader, CardContent, IconButton, Grid, Table, TableHead, TableRow, Paper, TableBody, TableCell, TableContainer, CardActions, Button, Dialog, DialogTitle, DialogContent, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PieChart, BarChart, TableChart, Tv, Print, Visibility, Close } from '@material-ui/icons';
import ReactEcharts from "echarts-for-react";
import { Chart } from "react-google-charts";
import { toggleSideBar } from '../../redux/actions/sidebarAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RestApiService from '../../services/http-services';
import { ApiEndPoints } from '../../models/api-endpoint';
import Toaster from '../../services/toaster.services';
import { toggleLoader } from '../../redux/actions/loader.action';
import LinearProgress from '@material-ui/core/LinearProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Typography, Box, AppBar, Tabs, Tab } from '@material-ui/core';
import _ from 'underscore';
import PagingComponent from '../../shared/components/paging/paging.component';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import SmallLoader from '../../shared/components/loader/small-loader';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SubHeaderComponent from '../../shared/components/sub-header/sub-header';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function tabIndexProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

class DashboardComponent extends Component<any, any> {

  timer;
  intervalRate: any = 60;
  public state: any = {
    schemaBarChartData: [],
    schemaTableData: [],
    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    endDate: new Date(),
    envelopeBarChartData: [],
    envelopeData: [],
    envelopePieChartData: [],
    assistedAuditData: [],
    envelopeTableData: [],
    schemaPieChartData: [],
    schemaCreatedTableData: [],
    schemaUpdatedTableData: [],
    schemaDetailsTableData: [],
    buttonArray: ['All', 'Good', 'Pending', 'Bad'],
    statusArray: ['Past Hour', 'Past Day', 'Past Week', 'Past Month', 'Past Year'],
    ratesArray: [0, 60, 120, 600],
    isEnvelopeFullscreenEnabled: false,
    //envelopeStartDate: new Date(new Date().setUTCFullYear(new Date().getUTCFullYear() - 1)),
    envelopeStartDate: new Date(new Date().setUTCDate(new Date().getUTCDate() - 7)),
    envelopeEndDate: new Date(),
    schemaDetailsStartDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    schemaDetailsEndDate: new Date(),
    value: "EnvelopeHealth",
    pageSize: 10,
    currentPageNo: 1,
    totalPage: 1,
    totalRecord: 0,
    totalCreatedSchemas: 0,
    totalUpdatedSchemas: 0,
    healthQueryParam: '',
    envelopeDetail: {},
    statusText: 'Past Week',//'Past Year',
    healthStatusText: '',
    envelopeIndex: null,
    refreshLoading: false,
    defaultRate: 60,
    orderIndexedFields: [{ 'orderBy': 'Family', 'order': 'desc', 'isActive': false }, { 'orderBy': 'TableName', 'order': 'desc', 'isActive': false }, { 'orderBy': 'History', 'order': 'desc', 'isActive': false }, { 'orderBy': 'Summary', 'order': 'desc', 'isActive': false }, { 'orderBy': 'Inserted', 'order': 'desc', 'isActive': false }, { 'orderBy': 'Updated', 'order': 'desc', 'isActive': false }],

  }

  constructor(props: any) {
    super(props);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSchemaDetailsStartDateChange = this.handleSchemaDetailsStartDateChange.bind(this);
    this.handleSchemaDetailsEndDateChange = this.handleSchemaDetailsEndDateChange.bind(this);
    this.handleEnvelopeStartDateChange = this.handleEnvelopeStartDateChange.bind(this);
    this.handleEnvelopeEndDateChange = this.handleEnvelopeEndDateChange.bind(this);
    this.handleEnvelopeChartChange = this.handleEnvelopeChartChange.bind(this);
    this.goToPreviousPage = this.goToPreviousPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.isLessthanTotalElement = this.isLessthanTotalElement.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
    this.filterHealthCards = this.filterHealthCards.bind(this)
    this.filterStatus = this.filterStatus.bind(this)
    this.getEnvelopeData = this.getEnvelopeData.bind(this)
    this.filterRefresh = this.filterRefresh.bind(this)
    this.onSortingData = this.onSortingData.bind(this)
    this.getAssistedAudit = this.getAssistedAudit.bind(this)
  }



  getDateFormat(dateData: string) {
    if (dateData) {
      var currentDt = new Date(dateData);
      var mm = currentDt.getMonth() + 1;
      var dd = currentDt.getDate();
      var yyyy = currentDt.getFullYear();
      var date = yyyy + "/" + mm + "/" + dd;
      return date;
    } else return "";
  }

  getBarChartData() {
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_SCHEMAS_DETAILS_FOR_TABLE, null, null, { IsBarChart: true, startDate: this.state.schemaDetailsStartDate, endDate: this.state.schemaDetailsEndDate }, { ETId: 50, SV: 1 }).then(res => {
      this.props.toggleLoader(false);
      let dataFilter = [["Schema", "Summary Count", "History Count", "Inserted Records", "Updated Records"]];
      for (let row in res.data.data) {
        dataFilter.push([`${res.data.data[row].schema}`, res.data.data[row].summary, res.data.data[row].history, res.data.data[row].inserted, res.data.data[row].updated]);
      }
      console.log('bardata', dataFilter);
      this.setState({ schemaBarChartData: dataFilter });
    }).catch(error => {
      this.props.toggleLoader(false);
      Toaster.errorToaster(error);
    })


  }

  getSchemaPieChartData() {
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_SCHEMAS_LIST_FOR_PIE_CHART, null, null, { startDate: this.state.startDate, endDate: this.state.endDate }, { ETId: 50, SV: 1 }).then(res => {
      this.props.toggleLoader(false);
      this.setState({ totalCreatedSchemas: res.data[0].totalCreatedAt });
      this.setState({ totalUpdatedSchemas: res.data[0].totalUpdated });
      let dataFilterPie = [["DateType", "Value"]];
      dataFilterPie.push(["New Added Records", res.data[0].totalCreatedAt]);
      dataFilterPie.push(["Updated Records", res.data[0].totalUpdated]);
      //console.log("pie chart :", dataFilterPie);
      this.setState({ schemaPieChartData: dataFilterPie });
      this.setState({ schemaCreatedTableData: res.data[0].createdData });
      this.setState({ schemaUpdatedTableData: res.data[0].updatedData });
    }).catch(error => {
      this.props.toggleLoader(false);
      Toaster.errorToaster(error);
    })
  }

  getSchemaDetailsData(orderBy?: any, order?: any) {
    this.setState({ schemaDetailsTableData: [] });
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_SCHEMAS_DETAILS_FOR_TABLE, null, null, { orderBy: orderBy, order: order, pageNo: this.state.currentPageNo, pageSize: this.state.pageSize, IsBarChart: false, startDate: this.state.schemaDetailsStartDate, endDate: this.state.schemaDetailsEndDate }, { ETId: 50, SV: 1 }).then(res => {
      this.props.toggleLoader(false);
      this.setState({ schemaDetailsTableData: res.data.data });
      this.setState({ totalRecord: res.data.total })
      if (parseInt(res.data.total) % this.state.pageSize === 0) {
        this.setState({ totalPage: (parseInt(res.data.total) / this.state.pageSize) });
      }
      else {
        this.setState({ totalPage: ((Math.floor(parseInt(res.data.total) / this.state.pageSize)) + 1) });
      }
    }).catch(error => {
      this.props.toggleLoader(false);
      Toaster.errorToaster(error);
    })
  }

  getEnvelopeData(showLoader: boolean) {
    if (showLoader) {
      this.props.toggleLoader(true);
    }
    else {
      this.props.toggleLoader(false);
      this.setState({ refreshLoading: true });
    }
    var body;
    if (this.state.healthQueryParam !== '') {
      body = { startDate: this.state.envelopeStartDate, endDate: this.state.envelopeEndDate, health: this.state.healthQueryParam }
    }
    else {
      body = { startDate: this.state.envelopeStartDate, endDate: this.state.envelopeEndDate };
    }


    RestApiService.invoke(ApiEndPoints.GET_DATA_FOR_ENVELOPE_CHART, null, body, null, null).then(res => {
      if (showLoader) {
        this.props.toggleLoader(false);
        this.autoRefreshFunction();
      } else {
        this.setState({ refreshLoading: false })
      }
      // let dataFilter = [["ETId", "Total", "Submitted", "BC Stored", "Datastore", "External BlockChain"]];
      // let dataFilterPie = [["StatusType", "Value"]];
      // for (let row in res.data.data) {
      //   dataFilter.push([String(res.data.data[row].ETId), res.data.data[row].Total, res.data.data[row].Submitted, res.data.data[row]["BC Stored"], res.data.data[row].Datastore, res.data.data[row]["External BlockChain"]]);
      // }
      // const Submitted = res.data.data.reduce((sum, currentValue) => {
      //   return sum + parseFloat(currentValue.Submitted);
      // }, 0);
      // const BCStored = res.data.data.reduce((sum, currentValue) => {
      //   return sum + parseFloat(currentValue["BC Stored"]);
      // }, 0);
      // const Datastore = res.data.data.reduce((sum, currentValue) => {
      //   return sum + parseFloat(currentValue.Datastore);
      // }, 0);

      // const ExternalBlockChain = res.data.data.reduce((sum, currentValue) => {
      //   return sum + parseFloat(currentValue["External BlockChain"]);
      // }, 0);

      // dataFilterPie.push(["Submitted", Submitted]);
      // dataFilterPie.push(["BC Stored", BCStored]);
      // dataFilterPie.push(["Datastore", Datastore]);
      // dataFilterPie.push(["External BlockChain", ExternalBlockChain]);
      // this.setState({envelopeBarChartData: dataFilter, envelopePieChartData: dataFilterPie, envelopeTableData: _.sortBy(res.data.data, 'ETId')})

      this.setState({ envelopeData: _.sortBy(res.data.data, 'Health') }, () => {
        if (this.state.envelopeIndex !== null) {
          this.viewEnvelopeHealthCardDetails(this.state.envelopeData[this.state.envelopeIndex], this.state.envelopeIndex)
        }
      });
    }).catch(error => {
      this.props.toggleLoader(false);
      console.log('Get error in dashboard component', error);
      Toaster.errorToaster(error);
    })
  }
  getAssistedAudit() {
    this.props.toggleLoader(true);
    RestApiService.invoke(ApiEndPoints.GET_ASSISTED_AUDIT_PIE_CHART, null, null, null, { ETId: 50, SV: 1 }).then(res => {
      this.props.toggleLoader(false);
      let dataFilterPie = [["DateType", "Value"]];
      dataFilterPie.push(["Pass", res.data.data[0].chainHistoryStatusComplete]);
      dataFilterPie.push(["Fail", res.data.data[0].chainHistoryStatusNotComplete]);
      this.setState({ assistedAuditData: dataFilterPie });
    }).catch(error => {
      this.props.toggleLoader(false);
      Toaster.errorToaster(error);
    })

  }

  refreshEnvelopeHealthData() {
    this.getEnvelopeData(false);
  }

  closeCardDetails(item, index) {
    if (this.state.envelopeIndex === index) {
      this.setState({ envelopeIndex: null });
    }
  }

  componentDidMount() {
    this.viewEnvelopeGraph();
  }

  autoRefreshFunction() {
    if (this.intervalRate === 0) {
      clearInterval(this.timer);
    }
    else {
      clearInterval(this.timer);
      var intervalTime = (+this.intervalRate * 1000);
      this.timer = setInterval(
        this.refreshEnvelopeHealthData.bind(this)
        , intervalTime);
    }
  }

  goToPreviousPage(page: any) {
    this.setState({ currentPageNo: page }, () => {
      this.getSchemaDetailsData();
    });

  }

  goToNextPage(page: any) {
    this.setState({ currentPageNo: page }, () => {
      this.getSchemaDetailsData();
    })
  }

  isLessthanTotalElement() {
    if ((this.state.currentPageNo * this.state.pageSize) === (this.state.totalPage - 1))
      return true;
    else
      return false;
  }

  changePageSize = (value: any) => {
    this.setState({ pageSize: value, currentPageNo: 1 }, () => {
      this.getSchemaDetailsData();
    })
    //  this.setState({ currentPageNo: 1 })

  }


  handleStartDateChange = (date) => {
    if (new Date(date) < new Date(this.state.endDate)) {
      this.setState({
        startDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("startDateValidation", "Start Date should be less than end date.");
    }
  };

  handleEndDateChange = date => {
    if (new Date(date) > new Date(this.state.startDate)) {
      this.setState({
        endDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("endDateValidation", "End Date should be greater than start date.");
    }
  };



  handleSchemaDetailsStartDateChange = (date) => {
    if (new Date(date) < new Date(this.state.schemaDetailsEndDate)) {
      this.setState({
        schemaDetailsStartDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("startDateValidation", "Start Date should be less than end date.");
    }
  };

  handleSchemaDetailsEndDateChange = (date) => {
    if (new Date(date) > new Date(this.state.schemaDetailsStartDate)) {
      this.setState({
        schemaDetailsEndDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("endDateValidation", "End Date should be greater than start date.");
    }
  };

  viewEnvelopeHealthCardDetails(item, index) {
    RestApiService.invoke(ApiEndPoints.GET_ENVELOPE_HEALTH_DETAILS, null, null, {
      ETId: item.ETId,
      SV: 1
    }, null).then(res => {
      this.setState({ envelopeDetail: { ...item, ...res.data.data[0] }, envelopeIndex: index });

    }).catch(error => {
      console.log('Get error in dashboard component', error);
      Toaster.errorToaster(error);
    })

  }


  viewSchemaDetails() {
    this.getSchemaDetailsData();
    this.getBarChartData();
  }

  viewSchemaGraph() {
    this.getSchemaPieChartData();
  }

  handleEnvelopeStartDateChange = date => {
    if (new Date(date) < new Date(this.state.envelopeEndDate)) {
      this.setState({
        envelopeStartDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("startEvelpDateValidation", "Start Date should be less than end date.");
    }
  };

  handleEnvelopeEndDateChange = date => {
    if (new Date(date) > new Date(this.state.envelopeStartDate)) {
      this.setState({
        envelopeEndDate: new Date(date)
      });
    }
    else {
      Toaster.errorToasterWithId("endEnvlpDateValidation", "End Date should be greater than start date.");
    }
  };

  viewEnvelopeGraph() {
    this.getEnvelopeData(true);
  }

  handleEnvelopeChartChange(event: React.ChangeEvent<{}>, newValue: any) {
    this.setState({ value: newValue });
    if (newValue == "Envelope") {
      clearInterval(this.timer);
      this.getEnvelopeData(true);
    }
    if (newValue == "Schema") {
      clearInterval(this.timer);
      this.viewSchemaGraph();
    }
    if (newValue == "SchemaDetails") {
      clearInterval(this.timer);
      this.getBarChartData();
      this.getSchemaDetailsData('Family', 'desc');
    }
    if (newValue == "EnvelopeHealth") {
      this.getEnvelopeData(true);
    }
    if (newValue == "AssistedDataAudit") {
      this.getAssistedAudit();
    }

  };

  filterHealthCards(health: any) {
    this.setState({ healthStatusText: health });
    switch (health) {
      case 'Good':
        this.setState({ healthQueryParam: 'Good' }, () => {
          this.getEnvelopeData(true);
        });
        break;
      case 'Pending':
        this.setState({ healthQueryParam: 'Pending' }, () => {
          this.getEnvelopeData(true);
        });
        break;
      case 'Bad':
        this.setState({ healthQueryParam: 'Bad' }, () => {
          this.getEnvelopeData(true);
        });
        break;
      default:
        this.setState({ healthQueryParam: '' }, () => {
          this.getEnvelopeData(true);
        });
    }

  }


  filterStatus(text) {
    this.setState({ statusText: text });
    this.setState({ healthStatusText: '' });
    if (text == "Past Hour") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCHours(new Date().getUTCHours() - 1)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })
    }
    if (text == "Past Day") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })
    }
    if (text == "Past Week") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCDate(new Date().getUTCDate() - 7)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })

    }
    if (text == "Past Month") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCMonth(new Date().getUTCMonth() - 1)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })
    }
    if (text == "Past Year") {
      this.setState({
        envelopeStartDate: new Date(new Date().setUTCFullYear(new Date().getUTCFullYear() - 1)),
        envelopeEndDate: new Date(),
        healthQueryParam: ''
      }, () => {
        this.getEnvelopeData(true);
      })
    }

  }
  componentWillUnmount() {

    clearInterval(this.timer);
  }
  // evenelopePieChartFullscreen() {
  //   if (!document.fullscreenElement) {
  //     document.documentElement.requestFullscreen();
  //   } else {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     }
  //   }
  // }
  filterRefresh(refreshRate) {
    this.intervalRate = parseInt(refreshRate);
    this.setState({ defaultRate: parseInt(refreshRate) })
    if (this.intervalRate === 0) {
      clearInterval(this.timer);
    }
    else {
      clearInterval(this.timer);
      var intervalTime = (+this.intervalRate * 1000);
      this.timer = setInterval(
        this.refreshEnvelopeHealthData.bind(this)
        , intervalTime);
    }
  }
  onSortingData(orderBy: any, order: any) {
    var data = this.state.orderIndexedFields.map(item => ({ ...item, order: 'desc' }));
    // var data = [...this.state.orderIndexedFields];
    var index = data.findIndex(obj => obj.orderBy === orderBy);
    data[index].order = order === 'desc' ? 'desc' : 'asc';
    var index1 = data.findIndex(obj => obj.isActive);
    if (index1 > -1) {
      data[index1].isActive = false;
    }
    data[index].isActive = true;
    this.setState({ orderIndexedFields: data });
    this.getSchemaDetailsData(orderBy, order);
  }


  render() {
    const schemaTableColumns = ["Schema", "FieldCount"];
    return (
      <div className={`Innerheading`}>
        {/* <div className="InnerHeader">
          <div className="left"></div>
          <h3>Dashboard</h3>
          <div className="right"></div>
        </div> */}
        <SubHeaderComponent headingText={'Dashboard'} showBtn={false}></SubHeaderComponent>
        <div className="Innerdetails">
          <div className="page-dashboard">
            <Paper square className={"paging-tab"}>
              <Tabs value={this.state.value} onChange={this.handleEnvelopeChartChange} aria-label="Envelope" className="tab-color" centered >
                {/* <Tab value="Envelope" label="Envelope" {...tabIndexProps('Envelope')} /> */}
                <Tab
                  value="EnvelopeHealth"
                  label="Envelope Health Check"
                  wrapped
                  {...tabIndexProps('EnvelopeHealth')}
                />
                <Tab
                  value="Schema"
                  label="Schema"
                  wrapped
                  {...tabIndexProps('Schema')}
                />
                <Tab
                  value="SchemaDetails"
                  label="Schema Details"
                  wrapped
                  {...tabIndexProps('SchemaDetails')}
                />
                {/* <Tab
                  value="AssistedDataAudit"
                  label="Assisted Data Audit"
                  wrapped
                  {...tabIndexProps('AssistedDataAudit')}
                /> */}
              </Tabs>
            </Paper>
            <TabPanel value={this.state.value} index="Schema">
              <Grid>
                <Card>
                  <CardContent>
                    <div className="datepicker">
                      <div>
                        <span className="start-date">Start Date :</span><DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange}
                          dateFormat="MM/dd/yyyy"
                          className={"datetime-width"}
                        />
                      </div>
                      <div>
                        <span className="end-date">End Date :</span><DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange}
                          dateFormat="MM/dd/yyyy"
                          className={"datetime-width"}
                        />
                      </div>
                      <div className="view">
                        <a onClick={() => this.viewSchemaGraph()}>View</a>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                {/* {this.state.schemaPieChartData && this.state.schemaPieChartData.length > 0 &&
                  <Card className={"envelope-top-bar-chart"}>
                    <CardHeader
                      avatar={
                        <PieChart width={3} height={3} />
                      }
                      title="Envelope Distribution"
                    // action={
                    //   <IconButton aria-label="Fullscreen" onClick={() => this.evenelopePieChartFullscreen()}>
                    //     <Tv />
                    //   </IconButton>
                    // }
                    />
                    {this.state.totalCreatedSchemas > 0 && this.state.totalUpdatedSchemas > 0 ? < CardContent >
                      <Chart
                        width={'98%'}
                        height={'100%'}
                        chartType="PieChart"
                        loader={<div><LinearProgress /> Loading...</div>}
                        data={this.state.schemaPieChartData}
                        options={{
                          is3D: true,
                          hAxis: {
                            slantedText: true,
                            slantedTextAngle: 65,
                            minValue: 0
                          },
                        }}
                        rootProps={{ 'data-testid': '7' }}
                        graphID={"schema-graph3"}
                      />
                    </CardContent> : <CardContent><span className="no-data">No Data Found</span></CardContent>}
                  </Card>
                } */}
                <Card>
                  <CardHeader
                    avatar={
                      <TableChart width={3} height={3} />
                    }
                    title="Updated Records   "

                  />
                  <CardContent>
                    <Paper className="dashboardtable">
                      <TableContainer>
                        <Table aria-label="customized table" >
                          <TableHead>
                            <TableRow>
                              <TableCell align={"left"}>Table Name</TableCell>
                              <TableCell align={"right"}>ETId</TableCell>
                              <TableCell align={"right"}>SV</TableCell>
                              <TableCell align={"right"}>Total Fields</TableCell>
                              <TableCell align={"right"}>Total Indexes</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.schemaUpdatedTableData && this.state.schemaUpdatedTableData.length > 0 ? this.state.schemaUpdatedTableData.map(row => (
                              <TableRow key={row.tableName}>
                                <TableCell align={"left"} component="th" scope="row">
                                  {row.tableName ? row.tableName : 'N.A'}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.ETId}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.latestDVVersions}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.totalUpdatedSchemaFields}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.totalUpdatedSchemaIndexes}
                                </TableCell>
                              </TableRow>
                            )) : <TableRow><TableCell>No Data Found</TableCell></TableRow>}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader
                    avatar={
                      <TableChart width={3} height={3} />
                    }
                    title="New Added Records"

                  />
                  <CardContent>
                    <Paper className="dashboardtable">
                      <TableContainer>
                        <Table aria-label="customized table" >
                          <TableHead>
                            <TableRow>
                              <TableCell align={"left"}>Table Name</TableCell>
                              <TableCell align={"right"}>ETId</TableCell>
                              <TableCell align={"right"}>SV</TableCell>
                              <TableCell align={"right"}>Total Fields</TableCell>
                              <TableCell align={"right"}>Total Indexes</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.schemaCreatedTableData && this.state.schemaCreatedTableData.length > 0 ? this.state.schemaCreatedTableData.map(row => (
                              <TableRow key={row.tableName}>
                                <TableCell align={"left"} component="th" scope="row">
                                  {row.tableName ? row.tableName : 'N.A'}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.ETId}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.latestDVVersions}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.totalSchemaFields}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.totalSchemaIndexes}
                                </TableCell>
                              </TableRow>
                            )) : <TableRow><TableCell>No Data Found</TableCell></TableRow>}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </CardContent>
                </Card>

              </Grid>
            </TabPanel>
            <TabPanel value={this.state.value} index="SchemaDetails">
              <Grid>
                <Card>
                  <CardContent>
                    <div className="datepicker">
                      <div>
                        <span className="start-date">Start Date :</span><DatePicker
                          selected={this.state.schemaDetailsStartDate}
                          onChange={this.handleSchemaDetailsStartDateChange}
                          dateFormat="MM/dd/yyyy"
                          className={"datetime-width"}
                        />
                      </div>
                      <div>
                        <span className="end-date">End Date :</span><DatePicker
                          selected={this.state.schemaDetailsEndDate}
                          onChange={this.handleSchemaDetailsEndDateChange}
                          dateFormat="MM/dd/yyyy"
                          className={"datetime-width"}
                        />
                      </div>
                      <div className="view">
                        <a onClick={() => this.viewSchemaDetails()}>View</a>
                      </div>
                    </div>
                  </CardContent>
                </Card>

                {this.state.schemaBarChartData && this.state.schemaBarChartData.length > 1 &&
                  <Card className={"envelope-top-bar-chart"}>
                    <CardHeader
                      avatar={
                        <BarChart width={3} height={3} />
                      }
                      title="Schema Details"
                    />
                    <CardContent>
                      <Chart
                        width={'98%'}
                        height={'100%'}
                        chartType="Bar"
                        loader={<div><LinearProgress /> Loading...</div>}
                        data={this.state.schemaBarChartData}
                        options={{
                          is3D: true,
                          hAxis: {
                            direction: -1,
                            slantedText: true,
                            //slantedTextAngle:90                           
                          },
                          // chartArea: { width: "50%" },
                          colors: ["#4285F4", "#DB4437", "#0F9D58", "#F4B400", "#AB47BC"]
                        }}
                        legendToggle
                        rootProps={{ 'data-testid': '8' }}
                        graphID={"schema-graph4"}
                      />
                    </CardContent>
                  </Card>
                }
                <Card>
                  <CardHeader
                    avatar={
                      <TableChart width={3} height={3} />
                    }
                    title="Schema Records"

                  />
                  <CardContent>
                    <Paper className="dashboardtable">
                      <TableContainer className="tableContainerRoot">
                        <Table aria-label="customized table" >
                          <TableHead>
                            <TableRow>
                              <TableCell align={"left"}><div>Family
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'Family' && !x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('Family', 'asc')} className="arrowicon" />
                                  </Tooltip>
                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'asc' && x.orderBy === 'Family' && x.isActive) &&
                                  <Tooltip title="descending">
                                    <ArrowUpwardIcon onClick={() => this.onSortingData('Family', 'desc')} className="arrowiconactive" />
                                  </Tooltip>
                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'Family' && x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('Family', 'asc')} className="arrowiconactive" />
                                  </Tooltip>
                                }
                              </div>
                              </TableCell>
                              <TableCell align={"left"}><div>Schema
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'TableName' && !x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('TableName', 'asc')} className="arrowicon" />
                                  </Tooltip>

                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'asc' && x.orderBy === 'TableName' && x.isActive) &&
                                  <Tooltip title="descending">
                                    <ArrowUpwardIcon onClick={() => this.onSortingData('TableName', 'desc')} className="arrowiconactive" />
                                  </Tooltip>
                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'TableName' && x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('TableName', 'asc')} className="arrowiconactive" />
                                  </Tooltip>

                                }
                              </div>
                              </TableCell>
                              <TableCell align={"right"}><div>History
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'History' && !x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('History', 'asc')} className="arrowicon" />
                                  </Tooltip>

                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'asc' && x.orderBy === 'History' && x.isActive) &&
                                  <Tooltip title="descending" >
                                    <ArrowUpwardIcon onClick={() => this.onSortingData('History', 'desc')} className="arrowiconactive" />
                                  </Tooltip>
                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'History' && x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('History', 'asc')} className="arrowiconactive" />
                                  </Tooltip>

                                }
                              </div>
                              </TableCell>
                              <TableCell align={"right"}><div>Summary
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'Summary' && !x.isActive) &&
                                  <Tooltip title="ascending" >
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('Summary', 'asc')} className="arrowicon" />
                                  </Tooltip>

                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'asc' && x.orderBy === 'Summary' && x.isActive) &&
                                  <Tooltip title="descending" >
                                    <ArrowUpwardIcon onClick={() => this.onSortingData('Summary', 'desc')} className="arrowiconactive" />
                                  </Tooltip>}
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'Summary' && x.isActive) &&
                                  <Tooltip title="ascending" >
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('Summary', 'asc')} className="arrowiconactive" />
                                  </Tooltip>

                                }
                              </div>
                              </TableCell>
                              <TableCell align={"right"}><div>Inserted
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'Inserted' && !x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('Inserted', 'asc')} className="arrowicon" />
                                  </Tooltip>

                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'asc' && x.orderBy === 'Inserted' && x.isActive) &&
                                  <Tooltip title="descending" >
                                    <ArrowUpwardIcon onClick={() => this.onSortingData('Inserted', 'desc')} className="arrowiconactive" />
                                  </Tooltip>}
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'Inserted' && x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('Inserted', 'asc')} className="arrowiconactive" />
                                  </Tooltip>

                                }
                              </div>
                              </TableCell>
                              <TableCell align={"right"}><div>
                                Updated
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'Updated' && !x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('Updated', 'asc')} className="arrowicon" />
                                  </Tooltip>

                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'asc' && x.orderBy === 'Updated' && x.isActive) &&
                                  <Tooltip title="descending">
                                    <ArrowUpwardIcon onClick={() => this.onSortingData('Updated', 'desc')} className="arrowiconactive" />
                                  </Tooltip>
                                }
                                {this.state.orderIndexedFields.find(x => x.order === 'desc' && x.orderBy === 'Updated' && x.isActive) &&
                                  <Tooltip title="ascending">
                                    <ArrowDownwardIcon onClick={() => this.onSortingData('Updated', 'asc')} className="arrowiconactive" />
                                  </Tooltip>

                                }
                              </div></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.schemaDetailsTableData && this.state.schemaDetailsTableData.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell align={"left"} component="th" scope="row">
                                  {row.family.charAt(0).toUpperCase() + row.family.slice(1)}
                                </TableCell>
                                <TableCell align={"left"} component="th" scope="row">
                                  {row.schema}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.history}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.summary}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.inserted}
                                </TableCell>
                                <TableCell align={"right"} component="th" scope="row">
                                  {row.updated}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </Paper>
                    <PagingComponent
                      goToPreviousPage={(page: any) => this.goToPreviousPage(page)}
                      goToNextPage={(page: any) => this.goToNextPage(page)}
                      changePageSize={(page: any) => this.changePageSize(page)}
                      pageSize={this.state.pageSize}
                      currentPageNo={this.state.currentPageNo}
                      totalPage={this.state.totalPage}
                      totalRecord={this.state.totalRecord}
                      showPageSize={true}
                      isLessthanTotalElement={this.isLessthanTotalElement()}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.value} index="Envelope">
              <Grid>
                <Card className={"envelope-top-bar-chart"}>

                  <CardContent>
                    <div className="datepicker">
                      <div>
                        <span className="start-date">Start Date :</span><DatePicker
                          selected={this.state.envelopeStartDate}
                          onChange={this.handleEnvelopeStartDateChange}
                          dateFormat="MM/dd/yyyy"
                          className={"datetime-width"}
                        />
                      </div>
                      <div>
                        <span className="end-date">End Date :</span><DatePicker
                          selected={this.state.envelopeEndDate}
                          onChange={this.handleEnvelopeEndDateChange}
                          dateFormat="MM/dd/yyyy"
                          className={"datetime-width"}
                        />
                      </div>
                      <div className="view">
                        <a onClick={() => this.viewEnvelopeGraph()}>View</a>
                      </div>

                    </div>
                  </CardContent>
                </Card>
                {this.state.envelopePieChartData && this.state.envelopePieChartData.length > 1 &&
                  <Card className={"envelope-top-bar-chart"}>
                    <CardHeader
                      avatar={
                        <PieChart width={3} height={3} />
                      }
                      title="Envelope Status Chart"
                    // action={
                    //   <IconButton aria-label="Fullscreen" onClick={() => this.evenelopePieChartFullscreen()}>
                    //     <Tv />
                    //   </IconButton>
                    // }
                    />
                    <CardContent>
                      <Chart
                        width={'98%'}
                        height={'100%'}
                        chartType="PieChart"
                        loader={<div><LinearProgress /> Loading...</div>}
                        data={this.state.envelopePieChartData}
                        options={{
                          is3D: true,
                          hAxis: {
                            slantedText: true,
                            slantedTextAngle: 65,
                            minValue: 0
                          },
                          slices: [
                            {
                              color: "#DB4437"
                            },
                            {
                              color: "#0F9D58"
                            },
                            {
                              color: "#F4B400"
                            },
                            {
                              color: "#AB47BC"
                            }
                          ],
                        }}

                        rootProps={{ 'data-testid': '7' }}
                        graphID={"schema-graph3"}
                      />
                    </CardContent>
                  </Card>
                }



                {this.state.envelopeBarChartData && this.state.envelopeBarChartData.length > 1 &&
                  <Card className={"envelope-top-bar-chart"}>
                    <CardHeader
                      avatar={
                        <BarChart width={3} height={3} />
                      }
                      title="Envelope Status By ETID"
                    />
                    <CardContent>
                      <Chart
                        width={'98%'}
                        height={'100%'}
                        chartType="Bar"
                        loader={<div><LinearProgress /> Loading...</div>}
                        data={this.state.envelopeBarChartData}
                        options={{
                          is3D: true,
                          hAxis: {
                            direction: -1,
                            slantedText: true,
                            //slantedTextAngle:90                           
                          },

                          colors: ["#4285F4", "#DB4437", "#0F9D58", "#F4B400", "#AB47BC"]
                        }}
                        legendToggle
                        rootProps={{ 'data-testid': '6' }}
                        graphID={"schema-graph2"}
                      />
                    </CardContent>
                  </Card>
                }


                <Card className={"envelope-top-bar-chart"}>
                  <CardHeader
                    avatar={
                      <TableChart width={3} height={3} />
                    }
                    title="Envelope Summary"
                  />
                  <CardContent>
                    <Paper className="dashboardtable">
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell align={"left"}>Name</TableCell>
                            <TableCell align={"right"}>Total</TableCell>
                            <TableCell align={"right"}>Submitted</TableCell>
                            <TableCell align={"right"}>BC Stored</TableCell>
                            <TableCell align={"right"}>Datastore</TableCell>
                            <TableCell align={"right"}>External BlockChain</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.envelopeTableData && this.state.envelopeTableData.map(row => (
                            <TableRow key={row.ETId} className={"border-color"}>
                              <TableCell component="th" scope="row" align={"left"}>
                                {row.Name}
                              </TableCell>
                              <TableCell align={"right"}>{row.Total}</TableCell>
                              <TableCell align={"right"}>{row.Submitted}</TableCell>
                              <TableCell align={"right"}>{row["BC Stored"]}</TableCell>
                              <TableCell align={"right"}>{row.Datastore}</TableCell>
                              <TableCell align={"right"}>{row["External BlockChain"]}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </CardContent>
                </Card>

                {this.state.envelopeBarChartData.length == 1 && <div className={'not-found'}>
                  Data not found
                </div>
                }

              </Grid>

            </TabPanel>
            <TabPanel value={this.state.value} index="EnvelopeHealth">
              <Grid>
                <Card className={"envelope-top-bar-chart"}>

                  <CardContent className="health-filter">
                    <div className="filter-content">
                      <div className="datepicker">
                        <p className="statuslabel">Time Interval:</p>
                        <div>
                          <select
                            className="form-control"
                            name={`Time Interval`}
                            value={this.state.statusText}
                            onChange={(event) => this.filterStatus(event.target.value)}
                          >
                            {
                              this.state.statusArray && this.state.statusArray.length > 0 && this.state.statusArray.map((item: any, index: any) => (
                                <option key={index} value={item} label={item}></option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="datepicker">
                        <p className="statuslabel">Refresh Rate/Second: </p>
                        <div>
                          <select
                            className="form-control"
                            name={`RefreshRate`}
                            value={this.state.defaultRate}
                            onChange={(event) => this.filterRefresh(event.target.value)}
                          >
                            {
                              this.state.ratesArray && this.state.ratesArray.length > 0 && this.state.ratesArray.map((item: any, index: any) => (
                                <option key={index} value={item} label={item}></option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className='filter-buttons'>
                        <Button variant="contained" onClick={() => this.getEnvelopeData(true)} className={`health-button`}>
                          Refresh
                        </Button>
                      </div>
                    </div>
                    <div className='datepicker'>
                      <p className="statuslabel">Filter By Health: </p>
                      <div>
                        <select
                          className="form-control"
                          name={`Status`}
                          value={this.state.healthStatusText}
                          onChange={(event) => this.filterHealthCards(event.target.value)}
                        >
                          {
                            this.state.buttonArray && this.state.buttonArray.length > 0 && this.state.buttonArray.map((item: any, index: any) => (
                              <option key={index} value={item} label={item}></option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <div className="envelope-health">
                  {this.state.refreshLoading && <div className="loader-content">
                    <SmallLoader></SmallLoader>
                  </div>}
                  <Grid container spacing={3}>

                    {this.state.envelopeData && this.state.envelopeData.length > 0 &&
                      this.state.envelopeData.map((item: any, index: any) =>
                        <Grid item xs={12} md={3} key={`envelope_${index}`}>
                          <Card className={`health-card ${item.Health === 1 ? 'Good' : ''}
                          ${item.Health === 2 ? 'Pending' : ''}
                          ${item.Health === 3 ? 'Bad' : ''} `}>
                            <CardHeader
                              className="health-header"
                              action={
                                <Fragment>
                                  {this.state.envelopeIndex !== index && <IconButton
                                    aria-label="settings"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={() => this.viewEnvelopeHealthCardDetails(item, index)}
                                  >
                                    <Visibility />
                                  </IconButton>}
                                  {this.state.envelopeIndex === index && <IconButton
                                    aria-label="settings"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={() => this.closeCardDetails(item, index)}
                                  >
                                    <Visibility />
                                  </IconButton>}
                                </Fragment>

                              }
                              title={`${item.Name} (${item.ETId})`}
                            />
                            {this.state.envelopeDetail && (this.state.envelopeIndex === index) && <CardContent>
                              <div className="content-block">
                                <strong className="content-label">ETId</strong>
                                <span>{this.state.envelopeDetail.ETId}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">Total</strong>
                                <span>{this.state.envelopeDetail.Total}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">API Submitted</strong>
                                <span>{this.state.envelopeDetail['API Submitted']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">API Failed</strong>
                                <span>{this.state.envelopeDetail['API Failed']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">API Processed</strong>
                                <span>{this.state.envelopeDetail['API Processed']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">OCS Stored</strong>
                                <span>{this.state.envelopeDetail['OCS Stored']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">BC Submitted</strong>
                                <span>{this.state.envelopeDetail['BC Submitted']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">BC Included</strong>
                                <span>{this.state.envelopeDetail['BC Included']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">BC Confirmed</strong>
                                <span>{this.state.envelopeDetail['BC Confirmed']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">BPM Processed</strong>
                                <span>{this.state.envelopeDetail['BPM Processed']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">BPM Failed</strong>
                                <span>{this.state.envelopeDetail['BPM Failed']}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">Table Name</strong>
                                <Tooltip title={this.state.envelopeDetail.TableName} placement={'right'}>
                                  <span className="span-content">{this.state.envelopeDetail.TableName}</span>
                                </Tooltip>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">Family</strong>
                                <span>{this.state.envelopeDetail.Family}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">Do Summary</strong>
                                <span>{this.state.envelopeDetail.DoSummary ? 'true' : 'false'}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">Organization</strong>
                                <span>{this.state.envelopeDetail.ORGName}</span>
                              </div>
                              <div className="content-block">
                                <strong className="content-label">Description</strong>
                                <Tooltip title={this.state.envelopeDetail.Description} placement={'right'}>
                                  <span className="span-content">{this.state.envelopeDetail.Description}</span>
                                </Tooltip>

                              </div>
                              <div className="content-block">
                                <strong className="content-label"></strong>
                                <span> <nav>
                                  <NavLink
                                    to="/page/schema-list"
                                    className='schemalink'
                                  >
                                    Schema
                                  </NavLink>
                                </nav></span>
                              </div>
                              {/* <div className="content-block">
                                <strong className="content-label">Fields</strong>
                                <span className="field-span" > {this.state.envelopeDetail.Fields && this.state.envelopeDetail.Fields.map((field: any, index2: number) =>
                                  <li key={index2} >
                                    {field.FieldName}
                                  </li>
                                )}</span>
                              </div> */}
                            </CardContent>}
                            {/* <CardActions>
                              <Button size="small">Learn More</Button>
                            </CardActions> */}
                          </Card>
                        </Grid>
                      )}
                  </Grid>
                </div>
                {this.state.envelopeData.length == 0 && <div className={'not-found-health'}>
                  Data not found
                </div>
                }

              </Grid>
            </TabPanel>
            <TabPanel value={this.state.value} index="AssistedDataAudit">
              <Grid>
                {this.state.assistedAuditData && this.state.assistedAuditData.length > 1 &&
                  <Card className={"envelope-top-bar-chart"}>
                    <CardHeader
                      avatar={
                        <PieChart width={3} height={3} />
                      }
                      title="Assisted Data Audit"
                    />
                    <CardContent>
                      <Chart
                        width={'98%'}
                        height={'100%'}
                        chartType="PieChart"
                        loader={<div><LinearProgress /> Loading...</div>}
                        data={this.state.assistedAuditData}
                        options={{
                          is3D: true,
                          hAxis: {
                            slantedText: true,
                            slantedTextAngle: 65,
                            minValue: 0
                          },

                        }}
                        rootProps={{ 'data-testid': '7' }}
                        graphID={"schema-graph3"}
                      />
                    </CardContent>
                  </Card>
                }
              </Grid>
            </TabPanel>
          </div>

        </div>
      </div >
    );
  }
}


const mapStateToProps = (state) => ({
  isShowSideBar: state.appSidebarReducer
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSideBar: toggleSideBar,
      toggleLoader: toggleLoader
    },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
