import React, { useEffect } from 'react';
import './ChangePassword.scss';
import "../../../assets/themes/default/share.scss";
import { TextField, Button, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';



const ChangePassword = (props: any) => {
  const [oldPassword, setOldPassword] = React.useState<any>('');
  const [newPassword, setNewPassword] = React.useState<any>('');
  const [confirmPassword, setConfirmPassword] = React.useState<any>('');


  const onSubmit = () => {

    const userData = {
      oldPassword,
      newPassword,
      confirmPassword
    }
    props.sendData(userData);

  }


  return (
    <div className="change-password-modal">
      <Dialog onClose={props.hide} aria-labelledby="customized-dialog-title" open={true} >
        <DialogTitle disableTypography>
          <Typography variant="h6">Change Password</Typography>
          <IconButton aria-label="close" onClick={props.hide}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <div className="content-boxs">
          <div className="change-password">
            <form action="post" className="form">

              <div className="form-group">
                <TextField
                  id="standard-uncontrolled1"
                  label="Old Password"
                  margin="normal"
                  type="password"
                  value={oldPassword}
                  required
                  onChange={event => setOldPassword(event.target.value)}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="standard-uncontrolled2"
                  label="New Password"
                  margin="normal"
                  type="password"
                  value={newPassword}
                  required
                  onChange={event => setNewPassword(event.target.value)}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="standard-uncontrolled3"
                  label="Confirm New Password"
                  margin="normal"
                  type="password"
                  value={confirmPassword}
                  required
                  onChange={event => setConfirmPassword(event.target.value)}
                />
              </div>
              <div className="login-button">
                <Button color="primary" variant="contained" disabled={oldPassword === '' || newPassword === '' || confirmPassword === ''} onClick={onSubmit}>
                  Save
            </Button>

              </div>

            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}


export default ChangePassword;
